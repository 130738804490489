import "./Loading.css";
const Loading = () => {
  return (
    <div
      id="loader-container"
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <img style={{ width: "120px" }} src="/images/Loader.gif" />
    </div>
  );
};
export default Loading;
