import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { UpdateStandardrization } from "../../data/standardrization";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "center",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "650px",
    height: "500px",
  },
}));

export default function StandardrizationActivePopupUpdate(props) {
  const { openUpdateModal, setOpenUpdateModal, selectedPrompt } = props;
  // const [isFocused, setIsFocused] = useState(false);
  // const [isMaximized, setIsMaximized] = useState(false);
  const [prompt, setPrompt] = useState(selectedPrompt.prompt);
  const [versionRemark, setVersionRemark] = useState(
    selectedPrompt.versionremark
  );
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenUpdateModal(false);
    }
  };

  // const handleToggle = () => {
  //   setIsMaximized(!isMaximized);
  // };

  const handleUpdate = () => {
    if (!prompt) {
      setError("Prompt cannot be empty.");
      return;
    }
    UpdateStandardrization({
      prompt: prompt,
      versionremark: versionRemark,
    })
      .then(() => {
        setOpenUpdateModal(false);
        navigate(0);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openUpdateModal}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 8,
            color: "green",
          }}
        >
          <img
            style={{ width: "15px", height: "15px" }}
            src="/images/Path 1670.svg"
            alt="Button"
          />
        </IconButton>
        <DialogContent dividers sx={{ display: "block" }}>
          <div className="block mt-4">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                padding: "6px",
              }}
            >
              <div style={{ flex: "1px", width: "100%", position: "relative" }}>
                <p
                  style={{
                    height: "18px",
                    textAlign: "left",
                    font: "normal normal medium 15px/23px Rubik",
                    letterSpacing: "0px",
                    color: "#1F2E39",
                    textTransform: "uppercase",
                    opacity: 1,
                    fontWeight: "600",
                  }}
                >
                  Standardization PROMPT
                </p>

                <textarea
                  placeholder="Enter Standardization Prompt"
                  name="prompt"
                  value={prompt}
                  // onFocus={() => setIsFocused(true)}
                  // onBlur={() => setIsFocused(false)}
                  onChange={(e) => setPrompt(e.target.value)}
                  // className={isFocused ? "textarea-focused" : ""}
                  style={{
                    width: "100%",
                    // height: isMaximized ? "600px" : "260px",
                    height: "260px",
                    border: "1px solid #708EA4",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    borderRadius: "5px",
                    opacity: 1,
                    padding: "7px",
                    paddingRight: "20px",
                    marginTop: "10px",
                    resize: "none",
                  }}
                />
                {error && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {error}
                  </p>
                )}
                {/* <img
                  className="w-4 h-4"
                  style={{
                    position: "absolute",
                    top: "30px",
                    bottom: "0px",
                    right: "20px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "16px",
                  }}
                  onClick={handleToggle}
                  src="/images/window-maximize-regular.svg"
                /> */}
              </div>
            </div>

            <div style={{ flex: "1px", width: "100%", paddingLeft: "5px" }}>
              <p
                style={{
                  height: "18px",
                  textAlign: "left",
                  font: "normal normal medium 15px/23px Rubik",
                  letterSpacing: "0px",
                  textTransform: "uppercase",
                  opacity: 1,
                  color: "#1F2E39",
                  fontWeight: "600",
                }}
              >
                Version remark
              </p>
              <input
                placeholder="Enter Version Remark"
                value={versionRemark}
                onChange={(e) => setVersionRemark(e.target.value)}
                style={{
                  display: "block",
                  width: "100%",
                  height: "40px",
                  border: "1px solid #708EA4",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  borderRadius: "5px",
                  opacity: 1,
                  padding: "7px",
                  marginTop: "10px",
                }}
              />
            </div>
            <div className="flex justify-center mt-5">
              <div
                className="buttonbox"
                style={{ cursor: "pointer" }}
                onClick={handleUpdate}
              >
                <p type="submit" className="backbutton ">
                  Save
                </p>
                <img
                  className="iconbox"
                  style={{ width: "20px" }}
                  src="/images/black-arrow.svg"
                />
              </div>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
