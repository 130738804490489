import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useClientSettingData } from "../../data/clientSettings";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "350px",
    height: "250px",
  },
}));

export default function DeletePopup(props) {
  const { open, setOpen, id, setId } = props;
  const { DeleteUser } = useClientSettingData();

  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  const handleDeleteUser = async () => {
    try {
      const response = await DeleteUser({
        userid: id,
      });

      if (response?.header?.status === 200) {
        setOpen(false);
        setId();
        navigate(0);
      }
    } catch (error) {
      // Handle error - show an error message
      console.error("API call error:", error);
    }
  };
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 8,
            color: "green",
          }}
        >
          <img
            style={{ width: "15px", height: "15px" }}
            src="/images/Path 1670.svg"
          />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <img
              style={{ width: "50px", height: "50px" }}
              src="/images/Path 1681.svg"
            />
          </Typography>

          <Typography
            gutterBottom
            style={{ font: "normal normal bold 20px/23px Rubik",marginTop:"7px" }}
          >
            Are You Sure?
          </Typography>
          <Typography
            gutterBottom
            style={{
              font: "normal normal 15px/22px Rubik",
            }}
          >
            Are you sure you want to delete this user?
          </Typography>
          <Typography
            gutterBottom
            style={{
              font: "normal normal 15px/22px Rubik",
            }}
          >
            This process cannot be undone.
          </Typography>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              marginTop:"7px"
            }}
          >
            <div
              style={{
                background: "#ffff",
                border: " 2px solid #6F8EA4",
                padding: "0px 15px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "#708EA4",
                  fontSize: "14px",
                  fontWeight: "500",
                  display: "inline-flex",
                  padding: "6px 24px",
                }}
                onClick={() => setOpen(false)}
              >
                CANCEL
              </div>
            </div>
            <div
              style={{
                background: "#E77388",
                border: "#E77388",
                padding: "0px 12px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "#FFFF",
                  fontSize: "14px",
                  fontWeight: "500",
                  display: "inline-flex",
                  padding: "8px 28px",
                }}
                onClick={() => handleDeleteUser()}
              >
                DELETE
              </div>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
