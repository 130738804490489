import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();


 export const GetIndustryPrompts = async (body) => {
  const { industryid} = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getlatestindustryprompts",
      industryid:industryid
      
    },
  });
  return data;
};