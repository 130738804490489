import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Box, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";
import { useJobsData } from "../../data/jobsdata";
import { toast, ToastContainer } from "react-toastify";
import { decode } from "he"; 
import { useLocation, useNavigate } from "react-router-dom";
import { storage } from "../../libs/localStorage";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import AddNewJobSavedPopup from "./AddNewJobSavedPopup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill's CSS
import "./AddJob.css";
import { useCandidateData } from "../../data/candidatedata";


const AddJob = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const params = location?.state?.job;
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const activetab = "addjob";
  const [currentDate, setCurrentDate] = useState("");
  const [jobCodeError, setJobCodeError] = useState("");
  const [jobTitleError, setJobTitleError] = useState("");
  const [jobTypeError, setJobTypeError] = useState("");
  const [jobDescriptionError, setJobDescriptionError] = useState("");
  const [jobCode, setJobCode] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobtype, setJobType] = useState(
    params?.employmenttype ? params?.employmenttype : "Full Time"
  );
  const [isActive, setIsActive] = useState(true);
  const { GetCustomerById } = useCandidateData();
  const [open, setOpen] = useState(false);
  const { UpdateJob, AddJob, GenerateAiDesc, GetJobByJobCode } = useJobsData();
  const { retrieveToken } = storage();
  const token = retrieveToken();
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const [jobDescription, setJobDescription] = useState("");
  const dropdownRef = useRef(null);
  const [industry, setIndustry] = useState("");
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let isValid = true;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Full Time");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // const handleOptionClick = (id, name) => {
  //   setSelectedOption(name);
  //   setIsOpen(false);
  // };

  const handleAddJob = async () => {
    AddJob({
      atsjobcode: jobCode?.trim(),
      jobtitle: jobTitle?.trim(),
      employmenttype: jobtype,
      jobdescription: jobDescription?.trim(),
      isactive: isActive,
    })
      .then((response) => {
        {
          response?.header?.status == 200
            ? setOpen(true)
            : toast.error(
                <span
                  style={{
                    font: "rubik regular",
                    color: "black",
                    fontWeight: "normal",
                    fontSize: "15px",
                  }}
                >
                  {response?.header?.message}
                </span>
              );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleUpdateJob = async () => {
    UpdateJob({
      atsjobcode: jobCode?.trim(),
      jobid: params?.jobid,
      jobtitle: jobTitle?.trim(),
      employmenttype: jobtype,
      jobdescription: jobDescription,
      isactive: isActive,
    })
      .then((response) => {
        {
          response?.header?.status == 200
            ? setOpen(true)
            : toast.error(
                <span
                  style={{
                    font: "rubik regular",
                    color: "black",
                    fontWeight: "normal",
                    fontSize: "15px",
                  }}
                >
                  {response?.header?.message}
                </span>
              );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`; // MM-DD-YYYY format
  };

  const handleDateChange = (event) => {
    setCurrentDate(event.target.value);
  };

  useEffect(() => {
    if (token) {
      const today = new Date();
      const formattedDate = formatDate(today); // Format to MM-DD-YYYY
      setCurrentDate(formattedDate);
      if (params?.jobid) {
        setJobCode(params?.atsjobcode || "");
        setJobTitle(params?.jobtitle || "");
        setJobDescription(params?.jobdescription || "");
        setIsActive(params?.isactive ?? false);
        setSelectedOption(params?.employmenttype || "Full Time");
      }
    } else {
      window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
    }
  }, []);

  const handleSubmit = async (event) => {
    // event.preventDefault();
    // let isValid = true;
    setJobCodeError("");
    setJobTitleError("");
    setJobTypeError("");
    setJobDescriptionError("");

    if (!jobCode?.trim()) {
      setJobCodeError("Job Code is required.");
      isValid = false;
    }

    if (!jobTitle?.trim()) {
      setJobTitleError("Job Title is required.");
      isValid = false;
    }

    if (!jobtype) {
      setJobTypeError("Job Type is required.");
      isValid = false;
    }

    if (!jobDescription?.trim()) {
      setJobDescriptionError("Job Description is required.");
      isValid = false;
    }
    await handleJobCodeBlur(jobCode);
    if (isValid) {
      if (params?.jobid) {
        handleUpdateJob();
      } else {
        handleAddJob();
      }
    }
  };

  const handleInputChange = (setter, setError) => (event) => {
    // const trimmedValue = event.target.value.trim();
    setter(event.target.value);
    setError("");
  };

  const jobTypes = [
    { type: "Full Time", id: 1 },
    { type: "Part Time", id: 2 },
    { type: "Contract", id: 3 },
  ];

  const handleOptionClick = (industryid, industryname) => {
    setSelectedOption(industryname);
    setJobType(industryname);
    toggleDropdown();
    // setDropdownChange(true);
  };

  const genAiDesc = async () => {
    try {
      setJobTitleError("");
      setJobTypeError("");

      let isValid = true;

      if (!jobTitle?.trim()) {
        setJobTitleError("Job Title is required.");
        isValid = false;
      }

      if (!jobtype) {
        setJobTypeError("Job Type is required.");
        isValid = false;
      }

      if (!isValid) {
        return;
      }
      const response = await GetCustomerById();
      if (response?.data?.length > 0) {
        setIndustry(response.data[0]?.industry);
        setCustomer(response.data[0]);
        setIsLoading(true);
        const aiDescResponse = await GenerateAiDesc({
          jobtitle: jobTitle?.trim(),
          employmenttype: jobtype,
          industry: response.data[0]?.industry,
        });
        setIsLoading(false);
        if (aiDescResponse?.job_description) {
          setJobDescription(aiDescResponse?.job_description);
        } else {
          toast.error(
            <span
              style={{
                font: "rubik regular",
                color: "black",
                fontWeight: "normal",
                fontSize: "15px",
              }}
            >
              Failed to generate job description
            </span>
          );
        }
      } else {
        throw new Error("Customer data is empty");
      }
    } catch (error) {
      console.error("Error in generating AI description:", error);
      toast.error(
        <span
          style={{
            font: "rubik regular",
            color: "black",
            fontWeight: "normal",
            fontSize: "15px",
          }}
        >
          An error occurred. Please try again later.
        </span>
      );
    }
  };
  useEffect(() => {
    // Select the editor and container elements
    const editor = document.querySelector(".custom-quill-editor .ql-editor");
    const container = document.querySelector(
      ".custom-quill-editor .ql-container"
    );

    // Apply styles with !important
    if (editor && container) {
      editor.style.setProperty("background-color", "#ffffff", "important");
      editor.style.setProperty("color", "#000000", "important");
      container.style.setProperty("background-color", "#ffffff", "important");
    }
  }, [jobDescription]);

  const handleJobCodeBlur = async (jobcode) => {
    if (jobcode) {
      try {
        const response = await GetJobByJobCode({ jobcode: jobcode });
        if (response?.header?.status == 200) {
          if (response?.data[0]?.jobid == params?.jobid) {
            isValid = true;
          } else {
            isValid = false;
            setJobCodeError("Job already exists");
          }
        } else {
          isValid = true;
        }
      } catch (error) {
        // console.error("API call error:", error);
        // return false; // Return false in case of API call error
      }
    }
  };

  return (
    <>
      <ToastContainer />
      {isLoading && (
        <div
          id="loader-container"
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            inset: "0",
            zIndex: 2,
          }}
        >
          <img style={{ width: "120px" }} src="/images/Loader.gif" />
        </div>
      )}

      {
        <AddNewJobSavedPopup
          open={open}
          setOpen={setOpen}
          jobid={params?.jobid}
        />
      }
      {isMobile ? (
        <>
          <Helmet>
            <style>{"body { background-color: #ffff; }"}</style>
            <title>Add New JOb</title>
          </Helmet>
          <React.Fragment>
            <Navbar data={params?.jobid ? "editjob" : "addjob"} />
            <Box className="flex w-full overflow-auto">
              <Box className="w-full">
                <div className="w-full">
                  <div className="bg-white rounded-md w-full shadow-md px-3 py-3">
                    <div>
                      <div className="flex flex-col w-full">
                        <div
                          style={{ flex: "1px", width: "100%" }}
                          className="px-3 py-3"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                            }}
                          >
                            Date
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <input
                            placeholder="Select Date"
                            value={currentDate}
                            readOnly
                            onChange={handleDateChange}
                            style={{
                              display: "block",
                              width: "98%",
                              height: "40px",
                              outline: "none",
                              border: "1px solid #708EA4",
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              borderRadius: "5px",
                              opacity: 1,
                              padding: "7px",
                              marginTop: "15px",
                            }}
                          />
                        </div>
                        <div
                          style={{ flex: "1px", width: "100%" }}
                          className="px-3 py-3"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                            }}
                          >
                            JobId
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <input
                            placeholder="Enter Job Code e.g Jd-0078"
                            value={jobCode}
                            onChange={handleInputChange(
                              setJobCode,
                              setJobCodeError
                            )}
                            readOnly={params?.totalcvs > 0 ? true : false}
                            onBlur={(e) => handleJobCodeBlur(e.target.value)}
                            style={{
                              display: "block",
                              width: "98%",
                              height: "40px",
                              border: "1px solid #708EA4",
                              outline: params?.totalcvs > 0 ? "none" : "",
                              color: params?.totalcvs > 0 ? "gray" : "",
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              borderRadius: "5px",
                              opacity: 1,
                              padding: "7px",
                              marginTop: "15px",
                            }}
                          />
                          {jobCodeError && (
                            <p style={{ color: "red", marginTop: "10px" }}>
                              {jobCodeError}
                            </p>
                          )}
                        </div>

                        <div
                          style={{ flex: "1px", width: "100%" }}
                          className="px-3"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                            }}
                          >
                            Job Title
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <input
                            placeholder="Enter Job Title"
                            type="text"
                            value={jobTitle}
                            onChange={handleInputChange(
                              setJobTitle,
                              setJobTitleError
                            )}
                            style={{
                              display: "block",
                              width: "98%",
                              height: "40px",
                              outline: "none",
                              border: "1px solid #708EA4",
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              borderRadius: "5px",
                              opacity: 1,
                              padding: "7px",
                              marginTop: "15px",
                            }}
                          />
                          {jobTitleError && (
                            <p style={{ color: "red", marginTop: "10px" }}>
                              {jobTitleError}
                            </p>
                          )}
                        </div>

                        <div
                          style={{ flex: "1px", width: "100%" }}
                          className="px-3"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                              marginTop: "15px",
                            }}
                          >
                            Type
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <div
                            style={{ marginTop: "15px", width: "98%" }}
                            className="dropdown__list__parent"
                            ref={dropdownRef}
                          >
                            <button
                              onClick={toggleDropdown}
                              className={`dropdown__list ${
                                isOpen ? "open" : ""
                              }`}
                              style={{ borderColor: "rgb(112, 142, 164)" }}
                            >
                              {selectedOption}
                              {!isOpen ? (
                                <img
                                  src="images/custom-arrow.svg"
                                  alt="Dropdown Arrow"
                                />
                              ) : (
                                <img
                                  style={{ transform: "rotate(180deg)" }}
                                  src="images/custom-arrow.svg"
                                  alt="Dropdown Arrow"
                                />
                              )}
                            </button>

                            {isOpen && (
                              <div
                                className="dropdown__list__child"
                                style={{ borderColor: "rgb(112, 142, 164)" }}
                              >
                                <div
                                  id="jobtypeid"
                                  value={jobtype}
                                  onChange={(e) => setJobType(e.target.value)}
                                >
                                  {jobTypes?.map((data) => (
                                    <p
                                      key={data?.id}
                                      value={data?.type}
                                      style={{
                                        borderBottom:
                                          "1px solid rgb(112, 142, 164)",
                                      }}
                                      onClick={() =>
                                        handleOptionClick(data.id, data.type)
                                      }
                                    >
                                      {data?.type}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            )}

                            {jobTypeError && (
                              <p style={{ color: "red", marginTop: "10px" }}>
                                {jobTypeError}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col w-full">
                        <div className="flex flex-col w-full px-3">
                          <div className="flex flex-col w-full relative">
                            <div className="flex flex-col">
                              <p
                                style={{
                                  height: "18px",
                                  textAlign: "left",
                                  font: "normal normal medium 15px/23px Rubik",
                                  letterSpacing: "0px",
                                  textTransform: "uppercase",
                                  opacity: 1,
                                  fontWeight: "600",
                                  marginTop: "15px",
                                }}
                              >
                                Job Description
                                <span
                                  style={{ color: "red", marginLeft: "5px" }}
                                >
                                  *
                                </span>
                              </p>
                              <p
                                style={{
                                  height: "18px",
                                  textAlign: "left",
                                  font: "normal normal medium 15px/23px Rubik",
                                  letterSpacing: "0px",
                                  textTransform: "uppercase",
                                  opacity: 1,
                                  color: "#5a93ed",
                                  fontWeight: "600",
                                  marginTop: "5px",
                                }}
                                onClick={() => genAiDesc()}
                              >
                                <div className="flex items-center gap-2 overflow-hidden">
                                  <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                                    Create Job Description Using Ai
                                  </p>
                                  <Tooltip
                                    title={
                                      "Provide Job Title in detail for AI to generate a better job description"
                                    }
                                  >
                                    <img
                                      className="w-[20px] h-[16px] flex-shrink-0 cursor-pointer"
                                      src="/images/exclamation.svg"
                                      alt="exclamation"
                                    />
                                  </Tooltip>
                                </div>
                              </p>
                            </div>
                            <div
                              className={`custom-quill-containerw-full`}
                              style={{
                                width: "98%",
                                backgroundColor: "#ffffff",
                                borderRadius: "8px",
                                marginTop: "15px",
                              }}
                            >
                              <ReactQuill
                                className="custom-quill-editor"
                                value={decode(jobDescription) }
                                // onFocus={() => setIsDescriptionFocused(true)}
                                // onBlur={() => setIsDescriptionFocused(false)}
                                onChange={setJobDescription}
                                placeholder="Enter Job Description"
                                modules={{
                                  toolbar: [
                                    [{ header: [1, 2, false] }],
                                    ["bold", "italic", "underline", "strike"],
                                    [{ list: "ordered" }, { list: "bullet" }],
                                    ["link"],
                                    ["clean"],
                                  ],
                                }}
                                theme="snow"
                              />
                            </div>
                            {jobDescriptionError && (
                              <p style={{ color: "red", marginTop: "10px" }}>
                                {jobDescriptionError}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row justify-between px-5 py-5">
                        <div className="">
                          <p>
                            <span
                              style={{
                                color: "#1F2E39",
                                font: "normal normal medium 15px/23px Rubik",
                                fontWeight: "600",
                                fontSize: "15px",
                              }}
                            >
                              ACTIVE
                            </span>
                          </p>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={isActive}
                              onChange={(e) => setIsActive(e.target.checked)}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>

                        <div className="flex gap-2">
                          <button
                            type="submit"
                            style={{
                              top: "725px",
                              width: "150px",
                              height: "40px",
                              font: "normal normal medium 15px/23px Rubik",
                              background:
                                " #21D6AA 0% 0% no-repeat padding-box",
                              borderRadius: "5px",
                              opacity: 1,
                              color: "white",
                            }}
                            onClick={() => handleSubmit()}
                          >
                            {params?.jobid ? "SAVE CHANGES" : "SAVE"}
                          </button>

                          <p
                            className="text-red-500 py-3 text-[15px] font-rubik font-medium"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(-1)}
                          >
                            DISCARD
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Box>
          </React.Fragment>
        </>
      ) : (
        <>
          <Helmet>
            <style>{"body { background-color: #F3F5F9; }"}</style>
            <title>{params?.jobid ? "Edit Job" : "Add Job"}</title>
          </Helmet>
          <Box sx={{ display: "flex" }}>
            <Navbar data={activetab} />
            <Box
              className="px-6 pt-4"
              component="main"
              sx={{ flexGrow: 1, height: "100vh" }}
            >
              <div>
                <div className="mb-5 text-[20px] font-rubik font-normal">
                  {params?.jobid ? (
                    <HeaderBar title="Edit Job" toggleDrawer={toggleDrawer} />
                  ) : (
                    <HeaderBar title="Add Job" toggleDrawer={toggleDrawer} />
                  )}
                </div>
                <div className="px-3">
                  <div className="bg-white rounded-md shadow-md px-5 py-3">
                    <div
                      className="max-h-[calc(100vh-130px)] overflow-auto"
                      style={{ scrollbarWidth: "thin" }}
                    >
                      <div className="flex">
                        <div
                          style={{ width: "50%" }}
                          className="px-2 py-2 flex flex-row"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                              width: "25%",
                              marginTop: "10px",
                            }}
                          >
                            Date
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <div className=" flex flex-col w-[73%]">
                            <input
                              // type="date"
                              placeholder="Enter Job Date"
                              value={currentDate}
                              // readOnly
                              onChange={handleDateChange}
                              style={{
                                display: "block",
                                height: "40px",
                                border: "1px solid #708EA4",
                                background:
                                  "#FFFFFF 0% 0% no-repeat padding-box",
                                borderRadius: "5px",
                                opacity: 1,
                                padding: "7px",
                              }}
                            />
                            {/* {jobCodeError && (
                              <p style={{ color: "red", marginTop: "5px" }}>
                                {jobCodeError}
                              </p>
                            )} */}
                          </div>
                        </div>

                        <div
                          style={{ width: "50%" }}
                          className="px-2 py-2 flex flex-grow"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                              width: "18%",
                              marginTop: "10px",
                            }}
                          >
                            JobID
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <div className="flex flex-col w-[80%]">
                            <input
                              placeholder="e.g JD-0078"
                              value={jobCode}
                              onChange={handleInputChange(
                                setJobCode,
                                setJobCodeError
                              )}
                              readOnly={params?.totalcvs > 0 ? true : false}
                              onBlur={(e) => handleJobCodeBlur(e.target.value)}
                              style={{
                                display: "block",
                                height: "40px",
                                border: "1px solid #708EA4",
                                outline: params?.totalcvs > 0 ? "none" : "",
                                color: params?.totalcvs > 0 ? "gray" : "",
                                background:
                                  "#FFFFFF 0% 0% no-repeat padding-box",
                                borderRadius: "5px",
                                opacity: 1,
                                padding: "7px",
                              }}
                            />
                            {jobCodeError && (
                              <p style={{ color: "red", marginTop: "5px" }}>
                                {jobCodeError}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <div
                          style={{ width: "50%" }}
                          className="px-2 py-2 flex flex-row"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                              width: "25%",
                              marginTop: "10px",
                            }}
                          >
                            Job&nbsp;Title
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <div className="flex flex-col w-[73%]">
                            <input
                              placeholder="e.g Project Manager"
                              type="text"
                              value={jobTitle}
                              onChange={handleInputChange(
                                setJobTitle,
                                setJobTitleError
                              )}
                              style={{
                                display: "block",
                                height: "40px",
                                border: "1px solid #708EA4",
                                background:
                                  "#FFFFFF 0% 0% no-repeat padding-box",
                                borderRadius: "5px",
                                opacity: 1,
                                padding: "7px",
                              }}
                            />
                            {jobTitleError && (
                              <p style={{ color: "red", marginTop: "5px" }}>
                                {jobTitleError}
                              </p>
                            )}
                          </div>
                        </div>

                        <div
                          style={{ width: "50%" }}
                          className="px-2 py-2 flex flex-row"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                              width: "18%",
                              marginTop: "10px",
                            }}
                          >
                            Type
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>

                          <div
                            style={{ width: "80%" }}
                            className="dropdown__list__parent"
                            ref={dropdownRef}
                          >
                            <button
                              onClick={toggleDropdown}
                              className={`dropdown__list ${
                                isOpen ? "opens" : ""
                              }`}
                              style={{ borderColor: "rgb(112, 142, 164)" }}
                            >
                              {selectedOption}
                              {!isOpen ? (
                                <img
                                  src="images/custom-arrow.svg"
                                  alt="Dropdown Arrow"
                                />
                              ) : (
                                <img
                                  style={{ transform: "rotate(180deg)" }}
                                  src="images/custom-arrow.svg"
                                  alt="Dropdown Arrow"
                                />
                              )}
                            </button>

                            {isOpen && (
                              <div
                                className="dropdown__list__child"
                                style={{ borderColor: "rgb(112, 142, 164)" }}
                              >
                                <div
                                  id="jobtypeid"
                                  value={jobtype}
                                  onChange={(e) => setJobType(e.target.value)}
                                >
                                  {jobTypes?.map((data) => (
                                    <p
                                      key={data?.id}
                                      value={data?.type}
                                      style={{
                                        borderBottom:
                                          "1px solid rgb(112, 142, 164)",
                                      }}
                                      onClick={() =>
                                        handleOptionClick(data.id, data.type)
                                      }
                                    >
                                      {data?.type}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            )}

                            {jobTypeError && (
                              <p style={{ color: "red", marginTop: "5px" }}>
                                {jobTypeError}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ flex: "1px", width: "100%" }}
                        className="px-2 py-2"
                      >
                        <div className=" flex flex-row gap-8 mt-1">
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                            }}
                          >
                            Job Description
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              cursor: "pointer",
                              textTransform: "uppercase",
                              color: "#5a93ed",
                              opacity: 1,
                              fontWeight: "600",
                            }}
                            onClick={() => genAiDesc()}
                          >
                            <div className="flex flex-raw gap-1">
                              <p>Create Job Description Using Ai</p>
                              <Tooltip
                                title={
                                  "Provide Job Title in detail for AI to generate better job description"
                                }
                              >
                                <img
                                  className="w-[18px] h-[16px] mt-1  cursor-pointer"
                                  src="/images/exclamation.svg"
                                  alt="exclamation"
                                />
                              </Tooltip>
                            </div>
                          </p>
                        </div>
                        <div
                          className={`custom-quill-containerw-full`}
                          style={{
                            width: "99%",
                            backgroundColor: "#ffffff",
                            borderRadius: "8px",
                            marginTop: "15px",
                          }}
                        >
                          <ReactQuill
                            className="custom-quill-editor"
                            value={decode(jobDescription) }
                            // onFocus={() => setIsDescriptionFocused(true)}
                            // onBlur={() => setIsDescriptionFocused(false)}
                            onChange={setJobDescription}
                            placeholder="Enter Job Description"
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, false] }],
                                ["bold", "italic", "underline", "strike"],
                                [{ list: "ordered" }, { list: "bullet" }],
                                ["link"],
                                ["clean"],
                              ],
                            }}
                            theme="snow"
                          />
                        </div>
                        {jobDescriptionError && (
                          <p style={{ color: "red", marginTop: "5px" }}>
                            {jobDescriptionError}
                          </p>
                        )}
                      </div>
                      <div className=" flex flex-row justify-between">
                        <div className="px-2 py-2">
                          <p style={{ marginTop: "10px" }}>
                            <span
                              style={{
                                color: "#1F2E39",
                                font: "normal normal medium 15px/23px Rubik",
                                fontWeight: "600",
                                fontSize: "15px",
                                marginRight: "175px",
                              }}
                            >
                              ACTIVE
                            </span>
                          </p>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={isActive}
                              onChange={(e) => setIsActive(e.target.checked)}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <div className="flex px-2 py-[23px]">
                          <button
                            type="submit"
                            style={{
                              top: "725px",
                              left: "300px",
                              width: "150px",
                              height: "40px",
                              font: "normal normal medium 15px/23px Rubik",
                              background:
                                " #21D6AA 0% 0% no-repeat padding-box",
                              borderRadius: "5px",
                              opacity: 1,
                              color: "white",
                            }}
                            onClick={() => handleSubmit()}
                          >
                            {params?.jobid ? "SAVE CHANGES" : "SAVE"}
                          </button>

                          <p
                            className="text-red-500 px-5 py-3 text-[15px] font-rubik font-medium"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(-1)}
                          >
                            DISCARD
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default AddJob;
