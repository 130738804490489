import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { useState } from "react";
import "./SendEmail.css";
import { useMediaQuery, useTheme } from "@mui/material";

export default function EmailModal({
  setTo,
  setBody,
  setSubject,
  handleEmail,
  to,
  subject,
  candidatename,
}) {
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({ to: "", subject: "" });
  const handleOpen = () => setOpen(true);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("820"));
  const isMobile = useMediaQuery(theme.breakpoints.down("680"));
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };


  const handleClose = () => {
    setOpen(false);setSubject("Resume Analysis Report For ")
     
  }
  const handleEmailClick = () => {
    let emailErrors = {};
    if (!to) {
      emailErrors.to = "Email is required";
    } else if (!validateEmail(to)) {
      emailErrors.to = "Invalid email address";
    }

    if (!subject) {
      emailErrors.subject = "Subject is required";
    }

    if (Object.keys(emailErrors).length > 0) {
      setErrors(emailErrors);
    } else {
      setErrors({});
      handleEmail();
      handleClose();
    }
  };

  return (
    <div className="relative">
      <Button
        sx={{
          display: "flex",
          flexDirection: "column",
          color: "#000000DE",
        }}
        onClick={handleOpen}
      >
        <img src="/Email.svg" alt="Email" />
        <p className="font-medium text-[15px] leading-[23px] font-rubik">
          EMAIL
        </p>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isTablet ? 380 : isMobile ? 280 : 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
          }}
        >
          <div className="flex justify-end -mt-4 cursor-pointer absolute right-2.5">
            <img
              src="/images/greenCross2.svg"
              onClick={handleClose}
              alt="Close"
            />
          </div>
          <div className="flex flex-col">
            <p className="text-[15px] font-medium">
              EMAIL <span className="text-red-500">*</span>
            </p>
            <input
              type="email"
              placeholder="Enter receiver email ID"
              className="border-[1px] border-[#708ea4] rounded-md"
              // value={to}
              onChange={(e) => {
                setTo(e.target.value);
                setErrors({ ...errors, to: "" });
              }}
              style={{
                width: "100%",
                margin: "8px 0",
                padding: "8px",
                boxSizing: "border-box",
              }}
            />
            {errors.to && <Typography color="error">{errors.to}</Typography>}
          </div>

          <div className="flex flex-col">
            <p className="text-[15px] font-medium">
              Subject <span className="text-red-500">*</span>
            </p>
            <input
              type="text"
              className="border-[1px] border-[#708ea4] rounded-md"
              placeholder="Subject"
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
                setErrors({ ...errors, subject: "" });
              }}
              style={{
                width: "100%",
                margin: "8px 0",
                padding: "8px",
                boxSizing: "border-box",
              }}
            />
            {errors.subject && (
              <Typography color="error">{errors.subject}</Typography>
            )}
          </div>

          <div className="flex flex-col">
            <p className="text-[15px] font-medium">EMAIL BODY</p>
            <textarea
              placeholder="Enter details"
              // value={body}
              onChange={(e) => setBody(e.target.value)}
              style={{
                width: "100%",
                margin: "8px 0",
                padding: "8px",
                boxSizing: "border-box",
              }}
              className="border-[1px] border-[#708ea4] rounded-md"
              rows={4}
            />
            <p className="flex items-center gap-1">
              <img
                style={{
                  width: "15px",
                  height: "20px",
                }}
                src="/fileicon.svg"
              />
              <span>{candidatename}_Report.pdf</span>
            </p>
          </div>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            {/* <div
              onClick={handleEmailClick}
              className="buttonboxbuttton  text-[15px] text-[#1F2E39] cursor-pointer w-max"
            >
              Send Email{" "}
              <img
                style={{
                  width: "20px",
                  height: "20px",
                  transform: "rotate(45deg)",
                }}
                src="/images/black-arrow.svg"
                className=""
              />
            </div> */}

            <div className="flex justify-center mt-5 w-auto">
              <div
                className="buttonbox text-[15px] text-[#1F2E39] flex cursor-pointer "
                style={{ cursor: "pointer" }}
                onClick={handleEmailClick}
              >
                <text className="backbutton text-[15px] text-[#1F2E39] cursor-pointer w-max" style={{font:"normal normal medium 15px/23px Rubik"}}>
                   SEND EMAIL
                </text>
                 <img
                  className="iconbox"
                  style={{ width: "20px" }}
                  src="/images/black-arrow.svg"
                />
              </div>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
