import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();

export const AddNewClientInsert = async (body) => {
  const {
    customername,
    industryid,
    customerpanelurl,
    baseprompt,
    additionalbasepromptindusty,
    understandingjdprompt,
    understandingcvprompt,
    isdetailview,
    issourcing,
    sourcingemail,
    isscreening,
    isoverqualified,
    isoverexperienced,
    isarabicspeaking,
    isgender,
    isdrivinglic,
    iscustomcriticalskill,
    isexperiencesimiliar,
    iscurrentjoblocation,
    ishighestqualification,
    isbulkupload,
    is4cvupload,
    isage,
    calculationprompt,
    resultformatprompt,
    versionremark,
    show_deni,
    ismiddleeast,
    atssync,
    isstandardization,
    isactive,
    atsid,
    dailylimit,
    totallimit,
  } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "insertcustomer",
      customername: customername,
      industryid: industryid,
      customerpanelurl: customerpanelurl,
      baseprompt: baseprompt,
      additionalbasepromptindusty: additionalbasepromptindusty,
      understandingjdprompt: understandingjdprompt,
      isdetailview: isdetailview,
      issourcing: issourcing,
      understandingcvprompt: understandingcvprompt,
      calculationprompt: calculationprompt,
      resultformatprompt: resultformatprompt,
      versionremark: versionremark,
      show_deni: show_deni,
      sourcingemail: sourcingemail,
      ismiddleeast: ismiddleeast,
      atssync: atssync,
      isstandardization: isstandardization,
      isactive: isactive,
      isscreening: isscreening,
      isoverqualified: isoverqualified,
      isoverexperienced: isoverexperienced,
      isarabicspeaking: isarabicspeaking,
      isgender: isgender,
      isdrivinglic: isdrivinglic,
      iscustomcriticalskill: iscustomcriticalskill,
      isexperiencesimiliar: isexperiencesimiliar,
      iscurrentjoblocation: iscurrentjoblocation,
      ishighestqualification: ishighestqualification,
      isbulkupload: isbulkupload,
      is4cvupload: is4cvupload,
      isage: isage,
      atsid: atsid,
      dailylimit: dailylimit,
      totallimit: totallimit,
    },
  });
  return data;
};

export const updateClientDetails = async (body) => {
  const {
    customer,
    customername,
    industryid,
    customerpanelurl,
    baseprompt,
    additionalbasepromptindusty,
    understandingjdprompt,
    understandingcvprompt,
    calculationprompt,
    sourcingemail,
    resultformatprompt,
    versionremark,
    isdetailview,
    issourcing,
    isscreening,
    isoverqualified,
    isoverexperienced,
    isarabicspeaking,
    isgender,
    isdrivinglic,
    iscustomcriticalskill,
    isexperiencesimiliar,
    iscurrentjoblocation,
    ishighestqualification,
    isbulkupload,
    is4cvupload,
    isage,
    show_deni,
    ismiddleeast,
    atssync,
    isstandardization,
    isactive,
    atsid,
    dailylimit,
    totallimit,
  } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatecustomer",
      customername: customername,
      customer: customer,
      industryid: industryid,
      customerpanelurl: customerpanelurl,
      baseprompt: baseprompt,
      isdetailview: isdetailview,
      issourcing: issourcing,
      additionalbasepromptindusty: additionalbasepromptindusty,
      understandingjdprompt: understandingjdprompt,
      understandingcvprompt: understandingcvprompt,
      calculationprompt: calculationprompt,
      resultformatprompt: resultformatprompt,
      versionremark: versionremark,
      show_deni: show_deni,
      ismiddleeast: ismiddleeast,
      atssync: atssync,
      isstandardization: isstandardization,
      isactive: isactive,
      isscreening: isscreening,
      isoverqualified: isoverqualified,
      isoverexperienced: isoverexperienced,
      isarabicspeaking: isarabicspeaking,
      isgender: isgender,
      isdrivinglic: isdrivinglic,
      iscustomcriticalskill: iscustomcriticalskill,
      isexperiencesimiliar: isexperiencesimiliar,
      iscurrentjoblocation: iscurrentjoblocation,
      ishighestqualification: ishighestqualification,
      isbulkupload: isbulkupload,
      is4cvupload: is4cvupload,
      isage: isage,
      atsid: atsid,
      sourcingemail: sourcingemail,
      dailylimit: dailylimit,
      totallimit: totallimit,
    },
  });
  return data;
};
export const updateClientSetting = async (body) => {
  const { customer, show_deni, atssync, isstandardization, isactive } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updateclientsetting",
      customer: customer,
      show_deni: show_deni,
      atssync: atssync,
      isstandardization: isstandardization,
      isactive: isactive,
    },
  });
  return data;
};

export const AtsList = async () => {
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getatslist",
    },
  });
  return data;
};

export const IndustryList = async () => {
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getindustrylist",
    },
  });
  return data;
};

export const checkAtsCredentials = async (custid) => {
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "checkatscredentials",
      customer: custid,
    },
  });
  return data;
};
