import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { useMediaQuery } from "@mui/material";
import { storage } from "../../libs/localStorage";
import { Helmet } from "react-helmet";
import { StandardizationJasonFileApi } from "../../data/standardrization";

const StandardizationJasonFile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { analysisid } = useParams();
  const { retrieveToken } = storage();
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [data, setData] = useState();
  const token = retrieveToken();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const activetab = "View Std Data";

  useEffect(() => {
    if (token) {
      StandardizationJasonFileApi({ analysisid: Number(analysisid) })
        .then((response) => {
          if (response?.data[0].standardizedcvjson == (undefined || null)) {
            setData([]);
            setNoDataAvailable(true);
          } else {
            setData(response.data[0]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // navigate("/Unauthorized");
      window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
    }
  }, []);

  const formatData = (data) => {
    if (!data) return "";
    // Convert JSON data to a formatted string
    const formattedData = JSON.stringify(data, null, 2)
      .replace(/\\n/g, "\n") // Replace escaped new lines with actual new lines
      .replace(/\\t/g, "  ") // Replace escaped tabs with actual spaces
      .replace(/\\"/g, '"');
    return formattedData;
  };

  return isMobile ? (
    <>
      <div style={{ position: "sticky", top: "0" }}>
        <p
          style={{
            color: "#1F2E39",
            background: "white",
            fontSize: "17px",
            fontWeight: "700",
            marginTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "10px",
            display: "flex",
            boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",
          }}
        >
          <img
            onClick={() => window.history.back()}
            src="/images/Back.svg"
            style={{ cursor: "pointer" }}
          />
          &nbsp;&nbsp;&nbsp; Back
        </p>
      </div>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <div className="w-full  overflow-auto bg-white px-3 py-3">
          {noDataAvailable ? (
            <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
              <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                <p className="text-[30px] text-gray-500 font-bold">!</p>
              </div>
              <p className="text-[20px] text-gray-500 font-bold">
                Data not available
              </p>
            </div>
          ) : (
            <pre className=" text-ellipsis text-wrap px-3 text-justify">
              {formatData(data?.standardizedcvjson)}
            </pre>
          )}
        </div>
      </Box>
    </>
  ) : (
    <>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <Navbar data={activetab} />
        <div className="w-full  overflow-auto  px-4 py-4">
          <button  onClick={() => navigate(-1)} className="p-match mb-4 px-3">
            <img style={{ width: "16px" }} src="/images/arrow-left.svg" />
            Back
          </button>
          {noDataAvailable ? (
            <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
              <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                <p className="text-[30px] text-gray-500 font-bold">!</p>
              </div>
              <p className="text-[20px] text-gray-500 font-bold">
                Data not available
              </p>
            </div>
          ) : (
            <pre className=" text-ellipsis text-wrap px-8 text-justify">
              {formatData(data?.standardizedcvjson)}
            </pre>
          )}
        </div>
      </Box>
    </>
  );
};

export default StandardizationJasonFile;
