import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "./ErrorResume.css";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import { Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { storage } from "../../libs/localStorage";
import { Helmet } from "react-helmet";
import { useCandidateData } from "../../data/candidatedata";
import { commonData } from "../../data/commondata";

function ErrorResume() {
  const { retrieveToken } = storage();
  const token = retrieveToken();
  const navigate = useNavigate();
  const activetab = "";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [data, setData] = useState(null);
  const { GetErrorResume } = useCandidateData();
  const { GetDownloadUrl } = commonData();
  const location = useLocation();
  const params = location.state;
  const userType = localStorage.getItem("usertype");

  useEffect(() => {
    if (token) {
      GetErrorResume({
        jobid: params,
      })
        .then((response) => {
          if (response?.data == (undefined || null)) {
            setData([]);
            setNoDataAvailable(true);
          } else {
            setData(response?.data);
            setNoDataAvailable(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
    }
  }, []);

  function formatDateTime(dateString) {
    const date = new Date(dateString);

    // Format the date as MM/DD/YY
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });

    // Format the time as HH:MMam/pm
    let formattedTime = date
      .toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace(" ", " ")
      .toUpperCase(); // Remove the space and convert to lowercase

    return `${formattedDate} ${formattedTime}`;
  }

  const downloadResume = (url) => {
    url &&
      GetDownloadUrl({
        url: url,
      })
        .then((response) => {
          window.open(response?.data?.url, "_blank");
        })
        .catch((error) => {
          console.error(error);
        });
  };

  return (
    <>
      {isMobile ? (
        <>
          <Helmet>
            <style>{"body { background-color: #FFFFFF;}"}</style>
            <title>Error Resumes</title>
            {/* <meta name="description" content="App Description" /> */}
            {/* <meta name="theme-color" content="#008f68" /> */}
          </Helmet>
          <React.Fragment>
            <div>Error Resumes</div>
          </React.Fragment>
        </>
      ) : (
        <>
          <Helmet>
            <style>{"body { background-color: #FFFFFF;}"}</style>
            <title>Error Resumes</title>
          </Helmet>
          <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
            <Navbar data={activetab} />
            <Box component="main" sx={{ flexGrow: 1, p: 3, width: "80%" }}>
              <div class="analyst" className="flex ">
                <div className="w-[28%] ms-5">
                  <button onClick={() => navigate(-1)} className="p-match">
                    <img
                      style={{ width: "16px" }}
                      src="/images/arrow-left.svg"
                    />
                    Back
                  </button>
                </div>
              </div>

              <div className="w-full flex flex-wrap justify-between items-center mt-2 ">
                <div class="analyst-child">
                  <div class="flex gap-5">
                    <div class="notify-child flex gap-2">
                      <p>JOB ID: {data && data[0]?.atsjobcode}</p>
                    </div>
                    <div className="uppercase flex flex-row items-center gap-1">
                      JOB TITLE: {data && data[0]?.jobtitle}
                    </div>
                  </div>
                </div>
              </div>

              <div class="analyst-head">
                <p>Unprocessed Resumes</p>
              </div>
              <div className="custom-error-container">
                <p className="custom-error-text">
                  Some resumes were not uploaded successfully. Please review the
                  list below for more details.
                </p>
              </div>
              {noDataAvailable ? (
                <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                  <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                    <p className="text-[30px] text-gray-500 font-bold">!</p>
                  </div>
                  <p className="text-[20px] text-gray-500 font-bold">
                    Data not available
                  </p>
                </div>
              ) : (
                <div className="mx-[20px] mt-2.5 shadow-md ">
                  <div className="sticky z-10 top-0 bg-white border-b border-[#E0E0E0]">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="w-[185px] pr-3 pl-14 py-5 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                            Date&nbsp;Time
                          </th>
                          {/* {userType == "A" && (
                            <th className="w-[100px] px-3 py-5 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                              Job&nbsp;ID
                            </th>
                          )}
                          {userType == "A" && (
                            <th className="w-[140px] px-3 py-5 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                              Job&nbsp;Title
                            </th>
                          )} */}
                          <th className="w-[150px] px-3 py-5 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                            File&nbsp;Name
                          </th>
                          <th className="w-[285px] px-3 py-5 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                            Error&nbsp;Description
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              )}
              <div
                className="mx-[20px] max-h-[calc(100vh-327px)] overflow-auto shadow-md "
                style={{ scrollbarWidth: "thin" }}
              >
                <div className="">
                  <table className="min-w-full divide-y divide-gray-200">
                    <tbody className="bg-white divide-y divide-gray-200">
                      {data?.map((item, index) => (
                        <tr
                          key={index}
                          className={`${
                            index % 2 === 0
                              ? "bg-[#EDF4FF] border-none"
                              : "bg-white border-none"
                          }`}
                        >
                          <td className="w-[185px] px-5 py-4 text-sm text-gray-700 font-rubik ">
                            <div className="flex flex-row gap-4">
                              <img
                                className="w-7"
                                src="/images/icon-warning-24.svg"
                                alt="error-icon"
                              />
                              <span>{formatDateTime(item?.processeddate)}</span>
                            </div>
                          </td>
                          {/* {userType == "A" && (
                            <Tooltip
                              title={
                                item?.atsjobcode?.length > 10
                                  ? item.atsjobcode
                                  : ""
                              }
                            >
                              <td className="w-[100px] px-5 py-4 text-sm text-gray-700 font-rubik">
                                {item?.atsjobcode?.length > 10
                                  ? `${item?.atsjobcode.substring(0, 10)}...`
                                  : item?.atsjobcode}
                              </td>
                            </Tooltip>
                          )}
                          {userType == "A" && (
                            <td className=" w-[150px] px-5 py-4 text-sm text-gray-700 font-rubik">
                              <Tooltip
                                title={
                                  item?.jobtitle?.length > 15
                                    ? item.jobtitle
                                    : ""
                                }
                              >
                                <p>
                                  {item?.jobtitle?.length > 15
                                    ? `${item?.jobtitle.substring(0, 15)}...`
                                    : item?.jobtitle}
                                </p>
                              </Tooltip>
                              <p className="italic">{item?.customername}</p>
                            </td>
                          )} */}
                          <Tooltip
                            title={
                              item?.filename?.length > 28 ? item.filename : ""
                            }
                          >
                            <td
                              className="w-[150px] px-3 py-4 text-sm text-gray-700 font-rubik cursor-pointer"
                              onClick={() => downloadResume(item?.resumeurl)}
                            >
                              <div className="flex flex-row gap-2">
                                <img
                                  className="cursor-pointer"
                                  src="/images/resumee.svg"
                                  alt="Resume-download"
                                  onClick={() =>
                                    downloadResume(item?.resumeurl)
                                  }
                                />
                                <p>
                                  {item?.filename?.length > 40 ? (
                                    <>
                                      {item?.filename.substring(0, 20)}
                                      <br />
                                      {item?.filename.substring(20, 40)}...
                                    </>
                                  ) : item?.filename?.length > 20 ? (
                                    <>
                                      {item?.filename.substring(0, 20)}
                                      <br />
                                      {item?.filename.substring(20)}
                                    </>
                                  ) : (
                                    item?.filename
                                  )}
                                </p>
                              </div>
                            </td>
                          </Tooltip>

                          <td className=" w-[285px] px-3 py-4 text-sm text-gray-700 font-rubik ">
                            {item?.errormessage}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default ErrorResume;
