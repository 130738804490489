import React, { useState, useEffect, useRef } from "react";
import "./TalentPool.css";
import Box from "@mui/material/Box";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useCandidateData } from "../../data/candidatedata";
import { ToastContainer } from "react-toastify";
import { Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { storage } from "../../libs/localStorage";
import { Helmet } from "react-helmet";
import { commonData } from "../../data/commondata";
import { useJobsData } from "../../data/jobsdata";
import { auth } from "../../data/auth";

const capitalizeWords = (str) => {
  return str?.replace(/\b\w/g, (char) => char?.toUpperCase());
};
function TalentPool() {
  const { GetTalentPool } = commonData();
  const { GetAllJobs } = useJobsData();
  const [toggleView, setToggleView] = useState(false);
  const [detailsView, setDetailsView] = useState(true);
  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  const { GetCustomerById } = useCandidateData();
  const [params, setParams] = useState(location.state);
  const { retrieveToken } = storage();
  const [data, setData] = useState([]);
  const { GetCandidatesByIds } = useCandidateData();
  const { GetDownloadUrl } = commonData();
  const navigate = useNavigate();
  const activetab = "";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const token = retrieveToken();
  const { VerifyToken } = auth();
  const [isSecondOpen, setSecondIsOpen] = useState(false);
  const [filterdata, setFilterdata] = useState(
    params?.atsjobcode && params?.jobtitle
      ? params?.atsjobcode + " - " + params?.jobtitle
      : "Job List"
  );
  // const [filterdata, setFilterdata] = useState("Job List");
  const [customer, setcustomer] = useState(null);
  const [selectedFilterdata, setSelectedFilterdata] = useState("percentage");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [jobs, setJobs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const customerid = Number(localStorage.getItem("customerid"));
  const [allData, setAllData] = useState(null);
  const [sort, setSort] = useState({
    sortby: "match_percentage",
    sortorder: "DESC",
  });
  const [shouldNavigateBack, setShouldNavigateBack] = useState(false);
  // Function to update sorting state
  const updateSort = (newSort) => {
    setSort((prevSort) => ({
      ...prevSort,
      ...newSort,
    }));
  };

  useEffect(() => {
    if (data) {
      switch (sort?.sortorder) {
        case "ASC":
          const sortedAsc = [...data].sort((a, b) => {
            if (a[sort?.sortby] < b[sort?.sortby]) return -1; // Ascending order
            if (a[sort?.sortby] > b[sort?.sortby]) return 1;
            return 0;
          });
          setData(sortedAsc);
          break;
        case "DESC":
          const sortedDesc = [...data].sort((a, b) => {
            if (a[sort?.sortby] > b[sort?.sortby]) return -1; // Descending order
            if (a[sort?.sortby] < b[sort?.sortby]) return 1;
            return 0;
          });
          setData(sortedDesc);
          break;
        default:
          break;
      }
    }
  }, [sort]);

  const toggleSecondDropdown = () => {
    setSecondIsOpen((prev) => !prev);
  };

  const handleSecondOptionClick = (Secondoption) => {
    setFilterdata(Secondoption.atsjobcode + " - " + Secondoption.jobtitle);
    toggleSecondDropdown();
    setIsLoading(true);
    GetTalentPool({
      jd: Secondoption?.jobdescription,
      customerid: customerid,
    })
      .then((response) => {
        setData(response?.candidates);
        fetchCandidates(response?.candidates);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    updateSort({
      sortby: "match_percentage",
      sortorder: "DESC",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    btnSearch();
  }, [searchText]);

  const searchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const fetchCandidates = async (data) => {
    const cids =
      data && data?.map((item) => `${item?.candidate_id || ""}`).join(",");
    GetCandidatesByIds({
      candidateids: cids,
    })
      .then((response) => {
        if (response?.header?.status == 200) {
          // setCandidatesData(response?.data[0]);
          // window.alert(response?.data[0]?.candidateid);

          // const updatedList = () => {
          const updatedList = data?.map((item) => {
            const val = response?.data?.find(
              (x) => x?.candidateid == item?.candidate_id
            );
            return val ? { ...item, ...val } : item;
          });
          setData(updatedList);
          setAllData(updatedList);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    shouldNavigateBack && navigate(-1, { replace: true });
  }, [shouldNavigateBack]);

  useEffect(() => {
    // let shouldNavigateBack = false;
    try {
      if (token) {
        if (!params?.singleusetoken) {
          setShouldNavigateBack(true);
        } else {
          VerifyToken({ token: params?.singleusetoken })
            .then((response) => {
              if (response?.header?.status == 200) {
                GetCustomerById()
                .then((response) => {
                  setcustomer(response?.data[0]);
                  response?.data[0]?.isdetailview === true && setDetailsView(true);
                })
                .catch((error) => {
                  console.error(error);
                });
              setIsLoading(true);
              GetTalentPool({
                jd: params?.jobdescription,
                customerid: customerid,
              })
                .then((response) => {
                  setData(response?.candidates);
                  fetchCandidates(response?.candidates);
                })
                .catch((error) => {
                  console.error(error);
                })
                .finally(() => {
                  setIsLoading(false); // Move this here to ensure it runs after the API call
                });
              GetAllJobs()
                .then((response) => {
                  setJobs(response?.data);
                })
                .catch((error) => {
                  console.error(error);
                });
              } else {
                setShouldNavigateBack(true);
              }
            })
            .catch((error) => {
              console.error(error);
              setShouldNavigateBack(true);
            });          
        }
      } else {
        window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
      }
    } catch (error) {
      console.error(error);
    }
    if (shouldNavigateBack) {
      navigate(-1, { replace: true });
    }
  }, []);

  const btnSearch = async () => {
    if (searchText) {
      const results = allData?.filter((item) =>
        item?.candidate_name?.toLowerCase()?.includes(searchText.toLowerCase())
      );
      results && setData(results);
    } else {
      setData(allData);
      updateSort({
        sortby: "match_percentage",
        sortorder: "DESC",
      });
    }
  };

  const clearSearch = async () => {
    setSearchText("");
  };

  const downloadResume = (url) => {
    url &&
      GetDownloadUrl({
        url: url,
      })
        .then((response) => {
          window.open(response?.data?.url, "_blank");
        })
        .catch((error) => {
          console.error(error);
        });
  };

  const buttonHandle = () => {
    setToggleView(false);
    setDetailsView(!detailsView);
  };
  const buttonHandle2 = () => {
    setDetailsView(false);
  };

  const secondDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        secondDropdownRef.current &&
        !secondDropdownRef.current.contains(event.target)
      ) {
        setSecondIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function formatDateTime(dateString) {
    const date = new Date(dateString);

    // Format the date as MM/DD/YY
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });

    // Format the time as HH:MMam/pm
    let formattedTime = date
      .toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace(" ", " ")
      .toUpperCase(); // Remove the space and convert to lowercase

    return `${formattedDate} ${formattedTime}`;
  }
  function formatDateTime2(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    });

    return `${formattedDate} `;
  }

  const yourColumnsArray = [
    { id: 1, title: "Column 1", isVisible: true },
    { id: 2, title: "Column 2", isVisible: true },
    { id: 3, title: "Column 7", isVisible: true },
    { id: 4, title: "Column 3", isVisible: customer?.show_deni },
    { id: 5, title: "Column 4", isVisible: customer?.ismiddleeast },
    { id: 6, title: "Column 5", isVisible: customer?.isgender },
    { id: 7, title: "Column 6", isVisible: customer?.iscurrentjoblocation },
    { id: 8, title: "Column 8", isVisible: true },
    { id: 9, title: "Column 9", isVisible: true },
    { id: 10, title: "Column 10", isVisible: true },
    { id: 11, title: "Column 11", isVisible: true },
    { id: 12, title: "Column 12", isVisible: customer?.isexperiencesimiliar },
    { id: 13, title: "Column 13", isVisible: customer?.iscustomcriticalskill },
    { id: 14, title: "Column 14", isVisible: customer?.ishighestqualification },
    { id: 15, title: "Column 15", isVisible: customer?.isdrivinglic },
    { id: 16, title: "Column 16", isVisible: customer?.isarabicspeaking },
    { id: 17, title: "Column 17", isVisible: customer?.isoverqualified },
    { id: 18, title: "Column 18", isVisible: customer?.isoverexperienced },
    { id: 19, title: "Column 19", isVisible: customer?.isage },
    { id: 20, title: "Column 20", isVisible: true },
    { id: 21, title: "Column 21", isVisible: true },
  ];

  const totalColumns = 21;
  const fullWidth = 3400; // Maximum width when all columns are visible
  const columnWidth = fullWidth / totalColumns;

  // Calculate the current table width based on visible columns
  const visibleColumns = yourColumnsArray.filter(
    (column) => column.isVisible
  ).length;
  const tableWidth = Math.round(visibleColumns * columnWidth);
  return (
    <>
      <ToastContainer />
      {isLoading && (
        <div
          id="loader-container"
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            inset: "0",
            zIndex: 2,
          }}
        >
          <img style={{ width: "120px" }} src="/images/Loader.gif" />
        </div>
      )}
      {isMobile ? (
        <>
          <Helmet>
            <style>{"body { background-color: #f3f5f9;}"}</style>
            <title>Talent Pool</title>
          </Helmet>
          <React.Fragment>
            <div className="sticky top-0 bg-[#F3F5F9] pb-1">
              <div className="flex flex-row justify-between bg-white p-[10px_20px] gap-4 shadow-md shadow-[rgba(91,147,237,0.2)]">
                <div className="flex flex-row gap-3 ">
                  <div className="cursor-pointer mt-1">
                    <img
                      onClick={() => window.history.back()}
                      src="/images/Back.svg"
                      alt="back"
                    />
                  </div>

                  <div className="text-[#21d6aa] text-[15px] font-bold cursor-pointer">
                    Talent Pool
                  </div>
                </div>
              </div>
              <div className="px-3">
                <div className="search-container py-4">
                  <input
                    type="text"
                    id="search"
                    placeholder="Search Candidate"
                    className="border-none outline-none h-10 pl-2 w-[200px] flex-1"
                    value={searchText}
                    onChange={searchTextChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") btnSearch();
                    }}
                  />

                  {searchText && (
                    <button
                      style={{
                        position: "absolute",
                        right: "60px",
                        border: "none",
                        outline: "none",
                        background: "transparent",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={clearSearch}
                    >
                      <img
                        src="/images/greenCross1.svg"
                        style={{ width: "10px", height: "10px" }}
                        alt="Clear"
                      />
                    </button>
                  )}

                  <button
                    className="border-none outline-none h-10 pt-2"
                    type="submit"
                    onClick={() => btnSearch()}
                  >
                    <img
                      className="bg-inherit"
                      src="/images/Search Icon.svg"
                      alt="Search"
                    />
                  </button>
                </div>

                <div className="relative w-full z-[99]" ref={secondDropdownRef}>
                  <button
                    onClick={toggleSecondDropdown}
                    className={`bg-white p-5 w-full flex items-center justify-between border-none shadow-[0_0_20px_#5b93ed33] h-[40px] text-[15px] ${
                      isSecondOpen ? "rounded-t-md text-black" : "rounded-md"
                    }`}
                  >
                    {filterdata || "Job List"}

                    {!isSecondOpen ? (
                      <img src="images/custom-arrow.svg" />
                    ) : (
                      <img
                        style={{ transform: "rotate(180deg)" }}
                        src="images/custom-arrow.svg"
                      />
                    )}
                  </button>

                  {isSecondOpen && (
                    <div
                      className="absolute w-full bg-white px-[10px] shadow-[0px_10px_20px_#5b93ed33] rounded-b-lg  max-h-[200px] overflow-y-auto"
                      style={{ scrollbarWidth: "thin" }}
                    >
                      <div className="pl-2">
                        {jobs &&
                          jobs?.map((data, i) => (
                            <p
                              key={i}
                              onClick={() => handleSecondOptionClick(data)}
                              className="cursor-pointer border-t-2 border-t-[#5b93ed33] border-b-0 text-[15px] p-[5px]"
                            >
                              {data?.atsjobcode} - {data?.jobtitle}
                            </p>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {noDataAvailable ? (
              <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                  <p className="text-[30px] text-gray-500 font-bold">!</p>
                </div>
                <p className="text-[20px] text-gray-500 font-bold">
                  Data not available
                </p>
              </div>
            ) : (
              <div className="!flex !flex-col mx-3">
                {data &&
                  data?.map((item, i) => (
                    <div
                      className="bg-[#ffffff] rounded-md p-2 mt-2.5 flex flex-col gap-1"
                      key={i}
                    >
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-row gap-2">
                          {item?.linkedinurl ? (
                            <Tooltip
                              title={
                                item?.linkedinurl?.length > 20
                                  ? item?.linkedinurl
                                  : ""
                              }
                            >
                              <a
                                href={
                                  item?.linkedinurl?.startsWith("http")
                                    ? item.linkedinurl
                                    : `https://${item?.linkedinurl}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  className="cursor-pointer"
                                  src="/images/linkedin.svg"
                                  alt="LinkedIn"
                                />
                              </a>
                            </Tooltip>
                          ) : null}
                          <Tooltip title="Download Resume">
                            {item?.resumeurl && (
                              <img
                                className="cursor-pointer"
                                src="/images/resumee.svg"
                                alt="resume"
                                onClick={() => downloadResume(item?.resumeurl)}
                              />
                            )}
                          </Tooltip>
                        </div>
                      </div>

                      <div className="flex flex-row justify-between">
                        <div className="text-sm text-gray-700 font-medium text-[15px]">
                          {item?.candidate_name ? item?.candidate_name : " "}
                        </div>
                        <p className="text-sm text-gray-700 font-normal text-[15px]">
                          {item?.match_percentage
                            ? item?.match_percentage + "%"
                            : ""}
                        </p>
                      </div>
                      <div className="flex flex-row justify-between text-sm text-gray-700 font-normal text-[15px]">
                        <span>{item?.cvtitle ? item?.cvtitle : ""}</span>
                        <span>
                          {item?.total_experience
                            ? item?.total_experience + "y Exp"
                            : ""}
                        </span>
                      </div>

                      <div className="flex flex-row justify-between text-sm text-gray-700 font-normal text-[15px]">
                        {customer?.ismiddleeast && (
                          <div>
                            {customer?.ismiddleeast ? (
                              <p>
                                {" "}
                                {item?.nationality === "unknown"
                                  ? ""
                                  : item?.nationality}
                              </p>
                            ) : (
                              <text className="flex justify-center w-[100px] items-center  font-normal">
                                {" "}
                              </text>
                            )}
                          </div>
                        )}
                        {customer?.isgender && (
                          <span>
                            {item.gender === "F"
                              ? "Female"
                              : item.gender === "M"
                              ? "Male"
                              : item.gender === "O"
                              ? "Other"
                              : ""}
                          </span>
                        )}
                      </div>

                      <div className="flex flex-raw gap-1 text-sm text-gray-700 font-normal text-[15px]">
                        {item?.currentjoblocation && (
                          <img src="/images/loction-icon.svg" alt="loction" />
                        )}
                        <span>
                          {item?.currentjoblocation
                            ? item?.currentjoblocation
                            : ""}
                        </span>
                      </div>

                      <div className="flex flex-raw gap-1 text-sm text-gray-700 font-normal text-[15px]">
                        {item?.phone && (
                          <img src="/images/phone-icon.svg" alt="phone" />
                        )}
                        <span>{item?.phone ? item?.phone : ""}</span>
                      </div>

                      <div className="flex flex-raw gap-1 text-sm text-gray-700 font-normal text-[15px]">
                        {item?.email && (
                          <img src="/images/email-icon.svg" alt="email" />
                        )}
                        <span>{item?.email ? item?.email : ""}</span>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </React.Fragment>
        </>
      ) : (
        <>
          <Helmet>
            <style>{"body { background-color: #FFFFFF;}"}</style>
            <title>Talent Pool</title>
          </Helmet>
          <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
            <Navbar data={activetab} />
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 3, width: "80%", background: "#f3f5f9" }}
            >
              <div class="analyst1" className="flex ">
                <div className="w-[28%] ms-5  ">
                  <button
                    onClick={() => navigate(-1)}
                    className="text-[20px] font-bold text-[#1f2e39] border-none flex gap-[10px] items-center cursor-pointer pb-3"
                  >
                    <img className="w-4" src="/images/arrow-left.svg" />
                    Back
                  </button>
                </div>
              </div>

              <div className="flex flex-row justify-between pl-5 pb-3">
                <div
                  className="relative w-[400px] z-[99] "
                  ref={secondDropdownRef}
                >
                  <button
                    onClick={toggleSecondDropdown}
                    className={`dropdown_sort ${
                      isSecondOpen ? "secondopen" : ""
                    }`}
                  >
                    {filterdata || "Job List"}

                    {!isSecondOpen ? (
                      <img src="images/custom-arrow.svg" />
                    ) : (
                      <img
                        style={{ transform: "rotate(180deg)" }}
                        src="images/custom-arrow.svg"
                      />
                    )}
                  </button>

                  {isSecondOpen && (
                    <div
                      className="dropdown_sort_by_list  max-h-[200px] overflow-y-auto"
                      style={{ scrollbarWidth: "thin" }}
                    >
                      <div>
                        {jobs &&
                          jobs?.map((data, i) => (
                            <p
                              key={i}
                              onClick={() => handleSecondOptionClick(data)}
                            >
                              {data?.atsjobcode} - {data?.jobtitle}
                            </p>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex flex-row justify-between  pb-3 gap-5">
                  <div
                    className="search-container"
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      width: "400px",
                    }}
                  >
                    <input
                      type="text"
                      id="search"
                      placeholder="Search Candidate"
                      style={{
                        border: "none",
                        outline: "none",
                        height: "40px",
                        paddingLeft: "10px",
                        width: "200px",
                        flex: 1,
                      }}
                      value={searchText}
                      onChange={searchTextChange}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") btnSearch();
                      }}
                    />

                    {searchText && (
                      <button
                        style={{
                          position: "absolute",
                          right: "40px",
                          border: "none",
                          outline: "none",
                          background: "transparent",
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={clearSearch}
                      >
                        <img
                          src="/images/greenCross1.svg"
                          style={{ width: "10px", height: "10px" }}
                          alt="Clear"
                        />
                      </button>
                    )}

                    <button
                      style={{
                        border: "none",
                        outline: "none",
                        height: "40px",
                        paddingTop: "9px",
                      }}
                      type="submit"
                      onClick={() => btnSearch()}
                    >
                      <img
                        style={{ background: "inherit" }}
                        src="/images/Search Icon.svg"
                      />
                    </button>
                  </div>
                  <div
                    class="justify-center w-34 h-10  flex flex-raw"
                    style={{ boxShadow: "0px 0px 20px #5B93ED33" }}
                  >
                    <Tooltip title={"Summary View"}>
                      <button
                        className="w-[42px] flex items-center justify-center"
                        onClick={() => buttonHandle2()}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            !toggleView && !detailsView ? "#21D6AA" : "white",
                        }}
                      >
                        {!toggleView && !detailsView ? (
                          <img
                            className="w-5 "
                            src="/images/white-grid.svg"
                            alt="Button 2"
                          />
                        ) : (
                          <img
                            className="w-5 "
                            src="/images/4-box.svg"
                            alt="Button 2"
                          />
                        )}
                      </button>
                    </Tooltip>
                    {/* {customer?.isdetailview === true && ( */}
                    <Tooltip title={"Detailed View"}>
                      <button
                        className="w-[42px] flex items-center justify-center"
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            detailsView === true ? "#21D6AA" : "white",
                        }}
                        onClick={() => buttonHandle()}
                      >
                        {detailsView ? (
                          <img
                            className="w-12 "
                            src="/images/viewdetails2.svg"
                            alt="Button 1"
                          />
                        ) : (
                          <img
                            className="w-12"
                            src="/images/viewdetails.svg"
                            alt="Button 1"
                          />
                        )}
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>

              {detailsView ? (
                noDataAvailable ? (
                  <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                    <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                      <p className="text-[30px] text-gray-500 font-bold">!</p>
                    </div>
                    <p className="text-[20px] text-gray-500 font-bold">
                      Data not available
                    </p>
                  </div>
                ) : (
                  <>
                    <div
                      className="2xl:min-w-[100%]   overflow-y-hidden ms-5"
                      style={{
                        scrollbarWidth: "thin",
                        scrollbarColor: "auto transparent",
                      }}
                    >
                      <div
                        className={`overflow-auto `}
                        style={{
                          width: `${tableWidth + 100}px`,
                          scrollbarWidth: "thin",
                          scrollbarColor: "auto transparent",
                        }}
                      >
                        <table className="w-full bg-white border border-[#E0E0E0] rounded-sm divide-gray-200">
                          <thead>
                            <tr>
                              <th className="!w-[70px] px-2 py-3 font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase"></th>
                              <th className="!w-[100px] cursor-pointer  px-2 py-3 text-start font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px]"
                                  onClick={() => {
                                    updateSort({
                                      sortby: "match_percentage",
                                      sortorder:
                                        sort?.sortby === "match_percentage"
                                          ? sort?.sortorder === "ASC"
                                            ? "DESC"
                                            : "ASC"
                                          : "ASC",
                                    });
                                  }}>
                                Match %{" "}
                                <span
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "start",
                                  }}
                                >
                                  {sort?.sortby != "match_percentage" ? (
                                    <img
                                      src="/images/descendant.svg"
                                      alt="Sort"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  ) : sort?.sortorder === "DESC" ? (
                                    <img
                                      src="/images/asc.svg"
                                      alt="Ascending"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="/images/desc.svg"
                                      alt="Descending"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  )}
                                </span>
                              </th>
                              <th className="!w-[160px] cursor-pointer px-2 py-3 text-start font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px]"
                                  onClick={() => {
                                    updateSort({
                                      sortby: "candidate_name",
                                      sortorder:
                                        sort?.sortby === "candidate_name"
                                          ? sort?.sortorder === "ASC"
                                            ? "DESC"
                                            : "ASC"
                                          : "ASC",
                                    });
                                  }}>
                                Name{" "}
                                <span
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "start",
                                  }}
                                >
                                  {sort?.sortby != "candidate_name" ? (
                                    <img
                                      src="/images/descendant.svg"
                                      alt="Sort"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  ) : sort?.sortorder === "DESC" ? (
                                    <img
                                      src="/images/asc.svg"
                                      alt="Ascending"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="/images/desc.svg"
                                      alt="Descending"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  )}
                                </span>
                              </th>
                              {customer?.isgender && (
                                <th className="!w-[100px] cursor-pointer px-2 py-3 text-start font-medium text-blue 2xl:text-[16px] lg:text-[13px] xl:text-[15px]"
                                  onClick={() => {
                                    updateSort({
                                      sortby: "gender",
                                      sortorder:
                                        sort?.sortby === "gender"
                                          ? sort?.sortorder === "ASC"
                                            ? "DESC"
                                            : "ASC"
                                          : "ASC",
                                    });
                                  }}>
                                  Gender{" "}
                                  <span
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "start",
                                    }}
                                  >
                                    {sort?.sortby != "gender" ? (
                                      <img
                                        src="/images/descendant.svg"
                                        alt="Sort"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    ) : sort?.sortorder === "DESC" ? (
                                      <img
                                        src="/images/asc.svg"
                                        alt="Ascending"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src="/images/desc.svg"
                                        alt="Descending"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    )}
                                  </span>
                                </th>
                              )}
                              <th className="!w-[200px] cursor-pointer px-2 py-3 text-start font-medium text-blue 2xl:text-[16px] lg:text-[13px] xl:text-[15px]"
                                onClick={() => {
                                  updateSort({
                                    sortby: "cvtitle",
                                    sortorder:
                                      sort?.sortby === "cvtitle"
                                        ? sort?.sortorder === "ASC"
                                          ? "DESC"
                                          : "ASC"
                                        : "ASC",
                                  });
                                }}>
                                CV Title{" "}
                                <span
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "start",
                                  }}
                                >
                                  {sort?.sortby != "cvtitle" ? (
                                    <img
                                      src="/images/descendant.svg"
                                      alt="Sort"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  ) : sort?.sortorder === "DESC" ? (
                                    <img
                                      src="/images/asc.svg"
                                      alt="Ascending"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="/images/desc.svg"
                                      alt="Descending"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  )}
                                </span>
                              </th>

                              <th className="!w-[200px] cursor-pointer px-2 py-3 text-start font-medium text-blue 2xl:text-[16px] lg:text-[13px] xl:text-[15px]"
                                onClick={() => {
                                  updateSort({
                                    sortby: "currentjoblocation",
                                    sortorder:
                                      sort?.sortby === "currentjoblocation"
                                        ? sort?.sortorder === "ASC"
                                          ? "DESC"
                                          : "ASC"
                                        : "ASC",
                                  });
                                }}>
                                Current Location{" "}
                                <span
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "start",
                                  }}
                                >
                                  {sort?.sortby != "currentjoblocation" ? (
                                    <img
                                      src="/images/descendant.svg"
                                      alt="Sort"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  ) : sort?.sortorder === "DESC" ? (
                                    <img
                                      src="/images/asc.svg"
                                      alt="Ascending"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="/images/desc.svg"
                                      alt="Descending"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  )}
                                </span>
                              </th>
                              {customer?.ismiddleeast && (
                                <th className="!w-[200px] cursor-pointer px-2 py-3 text-start font-medium text-blue 2xl:text-[16px] lg:text-[13px] xl:text-[15px]"
                                  onClick={() => {
                                    updateSort({
                                      sortby: "nationality",
                                      sortorder:
                                        sort?.sortby === "nationality"
                                          ? sort?.sortorder === "ASC"
                                            ? "DESC"
                                            : "ASC"
                                          : "ASC",
                                    });
                                  }}>
                                  Nationality{" "}
                                  <span
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "start",
                                    }}
                                  >
                                    {sort?.sortby != "nationality" ? (
                                      <img
                                        src="/images/descendant.svg"
                                        alt="Sort"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    ) : sort?.sortorder === "DESC" ? (
                                      <img
                                        src="/images/asc.svg"
                                        alt="Ascending"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src="/images/desc.svg"
                                        alt="Descending"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    )}
                                  </span>
                                </th>
                              )}

                              <th className="!w-[100px] cursor-pointer px-2 py-3 text-start font-medium text-blue 2xl:text-[16px] lg:text-[13px] xl:text-[15px]"
                                onClick={() => {
                                  updateSort({
                                    sortby: "total_experience",
                                    sortorder:
                                      sort?.sortby === "total_experience"
                                        ? sort?.sortorder === "ASC"
                                          ? "DESC"
                                          : "ASC"
                                        : "ASC",
                                  });
                                }}>
                                Total Exp.{" "}
                                <span
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "start",
                                  }}
                                >
                                  {sort?.sortby != "total_experience" ? (
                                    <img
                                      src="/images/descendant.svg"
                                      alt="Sort"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  ) : sort?.sortorder === "DESC" ? (
                                    <img
                                      src="/images/asc.svg"
                                      alt="Ascending"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="/images/desc.svg"
                                      alt="Descending"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  )}
                                </span>
                              </th>
                              <th className="!w-[200px] cursor-pointer  px-2 py-3  text-start font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] ">
                                Contact{" "}
                              </th>

                              <th className="!w-[300px] cursor-pointer px-2 py-3 text-start font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] ">
                                Email{" "}
                              </th>
                              <th className="!w-[300px] cursor-pointer px-2 py-3  text-start font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] ">
                                LinkedIn{" "}
                              </th>
                            </tr>
                          </thead>
                        </table>

                        <div
                          className={`${
                            noDataAvailable
                              ? "max-h-[calc(100vh-350px)]"
                              : "max-h-[calc(100vh-305px)]"
                          }  overflow-auto shadow-md`}
                          style={{
                            scrollbarWidth: "thin",
                            scrollbarColor: "auto transparent",
                          }}
                        >
                          {noDataAvailable || data?.length === 0 ? (
                            <table className="w-full border-l border-[#E0E0E0] rounded-sm">
                              <tbody>
                                <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                                  <div className="rounded-full border-gray-500 border-2 flex w-12 h-12 justify-center items-center">
                                    <p className="text-[30px] text-gray-500 font-bold">
                                      !
                                    </p>
                                  </div>
                                  <p className="text-[20px] text-gray-500 font-bold">
                                    Data not available
                                  </p>
                                </div>
                              </tbody>
                            </table>
                          ) : (
                            <table className="min-w-full divide-y divide-gray-200">
                              <tbody className="bg-white divide-y divide-gray-200 font-rubik">
                                {data && data?.map((item, i) => (
                                  <tr
                                    key={i}
                                    className={`${
                                      i % 2 === 0
                                        ? "bg-[#EDF4FF] border-none"
                                        : "bg-white border-none"
                                    }`}
                                  >
                                    <td className="!w-[70px] pl-5  py-3 text-[15px]  text-center font-normal text-[#21D6AA]">
                                      <Tooltip title="Download Resume">
                                        <img
                                          className=" items-center  cursor-pointer"
                                          src="/images/resumee.svg"
                                          onClick={() =>
                                            downloadResume(item?.resumeurl)
                                          }
                                        />
                                      </Tooltip>
                                    </td>
                                    <td className=" !w-[100px] text-center  px-2 pl-1 py-3 text-sm text-gray-700 font-normal text-[15px]">
                                      {item?.match_percentage
                                        ? item?.match_percentage + "%"
                                        : ""}
                                    </td>

                                    <td className=" !w-[160px] text-start px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                      <Tooltip
                                        title={
                                          item?.candidate_name?.length > 15
                                            ? item?.candidate_name
                                            : ""
                                        }
                                      >
                                        {item?.candidate_name?.length > 15
                                          ? `${item?.candidate_name.substring(
                                              0,
                                              15
                                            )}...`
                                          : item?.candidate_name
                                          ? item?.candidate_name
                                          : ""}
                                      </Tooltip>
                                    </td>
                                    {customer?.isgender && (
                                      <td className=" !w-[100px] text-start px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        {item.gender === "F"
                                          ? "Female"
                                          : item.gender === "M"
                                          ? "Male"
                                          : item.gender === "O"
                                          ? "Other"
                                          : ""}
                                      </td>
                                    )}
                                    <td className=" !w-[200px] text-start px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                      <Tooltip
                                        title={
                                          item?.cvtitle?.length > 23
                                            ? item?.cvtitle
                                            : ""
                                        }
                                      >
                                        {item?.cvtitle?.length > 23
                                          ? `${item?.cvtitle.substring(
                                              0,
                                              23
                                            )}...`
                                          : item?.cvtitle
                                          ? item?.cvtitle
                                          : ""}
                                      </Tooltip>
                                    </td>
                                    <td className=" !w-[200px] text-start px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                      <Tooltip
                                        title={
                                          item?.currentjoblocation?.length > 21
                                            ? item?.currentjoblocation
                                            : ""
                                        }
                                      >
                                        {item?.currentjoblocation?.length > 21
                                          ? `${item?.currentjoblocation.substring(
                                              0,
                                              21
                                            )}...`
                                          : item?.currentjoblocation
                                          ? item?.currentjoblocation
                                          : ""}
                                      </Tooltip>
                                    </td>
                                    {customer?.ismiddleeast && (
                                      <td className=" !w-[200px] text-start px-2 py-3 text-sm text-gray-700 font-normal text-[15px]">
                                        <Tooltip
                                          title={
                                            item?.nationality?.length > 12
                                              ? item?.nationality
                                              : ""
                                          }
                                        >
                                          {item?.nationality?.length > 12
                                            ? `${item?.nationality.substring(
                                                0,
                                                12
                                              )}...`
                                            : item?.nationality
                                            ? item?.nationality
                                            : ""}
                                        </Tooltip>
                                      </td>
                                    )}
                                    <td className=" !w-[100px] text-center  px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                      {item?.total_experience
                                        ? item?.total_experience + "y"
                                        : ""}
                                    </td>

                                    <td className="!w-[200px] text-start cursor-paste px-2  py-3  text-sm text-gray-700 font-normal text-[15px]">
                                      <Tooltip
                                        title={
                                          item?.phone?.length > 14
                                            ? item?.phone
                                            : ""
                                        }
                                      >
                                        {item?.phone?.length > 14
                                          ? `${item?.phone.substring(0, 14)}...`
                                          : item?.phone
                                          ? item?.phone
                                          : ""}
                                      </Tooltip>
                                    </td>

                                    <td className="!w-[300px] text-left px-2 cursor-paste py-3  text-sm text-gray-700 font-normal text-[15px]">
                                      <Tooltip
                                        title={
                                          item?.email?.length > 18
                                            ? item?.email
                                            : ""
                                        }
                                      >
                                        {item?.email?.length > 18
                                          ? `${item?.email.substring(0, 18)}...`
                                          : item?.email
                                          ? item?.email
                                          : ""}
                                      </Tooltip>
                                    </td>

                                    <td className="!w-[300px] text-left items-center cursor-paste px-2 py-3 text-sm text-gray-700 font-normal text-[15px]">
                                      <Tooltip
                                        title={
                                          item?.linkedinurl?.length > 20
                                            ? item?.linkedinurl
                                            : ""
                                        }
                                      >
                                        <a
                                          href={
                                            item?.linkedinurl?.startsWith(
                                              "http"
                                            )
                                              ? item.linkedinurl
                                              : `https://${item?.linkedinurl}`
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-blue"
                                        >
                                          {item.linkedinurl?.length > 20
                                            ? `${item?.linkedinurl?.substring(
                                                0,
                                                20
                                              )}...`
                                            : item?.linkedinurl
                                            ? item?.linkedinurl
                                            : ""}
                                        </a>
                                      </Tooltip>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )
              ) : (
                <>
                  {noDataAvailable ? (
                    <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                      <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                        <p className="text-[30px] text-gray-500 font-bold">!</p>
                      </div>
                      <p className="text-[20px] text-gray-500 font-bold">
                        Data not available
                      </p>
                    </div>
                  ) : (
                    <div
                      className="mx-[15px] max-h-[calc(100vh-240px)] overflow-auto "
                      style={{ scrollbarWidth: "thin", overflowX: "hidden" }}
                    >
                      <div class="full-list">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {data &&
                            data?.map((candidate, i) => (
                              <div
                                class="details-listing"
                                key={candidate?.analysisid}
                                style={{
                                  width: "32%",
                                  marginBottom: "10px",
                                  marginRight: "10px",
                                }}
                              >
                                <div
                                  class="under-listing "
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) =>
                                    navigate("/candidateresult", {
                                      state: candidate?.analysisid,
                                    })
                                  }
                                >
                                  <p>{candidate?.candidate_name}</p>
                                </div>
                                <div
                                  class="under-listing"
                                  style={{ textAlign: "center" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: "#708EA4",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "paste",
                                      }}
                                      onClick={(e) =>
                                        navigate("/candidateresult", {
                                          state: candidate?.analysisid,
                                        })
                                      }
                                    >
                                      {candidate?.match_percentage}%
                                    </p>
                                    <img
                                      style={{
                                        width: "20px",
                                        cursor: "pointer",
                                      }}
                                      src="/images/arrow.svg"
                                      onClick={(e) =>
                                        navigate("/candidateresult", {
                                          state: candidate?.analysisid,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default TalentPool;
