const storeToken = (tokenValue) => {
  localStorage.setItem("token", tokenValue);
};

const retrieveToken = () => {
  const storedToken = localStorage.getItem("token");
  if (storedToken) {
    return storedToken;
  } else {
    return null;
  }
};

const clearToken = () => {
  localStorage.removeItem("token");
};

export const storage = () => ({
  storeToken,
  retrieveToken,
  clearToken,
});
