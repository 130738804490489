import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import FontRubikRegular from "../../fonts/Rubik/static/Rubik-Regular.ttf";
import FontRubukBold from "../../fonts/Rubik/static/Rubik-Bold.ttf";
import FontRubukExtraBold from "../../fonts/Rubik/static/Rubik-ExtraBold.ttf";
import FontRubikItalic from "../../fonts/Rubik/static/Rubik-Italic.ttf";
import parse from "html-react-parser";
import he from 'he';
import "react-quill/dist/quill.snow.css";
import React from "react";
import sanitizeHtml from 'sanitize-html';

Font.register({
  family: "Rubik",
  fonts: [
    {
      src: FontRubikRegular,
    },
    {
      src: FontRubukBold,
    },
    { src: FontRubikItalic, fontStyle: "italic" },
    { src: FontRubukExtraBold, fontWeight: "semibold" },
  ],
});

const MyDocument = ({ data ,customer}) => {
  const rows = [data];

  let elements;

  const styles = StyleSheet.create({
    page: {
      padding: "30px",
      width: "100%",
    },
    header: {
      marginBottom: 10,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid gray",
      fontFamily: "Rubik",
    },
    title: {
      fontSize: "16px",
      marginBottom: 10,
      fontWeight: "bold",
      color: "#5A93ED",
      fontFamily: "Rubik",
    },
    titleDate: {
      fontSize: "10px",
      color: "#1F2E39",
      fontFamily: "Rubik",
    },
    section: {
      // marginBottom: 10,
      // marginBottom: 30,
      marginVertical: 15,
    },
    section2: {
      marginVertical: 10,
    },
    table: {
      display: "flex",
      width:"auto",
      borderBottom: "3px solid #5A93ED",
    },
    tablewidth: { 
      width:"auto",
     
    },
    tablewidth2: { 
      width:"686px",
     
    },
    tableRow: {
      flexDirection: "row",
    },
    tableColHeader: {
      width: rows.length > 1 ? "35%" : "20%",
      backgroundColor: "#5A93ED",
      padding: 4,
    },
    tableColEducation: {
      width: 120,
      backgroundColor: "#5A93ED",
      padding: 4,
    },
    firstrowColHeader: {
      width: 40,
      backgroundColor: "#5A93ED",
      padding: 4,
    },
    Recent_title: {
      width: rows.length == 1 ? "13%" : "13%",
      backgroundColor: "#5A93ED",
      padding: 4,
    },
    matchSkillsrowColHeader: {
      width: "10%",
      backgroundColor: "#5A93ED",
      padding: 4,
    },
    recenttitle: {
      width: "24%",
      padding: 4,
    },
    firstrowCol: {
      width: 40,
      padding: 4,
    },
    candidatename: {
      width: rows.length == 1 ? "50%" : "15%",
      padding: 4,
    },
    DEandIrowCol: {
      width: rows.length == 1 ? "50%" : "15%",
      padding: 4,
    },
    recenttitleCol: {
      width: rows.length == 1 ? "76px" : "16%",
      padding: 4,
    },
    matchingSkillsrowCol: {
      width: rows.length == 1 ? "11.5%" : "12%",
      padding: 4,
    },
    matchtext: {
      textAlign: "left",
    },
    tableCellFirstHeader: {
      // marginTop: 5,
      fontSize: 11,
      fontWeight: "bold",
      color: "#FFFFFF",
      fontFamily: "Rubik",
    },
    tableCellFirst: {
      marginTop: 5,
      fontSize: 10,
      fontFamily: "Rubik",
      color: "#1F2E39",
      textAlign: "center",
    },
    // bold: {
    //   fontWeight: "bold",
    //   fontFamily: "Rubik",
    // },
    rowOdd: {
      backgroundColor: "#f9f9f9",
    },
    rowEven: {
      backgroundColor: "#CBDFFF",
    },
    colOdd: {
      backgroundColor: "#f9f9f9",
    },
    subtitleFontSize: {
      fontSize: "12px",
      marginVertical: "10px",
    },
    jdSection: {
      fontSize: "10px",
      marginTop: "10px",
      color: "#1F2E39",
      fontFamily: "Rubik",
      width: "100%",
      textAlign: "justify",
    },
    rankingTitle: {
      fontSize: "16px",
      marginVertical: "10px",
      fontWeight: "bold",
      fontFamily: "Rubik",
    },
    preview: {
      marginBottom: "0x",
    },
    pro: {
      display: "flex",
      flexDirection: "row",
      gap: 2,
    },
    profont: {
      fontSize: "10px",
      fontWeight: "bold",
      marginVertical: "11px",
      fontFamily: "Rubik",
      width: "5%",
    },
    projdSection: {
      fontSize: "10px",
      width: "95%",
      marginVertical: "11px",
      color: "#1F2E39",
      fontFamily: "Rubik",
      textAlign: "justify",
    },

    //PDF Format_Single Candidate//
    secondrowColHeader: {
      width: "22%",
      backgroundColor: "#5A93ED",
      padding: 5,
    },
    m_srowSeColHeader: {
      width: "12%",
      backgroundColor: "#5A93ED",
      padding: 5,
    },
    main: {
      width: "22%",
      // marginTop: "5px",
      padding: 4,
    },
    // tableCol: {
    //   width: "50%",
    //   padding: 4,
    // },
    candidatenames: {
      marginTop: "5px",
      marginBottom: "5px",
      flexDirection: "row",
    },
    profonts: {
      width: "25%",
      fontSize: "10px",
      fontWeight: "bold",
      fontFamily: "Rubik",
    },
    colEven: {
      backgroundColor: "#CBDFFF",
    },
    tableCells: {
      fontSize: 10,
      width: "75%",
      color: "#1F2E39",
      fontFamily: "Rubik",
      textAlign: "justify",
    },
    tableCellsname: {
      fontSize: 10,
      // width: "75%",
      fontWeight: "bold",
      fontFamily: "Rubik",
    },
    tableCol: {
      width: "12%",
      padding: 4,
    },
    tableCell: {
      padding: 3,
      fontSize: 10,
      color: "#1F2E39",
      fontFamily: "Rubik",
      textAlign: "justify",
    },
    h1: {
      fontSize: 24,
      fontWeight: "light",
    },
    h2: {
      fontSize: 20,
      fontWeight: "light",
      fontFamily: "Rubik",
    },
    h3: {
      fontSize: 18,
      fontFamily: "Rubik",
      fontWeight: "light",
    },
    p: {
      fontSize: 12,
      fontWeight: "normal",
      marginBottom: 10,
    },
    strong: {
      fontWeight: "bold",
      fontFamily: "Rubik",
    },
    italic: {
      fontStyle: "italic",
      fontFamily: "Rubik",
    },
    underline: {
      textDecoration: "underline",
    },
    link: {
      color: "#06c", // A typical color for links
      fontFamily: "Rubik", // Common link style
    },

    li: { marginBottom: 2, fontWeight: "normal", fontSize: 12 },
    ul: { marginVertical: 5, paddingLeft: 10 },
    ol: { marginVertical: 5, paddingLeft: 10 },
    code: { fontFamily: "Courier" },
  });

  const rankColors = [
    "#cce5ff",
    "#d4edda",
    "#f8d7da",
    "#fff3cd",
    "#d1ecf1",
    "#d6d8d9",
  ];


  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      const options = { day: "numeric", month: "long", year: "numeric" };
      return new Intl.DateTimeFormat("en-GB", options).format(date);
    } catch (error) {
      console.error("Invalid date string", dateString);
      return "Invalid Date";
    }
  };

  function decodeHtml(html) {
    return he.decode(html);
  }

  try {
    const cleanHtml = sanitizeHtml(data?.jobdescription, {
      allowedTags: ['h1', 'h2', 'h3', 'p', 'strong', 'b', 'em', 'i', 'u', 'a', 'br', 'ul', 'ol', 'li', 'code'],
      allowedAttributes: { a: ['href'] },
    });
    const decodedHtml = decodeHtml(cleanHtml);
    elements = parse(decodedHtml);
  
    if (!Array.isArray(elements)) {
      elements = [elements];
    }
  } catch (error) {
    console.error('Failed to parse HTML:', error);
    return 'Error parsing HTML';
  }

  const renderElement = (element) => {
    if (typeof element === 'string') {
      return <Text>{element}</Text>; 
    }
    const { type: tagName, props } = element;
    const children = React.Children.toArray(props.children).map(renderElement);
  
    let combinedStyles = [];
    switch (tagName) {
      case 'h1':
        combinedStyles = [styles.h1];
        break;
      case 'h2':
        combinedStyles = [styles.h2];
        break;
      case 'h3':
        combinedStyles = [styles.h3];
        break;
      case 'p':
        combinedStyles = [styles.p];
        break;
      case 'strong':
      case 'b': 
        combinedStyles = [styles.strong];
        break;
      case 'em':
      case 'i':
        combinedStyles = [styles.italic];
        break;
      case 'u':
        combinedStyles = [styles.underline];
        break;
      case 'a':
        combinedStyles = [styles.link];
        break;
      case 'br':
        return <Text>{'\n'}</Text>; // Handle line breaks
      case 'ul':
        return <View style={styles.ul}>{children}</View>;
      case 'ol':
        return <View style={styles.ol}>{children}</View>;
      case 'li':
        return (
          <View style={styles.li}>
            <Text>• {children} </Text>
           
          </View>
        );
      case 'code':
        combinedStyles = [styles.code];
        break;
      case 'abbr':
        return <Text>{children}</Text>;
      default:
        combinedStyles = [styles.normal];
        break;
    }
  
    return (
      <Text style={combinedStyles}>{children}</Text>
    );
  };


  return (
    <Document>
      <Page
        size="A4"
        style={styles.page}
        // wrap={false}
      >
        <View style={styles.header}>
          <Text style={styles.title}>
            {localStorage.getItem("customername")}
          </Text>

          <Text style={styles.titleDate}>
            Print Date: {formatDate(new Date())}
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.bold}>Job Description</Text>
          <Text style={[styles.subtitleFontSize, styles.bold]}>
            Job Title: {data?.jobtitle}
            {"  "} | {"  "}ATS Job Code: {data?.atsjobcode} {"  "}| {"  "}Job
            Opened: {formatDate(data?.jobcreateddate)}
          </Text>
         
          {elements?.map((element, index) => (
           <View key={index}>{renderElement(element)}</View>
          ))}

          {/* <View>
            {React.Children.toArray(
              parse(
                data?.jobdescription
                  ?.toString()
                  .replaceAll("</br>", "\n")
                  .replaceAll("&lt;", "<")
                  .replaceAll("&gt;", ">")
                  .replaceAll("&middot;", "·")
              )
            )?.map((child, index) => (
              <Text style={styles.jdSection} key={index}>
                {child}
              </Text>
            ))}
          </View> */}

          {/* <Text style={styles.jdSection}>
            {/* <Typography> */}
          {/* <div
              dangerouslySetInnerHTML={{
                __html: `${data?.jobdescription}`,
              }}
            ></div> */}
          {/* </Typography> 
            {parse(
              data?.jobdescription
                ?.toString()
                .replaceAll("</br>", "\n")
                .replaceAll("&lt;", "<")
                .replaceAll("&gt;", ">")
                .replaceAll("&middot;", "·")
            )}
          </Text> */}

          {/* <Text style={styles.jdSection}>
            {data?.jobdescription}
            {data?.jobdescription.split("</br>").map((line, index) => (
              <Text key={index} style={styles.jdSection}>
                {line.trim()}
                {"\n"}
              </Text>
            ))}
             </Text> */}
        </View>
        {/* <View style={styles.section}>
          <Text style={[styles.rankingTitle]}>Ranking Table</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              {rows.length > 1 && (
                <View style={styles.firstrowColHeader}>
                  <Text style={styles.tableCellFirstHeader}>Rank</Text>
                </View>
              )}
              <View style={styles.secondrowColHeader}>
                <Text style={styles.tableCellFirstHeader}>Candidate Name</Text>
              </View>
              <View style={styles.DEandIrowColHeader}>
                <Text style={styles.tableCellFirstHeader}>D, E, & I</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellFirstHeader}>Matching Skills</Text>
              </View>
              <View style={styles.Recent_title}>
                <Text style={styles.tableCellFirstHeader}>Recent Title</Text>
              </View>
              <View style={styles.tableColEducation}>
                <Text style={styles.tableCellFirstHeader}>Education</Text>
              </View>
              <View style={styles.matchSkillsrowColHeader}>
                <Text style={styles.tableCellFirstHeader}>Match %</Text>
              </View>
            </View>
            {rows.map((row, rowIndex) => (
              <View
                key={row?.candidateid}
                style={[
                  styles.tableRow,
                  rowIndex % 2 === 0 ? styles.rowEven : styles.rowOdd,
                  {
                    backgroundColor:
                      rankColors[row?.candidateid - 1] || "#ffffff",
                  },
                ]}
              >
                {rows.length > 1 && (
                  <View style={[styles.firstrowCol, styles.colEven]}>
                    <Text style={styles.tableCellFirst}>{rowIndex}</Text>
                  </View>
                )}
                <View style={[styles.candidatename]}>
                  <Text style={styles.tableCell}>{row?.candidatename}</Text>
                </View>
                <View style={[styles.DEandIrowCol}>
                  <Text style={styles.tableCell}>
                    
                    {row?.ethnicity.charAt(0).toUpperCase() +
                      row?.ethnicity.slice(1).toLowerCase()}
                  </Text>
                </View>
                <View style={[styles.tableCol]}>
                  <Text style={styles.tableCell}>{row?.matching_skills}</Text>
                </View>
                <View style={[styles.recenttitleCol, styles.colEven]}>
                  <Text style={styles.tableCell}>{row?.jobtitle}</Text>
                </View>
                <View style={[styles.recenttitle]}>
                  <Text style={styles.tableCell}>{row?.education}</Text>
                </View>
                <View style={[styles.matchingSkillsrowCol, styles.colEven]}>
                  <Text style={[styles.tableCell, styles.matchtext]}>
                    {row?.match_percentage + "%"}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View> */}

        {/* PDF Format_Single Candidate */}
        <View style={customer?.show_deni === false && customer?.ismiddleeast === false ? styles.section2 : styles.section} break>
          <Text style={[styles.rankingTitle]}>Ranking Table</Text>
          <View style={ styles.table}>
            <View style={customer?.show_deni === false && customer?.ismiddleeast === false ? styles.tablewidth2 : styles.tablewidth}>
            <View style={styles.tableRow}>
              <View style={styles.secondrowColHeader}>
                <Text style={styles.tableCellFirstHeader}>
                  Candidate Details
                </Text>ismiddleeast
              </View>
              {customer?.ismiddleeast && (
              <View style={styles.secondrowColHeader}>
                                 <Text style={styles.tableCellFirstHeader}>
                                 Nationality
                                 </Text>
              </View>
              )}

              {customer?.show_deni && (
              <View style={styles.secondrowColHeader}>
                                 <Text style={styles.tableCellFirstHeader}>
                                      D, E, & I
                                 </Text>
              </View>
              )}
           
              <View style={styles.secondrowColHeader}>
                <Text style={styles.tableCellFirstHeader}>Recent Title</Text>
              </View>
              <View style={styles.secondrowColHeader}>
                <Text style={styles.tableCellFirstHeader}>Education</Text>
              </View>
              <View style={styles.m_srowSeColHeader}>
                <Text style={styles.tableCellFirstHeader}>Match %</Text>
              </View>
            </View>
            {rows.map((row, rowIndex) => (
              <View
                key={row?.candidateid}
                style={[
                  styles.tableRow,
                  rowIndex % 2 === 0 ? styles.rowEven : styles.rowOdd,
                  {
                    backgroundColor:
                      rankColors[row?.candidateid - 1] || "#ffffff",
                  },
                ]}
              >
                {/* <View style={[styles.main]}>
                  <View style={[styles.candidatenames]}>
                    <Text style={[styles.profonts]}>Name: </Text>
                    <Text style={styles.tableCellsname}>
                      {row?.candidatename}
                    </Text>
                  </View>

                  <View style={[styles.candidatenames]}>
                    <Text style={[styles.profonts]}>Match %: </Text>
                    <Text style={styles.tableCells}>
                      {row?.match_percentage + "%"}
                    </Text>
                  </View>

                  <View style={[styles.candidatenames]}>
                    <Text style={[styles.profonts]}>D, E, & I: </Text>
                    <Text style={styles.tableCells}>
                      {row?.ethnicity.charAt(0).toUpperCase() +
                        row?.ethnicity.slice(1).toLowerCase()}
                    </Text>
                  </View>

                  <View style={[styles.candidatenames]}>
                    <Text style={[styles.profonts]}>Recent Title: </Text>
                    <Text style={styles.tableCells}>
                      {row?.current_job_title}
                    </Text>
                  </View>

                  <View style={[styles.candidatenames]}>
                    <Text style={[styles.profonts]}>Education: </Text>
                    <Text style={styles.tableCells}>{row?.education}</Text>
                  </View>
                </View> */}

                <View style={[styles.main]}>
                  <Text style={styles.tableCellsname}>
                    {row?.candidatename}
                  </Text>
                </View>
                {customer?.ismiddleeast &&  (
                <View style={[styles.main, customer?.show_deni && customer?.ismiddleeast ? styles.colEven  : styles.colEven ]}>
                <Text style={styles.tableCell}>
                      {row?.nationality.charAt(0).toUpperCase() + row?.nationality.slice(1).toLowerCase()}
                       
                </Text>
                </View>
                )}
                 {customer?.show_deni && (
                <View style={[styles.main, customer?.show_deni && customer?.ismiddleeast ? ""  : styles.colEven]}>
                <Text style={styles.tableCell}>
                {row?.ethnicity.charAt(0).toUpperCase() + row?.ethnicity.slice(1).toLowerCase()}
                      
                </Text>
                </View>
                )}
                <View style={[styles.main , customer?.show_deni === false && customer?.ismiddleeast === false ? styles.colEven : customer?.show_deni === false && customer?.ismiddleeast === false ? styles.colEven : customer?.show_deni && customer?.ismiddleeast ? styles.colEven  : ""]}>
                  <Text style={styles.tableCell}>{row?.current_job_title}</Text>
                </View>
                <View style={[styles.main,customer?.show_deni === false && customer?.ismiddleeast === false ? "" : customer?.show_deni && customer?.ismiddleeast ? ""  : styles.colEven]}>
                  <Text style={styles.tableCell}>{row?.education}</Text>
                </View>
                <View style={[styles.tableCol, customer?.show_deni === false && customer?.ismiddleeast === false ? styles.colEven : customer?.show_deni && customer?.ismiddleeast ? styles.colEven  : ""]}>
                  <Text style={styles.tableCell}>
                    {row?.match_percentage + "%"}
                  </Text>
                </View>
              </View>
            ))}
            </View>
          </View>
        </View>

        <View style={customer?.show_deni === false && customer?.ismiddleeast === false ? styles.section2 : styles.section}>
          {rows.map((row, i) => {
            return (
              <>
                <View key={i}>
                  {/* <Text style={[styles.bold, styles.rankingTitle]}>
                    {row?.candidatename}
                  </Text> */}
                  <Text style={[styles.bold, styles.rankingTitle]}>
                    Analysis Summary
                  </Text>
                  <Text style={[styles.preview, styles.jdSection]}>
                    {row?.analysis_summary}
                  </Text>
                </View>
                <View key={i} style={styles.pro}>
                  <Text style={styles.profont}>Pros:</Text>
                  <Text style={[styles.projdSection]}>{row?.pros}</Text>
                </View>
                <View key={i} style={styles.pro}>
                  <Text style={styles.profont}>Cons: </Text>
                  <Text style={[styles.projdSection]}>{row?.cons}</Text>
                </View>
              </>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
