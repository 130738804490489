import React, { useEffect } from "react";
import { storage } from "../../libs/localStorage";

function Logout() {
  const { clearToken } = storage();

  useEffect(() => {
    clearToken();
    window.open(process.env.REACT_APP_API_LOGIN_URL + "/logout", "_self");
  }, []);
  return <div></div>;
}

export default Logout;
