import React from "react";
import "./Pagination.css";
import { useMediaQuery, useTheme } from "@mui/material";

// const PageNumbers = ({ table }) => {
// 	const { pageCount, currentPage, canNext, canPrev, onNext, onPageChange, onPrev } = table;

// 	const pages = Array.from({ length: pageCount }, (_, i) => i);

// 	return (
// 		<div className="pagination">
// 			<div
// 				style={{ color: canPrev ? '#22D6AA' : 'initial', fontSize: '18px', fontWeight: '600', cursor: canPrev ? 'pointer' : 'default' }}
// 				onClick={canPrev ? onPrev : null}
// 			>
// 				{'<'}
// 			</div>
// 			{pages.map((page) => (
// 				<React.Fragment key={page}>
// 					{pageCount === 6
// 						? page < 4 && (
// 							<div
// 								key={page}
// 								onClick={() => onPageChange(page + 1)}
// 								className={currentPage === page + 1 ? "active" : ""}
// 							>
// 								{page + 1}
// 							</div>
// 						)
// 						: page < 3 && (
// 							<div
// 								key={page}
// 								onClick={() => onPageChange(page + 1)}
// 								className={currentPage === page + 1 ? "active" : ""}
// 							>
// 								{page + 1}
// 							</div>
// 						)}
// 				</React.Fragment>
// 			))}
// 			{pageCount > 6 && (
// 				<select
// 					className={`border-0 bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded-md ${currentPage > 3 && currentPage < pageCount - 1 && "active"}`}
// 					onChange={(e) => onPageChange(Number(e.target.value))}
// 					value={currentPage}
// 				>
// 					{pages.map((page) => (
// 						(page > 2 && page < pageCount - 2) && <option key={page} value={page + 1}>{page + 1}</option>
// 					))}
// 				</select>
// 			)}
// 			{pageCount === 4
// 				? pageCount > 3 &&
// 				pages.map((page) => (
// 					<React.Fragment key={page}>
// 						{page > pageCount - 2 && (
// 							<div
// 								key={page}
// 								onClick={() => onPageChange(page + 1)}
// 								className={currentPage === page + 1 ? "active" : ""}
// 							>
// 								{page + 1}
// 							</div>
// 						)}
// 					</React.Fragment>
// 				))
// 				: pageCount > 3 &&
// 				pages.map((page) => (
// 					<React.Fragment key={page}>
// 						{page > pageCount - 3 && (
// 							<div
// 								key={page}
// 								onClick={() => onPageChange(page + 1)}
// 								className={currentPage === page + 1 ? "active" : ""}
// 							>
// 								{page + 1}
// 							</div>
// 						)}
// 					</React.Fragment>
// 				))}
// 			<div
// 				style={{ color: canNext ? '#22D6AA' : 'initial', fontSize: '18px', fontWeight: '600', cursor: canNext ? 'pointer' : 'default' }}
// 				onClick={canNext ? onNext : null}
// 			>
// 				{'>'}
// 			</div>
// 		</div>
// 	);
// };

// export default PageNumbers;

// Arbaz
// const PageNumbers = ({ table }) => {
//   const {
//     pageCount,
//     currentPage,
//     canNext,
//     canPrev,
//     onNext,
//     onPageChange,
//     onPrev,
//   } = table;

//   const theme = useTheme();

//   const isMobile = useMediaQuery(theme.breakpoints.down("820"));

//   const generatePages = () => {
//     let startPage = Math.max(1, currentPage - 2);
//     let endPage = Math.min(startPage + 4, pageCount);

//     if (endPage - startPage < 4) {
//       startPage = Math.max(1, endPage - 4);
//     }

//     return Array.from(
//       { length: endPage - startPage + 1 },
//       (_, i) => startPage + i
//     );
//   };

//   const pages = generatePages();

//   return (
//     <>
//       {pageCount > 0 && (
//         <div className='pagination'>
//           <div
//             style={{
//               color: canPrev ? "#22D6AA" : "initial",
//               fontSize: "18px",
//               fontWeight: "600",
//               cursor: canPrev ? "pointer" : "default",
//             }}
//             onClick={canPrev ? onPrev : null}
//           >
//             {"<"}
//           </div>
//           {!isMobile && currentPage > 3 && pageCount > 5 && (
//             <>
//               <div
//                 onClick={() => onPageChange(1)}
//                 className={currentPage === 1 ? "active" : ""}
//               >
//                 {1}
//               </div>
//               {pages[0] > 2 && <div>...</div>}
//             </>
//           )}
//           {pages.map((page) => (
//             <div
//               key={page}
//               onClick={() => onPageChange(page)}
//               className={currentPage === page ? "active" : ""}
//             >
//               {page}
//             </div>
//           ))}
//           {!isMobile && currentPage < pageCount - 2 && pageCount > 5 && (
//             <>
//               {pages[pages.length - 1] < pageCount - 1 && <div>...</div>}
//               <div
//                 onClick={() => onPageChange(pageCount)}
//                 className={currentPage === pageCount ? "active" : ""}
//               >
//                 {pageCount}
//               </div>
//             </>
//           )}
//           <div
//             style={{
//               color: canNext ? "#22D6AA" : "initial",
//               fontSize: "18px",
//               fontWeight: "600",
//               cursor: canNext ? "pointer" : "default",
//             }}
//             onClick={canNext ? onNext : null}
//           >
//             {">"}
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default PageNumbers;

const PageNumbers = ({ table }) => {
  const {
    pageCount,
    currentPage,
    canNext,
    canPrev,
    onNext,
    onPageChange,
    onPrev,
  } = table;

  const generatePages = () => {
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(startPage + 4, pageCount);

    if (startPage > 1) {
      startPage = Math.min(startPage, pageCount - 4);
      endPage = Math.min(startPage + 4, pageCount);
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  const pages = generatePages();

  return (
    <>
      {pageCount > 0 && (
        <div className="pagination">
          <div
            style={{
              color: canPrev ? "#22D6AA" : "initial",
              fontSize: "18px",
              fontWeight: "600",
              cursor: canPrev ? "pointer" : "default",
            }}
            onClick={canPrev ? onPrev : null}
          >
            {"<"}
          </div>
          {currentPage > 3 && pageCount > 5 && (
            <>
              <div
                onClick={() => onPageChange(1)}
                className={currentPage === 1 ? "active" : ""}
              >
                {1}
              </div>
              {currentPage > 4 && <div>...</div>}
            </>
          )}
          {Array.from({ length: 5 }, (_, index) => {
            const page =
              currentPage <= 3
                ? index + 1
                : currentPage >= pageCount - 2
                ? pageCount - 4 + index
                : currentPage - 2 + index;

            if (page > 0 && page <= pageCount) {
              return (
                <div
                  key={page}
                  onClick={() => onPageChange(page)}
                  className={currentPage === page ? "active" : ""}
                >
                  {page}
                </div>
              );
            }
            return null;
          })}
          {currentPage < pageCount - 2 && pageCount > 5 && (
            <>
              {currentPage < pageCount - 3 && <div>...</div>}
              <div
                onClick={() => onPageChange(pageCount)}
                className={currentPage === pageCount ? "active" : ""}
              >
                {pageCount}
              </div>
            </>
          )}
          <div
            style={{
              color: canNext ? "#22D6AA" : "initial",
              fontSize: "18px",
              fontWeight: "600",
              cursor: canNext ? "pointer" : "default",
            }}
            onClick={canNext ? onNext : null}
          >
            {">"}
          </div>
        </div>
      )}
    </>
  );
};

export default PageNumbers;

// const PageNumbers = ({ table }) => {
//   const {
//     pageCount,
//     currentPage,
//     canNext,
//     canPrev,
//     onNext,
//     onPageChange,
//     onPrev,
//   } = table;

//   const theme = useTheme();

//   const isMobile = useMediaQuery(theme.breakpoints.down("820"));

//   // const generatePages = () => {
//   //   let startPage = Math.max(1, currentPage - 2);
//   //   let endPage = Math.min(startPage + (isMobile ? 2 : 4), pageCount);

//   //   if (endPage - startPage < (isMobile ? 2 : 4)) {
//   //     startPage = Math.max(1, endPage - (isMobile ? 2 : 4));
//   //   }

//   //   return Array.from(
//   //     { length: endPage - startPage + 1 },
//   //     (_, i) => startPage + i
//   //   );
//   // };

//   const generatePages = () => {
//     const maxPagesToShow = isMobile ? 3 : 5;
//     const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

//     let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
//     let endPage;
//     if (currentPage >= pageCount - 2) {
//       endPage = Math.min(pageCount, startPage + maxPagesToShow);
//     } else {
//       endPage = Math.min(pageCount, startPage + maxPagesToShow - 1);
//     }
//     // Ensure we always show the correct number of pages around the current page
//     if (endPage - startPage + 1 < maxPagesToShow) {
//       startPage = Math.max(1, endPage - maxPagesToShow + 1);
//     }

//     // Adjust endPage if it's close to pageCount
//     if (currentPage + halfMaxPagesToShow > pageCount) {
//       endPage = pageCount;
//       startPage = Math.max(1, endPage - maxPagesToShow + 1);
//     }

//     // Ensure startPage and endPage cover the maxPagesToShow range when possible
//     if (currentPage <= halfMaxPagesToShow) {
//       endPage = Math.min(pageCount, maxPagesToShow);
//     }

//     // Ensure the last page number is always shown when clicking the third-last page
//     if (currentPage > pageCount - (maxPagesToShow - 1)) {
//       startPage = Math.max(1, pageCount - maxPagesToShow + 1);
//       endPage = pageCount;
//     }

//     return Array.from(
//       { length: endPage - startPage + 1 },
//       (_, i) => startPage + i
//     );
//   };

//   const pages = generatePages();

//   return (
//     <>
//       {pageCount > 0 && (
//         <div className="pagination">
//           <div
//             style={{
//               color: canPrev ? "#22D6AA" : "initial",
//               fontSize: "18px",
//               fontWeight: "600",
//               cursor: canPrev ? "pointer" : "default",
//             }}
//             onClick={canPrev ? onPrev : null}
//           >
//             {"<"}
//           </div>
//           {currentPage > 3 && pageCount > 5 && (
//             <>
//               <div
//                 onClick={() => onPageChange(1)}
//                 className={currentPage === 1 ? "active" : ""}
//               >
//                 {1}
//               </div>
//               {pages[0] > 2 && <div>...</div>}
//             </>
//           )}
//           {pages.map((page) => (
//             <div
//               key={page}
//               onClick={() => onPageChange(page)}
//               className={currentPage === page ? "active" : ""}
//             >
//               {page}
//             </div>
//           ))}
//           {currentPage < pageCount - 2 && pageCount > 5 && (
//             <>
//               {pages[pages.length - 1] < pageCount - 1 && <div>...</div>}
//               <div
//                 onClick={() => onPageChange(pageCount)}
//                 className={currentPage === pageCount ? "active" : ""}
//               >
//                 {pageCount}
//               </div>
//             </>
//           )}
//           <div
//             style={{
//               color: canNext ? "#22D6AA" : "initial",
//               fontSize: "18px",
//               fontWeight: "600",
//               cursor: canNext ? "pointer" : "default",
//             }}
//             onClick={canNext ? onNext : null}
//           >
//             {">"}
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default PageNumbers;
