import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import { useFeedbacksData } from "../../data/feedbacks";
import { storage } from "../../libs/localStorage";
import { Grid, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import Pagination from "../../components/Pagination/Pagination";
import { useClientSettingData } from "../../data/clientSettings";
import { useJobsData } from "../../data/jobsdata";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import DeletePopup from "./DeletePopup";
import HeaderBar from "../../components/HeaderBar/HeaderBar";

const Users = () => {
  const { GetUsers, DeleteUser } = useClientSettingData();
  const { storeToken, retrieveToken, clearToken } = storage();
  const token = retrieveToken();
  const [data, setData] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const activetab = "users";
  const { GetAtsUrlByAtsJobcode } = useJobsData();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state;
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();

  const userType = localStorage.getItem("usertype");
  const customerid = Number(localStorage.getItem("customerid"));
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  useEffect(() => {
    if (token) {
      GetUsers({
        customerid: params?.customerid ? params?.customerid : customerid,
      })
        .then((response) => {
          if (response?.data == (undefined || null)) {
            setData([]);
            setNoDataAvailable(true);
          } else {
            setData(response?.data);
            setNoDataAvailable(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
    }
  }, []);

  // const handleDeleteUser = async (userid) => {
  //   try {
  //     const response = await DeleteUser({
  //       userid: userid,
  //     });

  //     if (response?.header?.status === 200) {
  //       const updatedUsers = data?.filter((item) => item?.userid !== userid);
  //       setData(updatedUsers);
  //       setOpen(true);
  //     }
  //   } catch (error) {
  //     console.error("API call error:", error);

  //   }
  // };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    lineHeight: "24px",
    color: theme.palette.text.secondary,
  }));
  return (
    <div>
      <Helmet>
        <style>{"body { background-color: #F3F5F9;}"}</style>
        <title>User List</title>
      </Helmet>
      {<DeletePopup open={open} setOpen={setOpen} id={id} setId={setId} />}

      {isMobile ? (
        <>
          <Navbar data={activetab} />
          <Helmet>
            <style>{"body { background-color: #F3F5F9;}"}</style>
          </Helmet>

          <React.Fragment>
            {noDataAvailable ? (
              <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                  <p className="text-[30px] text-gray-500 font-bold">!</p>
                </div>
                <p className="text-[20px] text-gray-500 font-bold">
                  Data not available
                </p>
              </div>
            ) : (
              <div style={{ marginBottom: "25px" }}>
                <Grid
                  container
                  spacing={2}
                  columns={isMobile ? 1 : { xs: 4, sm: 8, md: 12 }}
                >
                  {data &&
                    data?.map((item, index) => (
                      <Grid item xs={2} sm={4} md={4}>
                        <Item
                          style={{
                            marginTop: "15px",
                            marginRight: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "7px",
                            }}
                            className=" font-rubik font-bold text-left "
                          >
                            <div>{item?.firstname}</div>
                            <div>{item?.lastname}</div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                            className=" font-rubik  font-normal text-left "
                          >
                            <div>{item?.emailid}</div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="uppercase cursor-pointer text-[#21D6AA]"
                              onClick={() => {
                                navigate("/edituser", {
                                  state: {
                                    customerid: params?.customerid
                                      ? params?.customerid
                                      : customerid,
                                    customername: params?.customername,
                                    userid: item?.userid,
                                  },
                                });
                              }}
                            >
                              Edit
                            </div>
                            <div
                              className="uppercase cursor-pointer text-[#E77388]"
                              onClick={() => {
                                setOpen(true);
                                setId(item?.userid);
                              }}
                            >
                              DELETE
                            </div>
                          </div>
                        </Item>
                      </Grid>
                    ))}
                </Grid>
              </div>
            )}
            <div
              style={{
                textAlign: "center",
                position: "sticky",
                bottom: "0px",
                paddingBottom: "20px",
              }}
            >
              <p
                class="Add-Client"
                onClick={() => {
                  navigate("/adduser", {
                    state: {
                      customerid: params?.customerid
                        ? params?.customerid
                        : customerid,
                      customername: params?.customername,
                    },
                  });
                }}
                style={{
                  color: "white",
                  fontSize: "15px",
                  fontWeight: "500",
                  display: "inline-flex",
                  padding: "12px 28px",
                }}
              >
                ADD USER
              </p>
            </div>
          </React.Fragment>
        </>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Navbar data={activetab} />
          <Box
            className="px-6 pt-4"
            component="main"
            sx={{ flexGrow: 1, height: "100vh" }}
          >
            <div>
              <div className="mb-7">
                <HeaderBar
                  title={`User List${
                    params?.customername ? ` For ${params?.customername}` : ""
                  }`}
                  toggleDrawer={toggleDrawer}
                />

                {/* <p className="pro">User List For {params?.customername}</p> */}
              </div>
              <div className="flex items-center justify-end px-3">
                <div className="mb-6">
                  <div className="filter-text flex items-center cursor-pointer">
                    <p
                      className="Add-Client text-white text-sm font-medium ml-2"
                      onClick={() => {
                        navigate("/adduser", {
                          state: {
                            customerid: params?.customerid
                              ? params?.customerid
                              : customerid,
                            customername: params?.customername,
                          },
                        });
                      }}
                    >
                      ADD USER
                    </p>
                  </div>
                </div>
              </div>

              {noDataAvailable ? (
                <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                  <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                    <p className="text-[30px] text-gray-500 font-bold">!</p>
                  </div>
                  <p className="text-[20px] text-gray-500 font-bold">
                    Data not available
                  </p>
                </div>
              ) : (
                <div className="mx-[10px] shadow-md ">
                  <div className="sticky z-10 top-0 bg-white border-b border-[#E0E0E0]">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="w-[230px] px-5 py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                            FIRST NAME
                          </th>
                          <th className="w-[230px] px-5 py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                            LAST NAME
                          </th>
                          <th className="w-[260px] px-5 py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                            EMAIL
                          </th>
                          <th className="w-[160px] px-5 py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                            {" "}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              )}
              <div
                className="mx-[10px] max-h-[calc(100vh-327px)] overflow-auto shadow-md "
                style={{ scrollbarWidth: "none" }}
              >
                <div className="">
                  <table className="min-w-full divide-y divide-gray-200">
                    <tbody className="bg-white divide-y divide-gray-200 font-rubik">
                      {data?.map((item, index) => (
                        <tr
                          key={index}
                          className={`${
                            index % 2 === 0
                              ? "bg-[#EDF4FF] border-none"
                              : "bg-white border-none"
                          }`}
                        >
                          <td className="w-[230px] px-5 py-4  text-sm text-gray-700 ">
                            {item?.firstname}
                          </td>
                          <td className="px-5 py-4  text-sm text-gray-700 w-[230px]">
                            {item?.lastname}
                          </td>
                          <td className="px-5 py-4  text-sm text-gray-700 w-[260px]">
                            {item?.emailid}
                          </td>
                          <td className="px-5 py-4    text-sm text-gray-700 w-[160px]">
                            <div className="flex  gap-4">
                              <img
                              className="w-[18px] h-[18px]"
                                src="/Group 247.svg"
                                alt="edit_icons"
                                onClick={() => {
                                  navigate("/edituser", {
                                    state: {
                                      customerid: params?.customerid
                                        ? params?.customerid
                                        : customerid,
                                      customername: params?.customername,
                                      userid: item?.userid,
                                    },
                                  });
                                }}
                              />
                              {/* <div
                                className="text-[15px] font-medium text-[#21D6AA] cursor-pointer uppercase"
                                onClick={() => {
                                  navigate("/edituser", {
                                    state: {
                                      customerid: params?.customerid
                                        ? params?.customerid
                                        : customerid,
                                      customername: params?.customername,
                                      userid: item?.userid,
                                    },
                                  });
                                }}
                              >
                                Edit
                              </div> */}
                              {item?.usertype === "U" && (
                                <div className="w-[18px] font-medium color-[#21D6AA] cursor-pointer uppercase">
                                  <img
                                    src="/Group 243.svg"
                                    alt="delete_icons"
                                    onClick={() => {
                                      setOpen(true);
                                      setId(item?.userid);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Users;
