import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();
export const GetPrompt = async (body) => {
  const { pageno, pagesize } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getstandardizationpromptslist",
      pageno: pageno,
      pagesize: pagesize,
    },
  });
  return data;
};

// Active - Inactive prompt
export const ActiveInactivePrompt = async (body) => {
  const { promptid, isactive } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatestandardizationprompt",
      promptid: promptid,
      isactive: isactive,
    },
  });
  return data;
};

// Update Standardrization API
export const UpdateStandardrization = async (body) => {
  const { prompt, versionremark } = body;
  const { data } = await axios.request({
    url: "/v1/insertstandardizationprompt",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      prompt: prompt,
      versionremark: versionremark,
    },
  });
  return data;
};

export const StandardizationJasonFileApi = async (body) => {
  const { analysisid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getstandardizedcvjsonlist",
      analysisid: analysisid,
    },
  });
  return data;
};


export const JobDescribtionFileApi = async (body) => {
  const { jobid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getjobdescbyjobid",
      jobid: jobid,
    },
  });
  return data;
};

export const GetStdCvTemplateList = async (body) => {
  const { customerid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getstdcvtemplates",
      customerid: customerid,
    },
  });
  return data;
};

