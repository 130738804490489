import { Box } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar/Navbar";
import "./AddNewClient.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import { AddNewClientInsert } from "../../data/addNewClient";
import { AtsList, IndustryList } from "../../data/addNewClient";
import { GetIndustryPrompts } from "../../data/editClient";
import AddNewClientModel from "./AddNewClientSavedPopup";
import { toast, ToastContainer } from "react-toastify";
import HeaderBar from "../../components/HeaderBar/HeaderBar";

const AddNewClient = () => {
  const navigate = useNavigate();
  const activetab = "addnewclient";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const [open, setOpen] = useState(false);
  const [clientName, setClientName] = useState("");
  const [industry, setIndustry] = useState(null);
  const [selectedId, setSelectedId] = useState("");
  const [clientPlatform, setClientPlatform] = useState(
    "https://dev.talentin.ai"
  );
  const [ats, setAts] = useState("0");
  const [industryid, setIndustryId] = useState("");
  const [dailyResumeLimit, setDailyResumeLimit] = useState("");
  const [totalResumeLimit, setTotalResumeLimit] = useState("");
  const [basePrompt, setBasePrompt] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [additionalbasepromptindusty, setAdditionIndustryPrompt] = useState("");
  const [understadingJdPrompt, setUnderstandingJdPrompt] = useState("");
  const [understadingCvPrompt, setUnderstandingCvPrompt] = useState("");
  const [calculationPrompt, setCalculationPrompt] = useState("");
  const [resultFormatPrompt, setResultFormatPrompt] = useState("");
  const [versionRemarkPrompt, setVersionRemarkPrompt] = useState("");
  const [deAndI, setDeAndI] = useState(false);
  const [detailedview, setDetailedview] = useState(false); 
  const [nationality, setNationality] = useState(false);
  const [autoAtsSync, setAutoAtsSync] = useState(false);
  const [sourceCandidate, setSourceCandidate] = useState(false);   
  const [standardization, setStandardization] = useState(true);
  const [active, setActive] = useState(true);
  const [overexp, setOverexp] = useState(false);
  const [overquali, setOverquali] = useState(false);
  const [gender, setGender] = useState(false);
  const [age, setAge] = useState(false);
  const [drivinglic, setDrivinglic] = useState(false);
  const [screening, setScreening] = useState(false);
  const [criticalskill, setCriticalskill] = useState(false);
  const [arabicspeak, setArabicspeak] = useState(false);
  const [currJobLocation, setCurrJobLocation] = useState(false);
  const [higestQulic, setHigestQulic] = useState(false);
  const [expsmiliar, setExpsmiliar] = useState(false);
  const [bulkupload, setBulkupload] = useState(false);
  const [is4cvupload, set4cvupload] = useState(false);
  const [customers, setCustomers] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [clientNameError, setClientNameError] = useState("");
  const [selectedOptionError, setSelectedOptionError] = useState("");
  const [selectedSecondOptionError, setSelectedSecondOptionError] =
    useState("");
  const [customerPanelUrlError, setCustomerPanelUrlError] = useState("");
  const [dailyResumeLimitError, setDailyResumeLimitError] = useState("");
  const [totalResumeLimitError, setTotalResumeLimitError] = useState("");
  // const talentinTag = "talentin.ai";
  const [isArrowUp, setIsArrowUp] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSecondOption, setSelectedSecondOption] = useState(null);
  const [isSecondOpen, setSecondIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [emailCheck, setEmailCheck] = useState("");


  const [isMaximized, setIsMaximized] = useState(false);
  const handleToggle = () => {
    setIsMaximized(!isMaximized);
  };

  const handleInputChange = (setter, setError) => (event) => {
    const trimmedValue = event.target.value.trim();
    setter(trimmedValue);
    setError("");
  };

  const [isMaximizedForadditionIndustry, setIsMaximizedForadditionIndustry] =
    useState(false);
  const handleToggleForadditionIndustry = () => {
    setIsMaximizedForadditionIndustry(!isMaximizedForadditionIndustry);
  };
  

  const [
    isMaximizedForunderstadingJdPrompt,
    setIsMaximizedForunderstadingJdPrompt,
  ] = useState(false);
  const handleToggleForunderstadingJdPrompt = () => {
    setIsMaximizedForunderstadingJdPrompt(!isMaximizedForunderstadingJdPrompt);
  };

  const [isMaximizedForCvPrompt, setIsMaximizedForCvPrompt] = useState(false);
  const handleToggleForCvPrompt = () => {
    setIsMaximizedForCvPrompt(!isMaximizedForCvPrompt);
  };

  const [isMaximizedForCalculationPrompt, setIsMaximizedForCalculationPrompt] =
    useState(false);
  const handleToggleForCalculationPrompt = () => {
    setIsMaximizedForCalculationPrompt(!isMaximizedForCalculationPrompt);
  };

  const [
    isMaximizedForresultFormatPrompt,
    setIsMaximizedForresultFormatPrompt,
  ] = useState(false);
  const handleToggleForresultFormatPromp = () => {
    setIsMaximizedForresultFormatPrompt(!isMaximizedForresultFormatPrompt);
  };

  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  useEffect(() => {
    AtsList()
      .then((response) => {
        setCustomers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    IndustryList()
      .then((response) => {
        setIndustry(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const OnSubmit = () => {
    const data = {
      customername: clientName,
      // industry: selectedOption,
      customerpanelurl: clientPlatform,
      baseprompt: basePrompt,
      additionalbasepromptindusty: additionalbasepromptindusty,
      understandingjdprompt: understadingJdPrompt,
      understandingcvprompt: understadingCvPrompt,
      calculationprompt: calculationPrompt,
      resultformatprompt: resultFormatPrompt,
      versionremark: versionRemarkPrompt,
      show_deni: deAndI,
      sourcingemail: sourceCandidate === true ? email : "" ,
      ismiddleeast:nationality,
      isdetailview: detailedview,
      issourcing:sourceCandidate,
      isscreening: screening ,
      isoverqualified : overquali,
      isoverexperienced : overexp,
      isarabicspeaking: arabicspeak,
      isgender : gender,
      isdrivinglic : drivinglic,
      iscustomcriticalskill : criticalskill,
      isexperiencesimiliar : expsmiliar,
      iscurrentjoblocation : currJobLocation,
      ishighestqualification : higestQulic,
      isage: age ,
      isbulkupload : bulkupload ,
      is4cvupload: is4cvupload ,
      atssync: autoAtsSync,
      isstandardization: standardization,
      isactive: active,
      industryid: Number(industryid),
      atsid: Number(ats),
      dailylimit: Number(dailyResumeLimit),
      totallimit: Number(totalResumeLimit),
    };

    // Clear previous error messages
    setClientNameError("");
    setSelectedOptionError("");
    setSelectedSecondOptionError("");
    setCustomerPanelUrlError("");
    setDailyResumeLimitError("");
    setTotalResumeLimitError("");
    setEmailCheck("")

    // Check for missing or empty mandatory parameters and set errors in state
    let hasErrors = false;
    if (!data.customername) {
      setClientNameError("Customer name is required");
      hasErrors = true;
    }
    if (!industryid) {
      setSelectedOptionError("Industry is required");
      hasErrors = true;
    }
    if (!ats) {
      setSelectedSecondOptionError("ATS is required");
      hasErrors = true;
    }
    if (!clientPlatform) {
      setCustomerPanelUrlError("Customer panel URL is required");
      hasErrors = true;
    } else {
      // Define a URL pattern for validation that does not contain "talentin.ai"
      const urlPattern = /^(https?:\/\/(?:[\w-]+\.)*([\w-]+\.[a-z]+))$/i;

      if (!urlPattern.test(clientPlatform)) {
        setCustomerPanelUrlError(
          "URL must start with https:// and be a valid domain"
        );
        hasErrors = true;
      }
    }

    if (sourceCandidate === true) {
      if (!email) {
        setEmailError("Email is required.");
        hasErrors = true;
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        setEmailError("Invalid email format.");
        hasErrors = true;
      } else {
        hasErrors = false;
      }
    } else {
      hasErrors = false;
    }

    if (!data.dailylimit) {
      setDailyResumeLimitError("Daily resume limit is required");
      hasErrors = true;
    }
    if (!data.totallimit) {
      setTotalResumeLimitError("Total resume limit is required");
      hasErrors = true;
    }
    if (hasErrors) {
      return;
    }

    // If all mandatory parameters are valid, make the API call
    AddNewClientInsert(data)
      .then((response) => {
        if (response?.header?.status === 200) {
          setOpen(true); // Show the popup if status is 200
        } else {
          // console.error("Unexpected response status:", response.header.message);
          toast.error(
            <text
              style={{
                font: "rubik regular",
                color: "black",
                fontWeight: "normal",
                fontSize: "15px",
              }}
            >
              {response?.header?.message}
            </text>
          );
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  };

  // function for select industry
  // const handleIndustryChange = (e) => {
  //   const selectedOption = e.target.options[e.target.selectedIndex];
  //   const selectedValue = selectedOption.value;
  //   const selectedId = selectedOption.id;

  //   setIndustry(selectedValue);
  //   setSelectedId(selectedId);
  // };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };
  const toggleSecondDropdown = () => {
    setIsArrowUp((prev) => !prev);
  };
  // const handleOptionClick = (option) => {
  //   setSelectedOption(option);
  //   toggleDropdown();
  // };

  const handleOptionClick = (industryid, industryname) => {
    setSelectedOption(industryname);
    setIndustryId(industryid);
    toggleDropdown();
  };
  const handleSecondOptionClick = (atsid, atsname) => {
    setSelectedSecondOption(atsname);
    setAts(atsid + "");
    setIsArrowUp(false);
  };

  const dropdownRef = useRef(null);
  const secondDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      if (
        secondDropdownRef.current &&
        !secondDropdownRef.current.contains(event.target)
      ) {
        setIsArrowUp(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyPress = (e) => {
    // Allow only digits
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  useEffect(() => {
    GetIndustryPrompts({ industryid: Number(industryid) })
      // .then((response) => {
      //   // setIndustryPromptdata(response?.data[0]);
      //   setBasePrompt(response?.data[0]?.baseprompt);
      //   setAdditionIndustryPrompt(
      //     response?.data[0]?.additionalbasepromptindusty
      //   );
      //   setUnderstandingJdPrompt(response?.data[0]?.understandingjdprompt);
      //   setUnderstandingCvPrompt(response?.data[0]?.understandingcvprompt);
      //   setCalculationPrompt(response?.data[0]?.calculationprompt);
      //   setResultFormatPrompt(response?.data[0]?.resultformatprompt);
      // })

      .then((response) => {
        if (response.header.status === 200) {
          setBasePrompt(response?.data[0]?.baseprompt);
          setAdditionIndustryPrompt(
            response?.data[0]?.additionalbasepromptindusty
          );
          setUnderstandingJdPrompt(response?.data[0]?.understandingjdprompt);
          setUnderstandingCvPrompt(response?.data[0]?.understandingcvprompt);
          setCalculationPrompt(response?.data[0]?.calculationprompt);
          setResultFormatPrompt(response?.data[0]?.resultformatprompt);
          setVersionRemarkPrompt(response?.data[0]?.versionremark)
        } else {
          setBasePrompt("");
          setAdditionIndustryPrompt("");
          setUnderstandingJdPrompt("");
          setUnderstandingCvPrompt("");
          setCalculationPrompt("");
          setResultFormatPrompt("");
          setVersionRemarkPrompt("")
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [industryid]);

  return (
    <>
      <ToastContainer />
      {isMobile ? (
        <>
          {<AddNewClientModel open={open} setOpen={setOpen} />}
          <Helmet>
            <style>{"body { background-color: #ffff;}"}</style>
            <title>Add New Client</title>
          </Helmet>
          <React.Fragment>
            <Navbar data={activetab} />
            <Box className="flex overflow-auto ">
              <Box className="">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "100vw",
                      height: "auto",
                      background: " #FFFFFF 0% 0% no-repeat padding-box",
                      // boxShadow: "0px 0px 20px #5B93ED33",
                      borderRadius: " 5px",
                      opacity: 1,
                      padding: "7px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        // padding: "6px",
                        gap: "10px",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <p
                          style={{
                            height: "18px",
                            textAlign: "left",
                            font: "normal normal medium 15px/23px Rubik",
                            letterSpacing: "0px",
                            textTransform: "uppercase",
                            opacity: 1,
                            fontWeight: "bold",
                          }}
                        >
                          CLIENT NAME
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            *
                          </span>
                        </p>
                        <input
                          placeholder="Enter Client Name"
                          value={clientName}
                          onChange={(e) =>
                            setClientName(e.target.value.replace)
                          }
                          style={{
                            display: "block",
                            width: "100%",
                            height: "40px",
                            border: "1px solid #708EA4",
                            outline: "none",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            borderRadius: "5px",
                            opacity: 1,
                            padding: "7px",
                            marginTop: "10px",
                          }}
                        />
                        {clientNameError && (
                          <p className="text-sm mt-1 text-red-500">
                            {clientNameError}
                          </p>
                        )}
                      </div>

                      {/*first dropdown */}
                      <div className="w-full ">
                        <p
                          style={{
                            height: "18px",
                            textAlign: "left",
                            font: "normal normal medium 15px/23px Rubik",
                            letterSpacing: "0px",
                            textTransform: "uppercase",
                            opacity: 1,
                            fontWeight: "bold",
                          }}
                        >
                          INDUSTRY
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            *
                          </span>
                        </p>
                        <div
                          style={{ marginTop: "10px" }}
                          className="dropdown__list__parent"
                          ref={dropdownRef}
                        >
                          <button
                            onClick={toggleDropdown}
                            className={`dropdown__list ${isOpen ? "open" : ""}`}
                            style={{ borderColor: "rgb(112, 142, 164)" }}
                          >
                            {selectedOption || "Select Industry"}
                            {!isOpen ? (
                              <img
                                src="images/custom-arrow.svg"
                                alt="Dropdown Arrow"
                              />
                            ) : (
                              <img
                                style={{ transform: "rotate(180deg)" }}
                                src="images/custom-arrow.svg"
                                alt="Dropdown Arrow"
                              />
                            )}
                          </button>

                          {isOpen && (
                            <div
                              className="dropdown__list__child"
                              style={{ borderColor: "rgb(112, 142, 164)" }}
                            >
                              <div
                                id="industryid"
                                value={industryid}
                                onChange={(e) => setIndustryId(e.target.value)}
                              >
                                {industry?.map((industry) => (
                                  <p
                                    key={industry.industryid}
                                    value={industry.industryid}
                                    style={{
                                      borderBottom:
                                        "1px solid rgb(112, 142, 164)",
                                    }}
                                    onClick={() =>
                                      handleOptionClick(
                                        industry.industryid,
                                        industry.industryname
                                      )
                                    }
                                  >
                                    {industry.industryname}
                                  </p>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                        {selectedOptionError && (
                          <p className="text-sm mt-1 text-red-500">
                            {selectedOptionError}
                          </p>
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        // padding: "6px",
                        gap: "10px",
                      }}
                    >
                      <div style={{ width: "100%", position: "relative" }}>
                        <p
                          style={{
                            display: "flex",
                            height: "18px",
                            textAlign: "left",
                            font: "normal normal medium 15px/23px Rubik",
                            letterSpacing: "0px",
                            textTransform: "uppercase",
                            color: "#1F2E39",
                            fontWeight: "bold",
                            marginTop: "10px",
                          }}
                        >
                          CLIENT PLATFORM
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            *
                          </span>
                        </p>
                        <input
                          placeholder="https://dev.talentin.ai"
                          value={clientPlatform}
                          onChange={(e) => setClientPlatform(e.target.value)}
                          style={{
                            display: "block",
                            width: "100%",
                            height: "40px",
                            border: " 1px solid #708EA4",
                            outline: "none",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            borderRadius: "5px",
                            opacity: 1,
                            padding: "7px",
                            font: "normal normal normal 15px/23px Rubik",
                            letterSpacing: " 0px",
                            color: "#1F2E39",
                            textAlign: "left",
                            marginTop: "10px",
                          }}
                        />
                        {/* <p className="absolute font-rubik top-[45px] right-4">
                          {talentinTag}
                        </p> */}
                        {customerPanelUrlError && (
                          <p className="text-sm mt-1 text-red-500">
                            {customerPanelUrlError}
                          </p>
                        )}
                      </div>
                      <div
                        style={{
                          flex: "1px",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <p
                          style={{
                            height: "18px",
                            textAlign: "left",
                            font: "normal normal medium 15px/23px Rubik",
                            letterSpacing: "0px",
                            textTransform: "uppercase",
                            color: "#1F2E39",
                            opacity: 1,
                            fontWeight: "bold",
                          }}
                        >
                          ATS
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            *
                          </span>
                        </p>
                        <div
                          style={{ marginTop: "10px" }}
                          ref={secondDropdownRef}
                        >
                          <button
                            onClick={toggleSecondDropdown}
                            className={`dropdown__list ${
                              isArrowUp ? "open" : ""
                            }`}
                            style={{ borderColor: "rgb(112, 142, 164)" }}
                          >
                            {selectedSecondOption || "No ATS"}
                            {!isArrowUp ? (
                              <img
                                src="images/custom-arrow.svg"
                                alt="Dropdown Arrow"
                              />
                            ) : (
                              <img
                                style={{ transform: "rotate(180deg)" }}
                                src="images/custom-arrow.svg"
                                alt="Dropdown Arrow"
                              />
                            )}
                          </button>

                          {isArrowUp && (
                            <div
                              className="dropdown__list__child"
                              style={{ borderColor: "rgb(112, 142, 164)" }}
                            >
                              <div
                                id="ats"
                                value={ats}
                                onChange={(e) => setAts(e.target.value)}
                              >
                                {customers?.map((customer) => (
                                  <p
                                    key={customer.atsid}
                                    value={customer.atsid}
                                    style={{
                                      borderBottom:
                                        "1px solid rgb(112, 142, 164)",
                                    }}
                                    onClick={() =>
                                      handleSecondOptionClick(
                                        customer.atsid,
                                        customer.atsname
                                      )
                                    }
                                  >
                                    {customer.atsname}
                                  </p>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                        {selectedSecondOptionError && (
                          <p className="text-sm my-1 text-red-500">
                            {selectedSecondOptionError}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    padding: "6px",
                    gap: "20px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <p
                      style={{
                        alignItems: "center",
                        height: "18px",
                        textAlign: "left",
                        font: "normal normal medium 15px/23px Rubik",
                        letterSpacing: "0px",
                        textTransform: "uppercase",
                        color: "#1F2E39",
                        opacity: 1,
                        fontWeight: "bold",
                      }}
                    >
                      DAILY RESUME LIMIT
                      <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                    </p>
                    <input
                      type="number"
                      className="remove-arrow"
                      placeholder="Enter Daily Limit for Resume "
                      value={dailyResumeLimit}
                      onChange={(e) => setDailyResumeLimit(e.target.value)}
                      onKeyPress={handleKeyPress}
                      style={{
                        display: "block",
                        width: "100%",
                        height: "40px",
                        outline: "none",
                        border: "1px solid #708EA4",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        borderRadius: "5px",
                        opacity: 1,
                        padding: "7px",
                        marginTop: "10px",
                        textAlign: "left",
                      }}
                    />
                    {dailyResumeLimitError && (
                      <p className="text-sm mt-1 text-red-500">
                        {dailyResumeLimitError}
                      </p>
                    )}
                  </div>

                  <div style={{ width: "100%" }}>
                    <p
                      style={{
                        height: "18px",
                        textAlign: "left",
                        font: "normal normal medium 15px/23px Rubik",
                        letterSpacing: "0px",
                        textTransform: "uppercase",
                        opacity: 1,
                        fontWeight: "bold",
                      }}
                    >
                      TOTAL RESUME LIMIT
                      <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                    </p>
                    <input
                      type="number"
                      className="remove-arrow"
                      placeholder="Enter Total Limit for Resume "
                      value={totalResumeLimit}
                      onChange={(e) => setTotalResumeLimit(e.target.value)}
                      onKeyPress={handleKeyPress}
                      style={{
                        display: "block",
                        width: "100%",
                        height: "40px",
                        outline: "none",
                        border: "1px solid #708EA4",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        borderRadius: "5px",
                        opacity: 1,
                        padding: "7px",
                        marginTop: "10px",
                        textAlign: "left",
                      }}
                    />
                    {totalResumeLimitError && (
                      <p className="text-sm mt-1 text-red-500">
                        {totalResumeLimitError}
                      </p>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    padding: "6px",
                    gap: "20px",
                  }}
                >
                  <div style={{ width: "100%", position: "relative" }}>
                    <p
                      style={{
                        height: "18px",
                        textAlign: "left",
                        font: "normal normal medium 15px/23px Rubik",
                        letterSpacing: "0px",
                        color: "#1F2E39",
                        textTransform: "uppercase",
                        opacity: 1,
                        fontWeight: "bold",
                      }}
                    >
                      BASE PROMPT
                    </p>
                    <textarea
                      placeholder="Enter Base Prompt "
                      name="basePrompt"
                      value={basePrompt}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      className={isFocused ? "textarea-focused" : ""}
                      onChange={(e) => setBasePrompt(e.target.value)}
                      style={{
                        width: "100%",
                        height: isMaximized ? "600px" : "80px",
                        outline: "none",
                        border: " 1px solid #708EA4",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        borderRadius: "5px",
                        opacity: 1,
                        padding: "7px",
                        marginTop: "10px",
                      }}
                    />
                    <img
                      className="w-4 h-4"
                      style={{
                        position: "absolute",
                        top: "34px",
                        bottom: "0px",
                        right: "20px",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={handleToggle}
                      src="/images/window-maximize-regular.svg"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    padding: "6px",
                    gap: "20px",
                  }}
                >
                  <div style={{ width: "100%", position: "relative" }}>
                    <p
                      style={{
                        height: "18px",
                        textAlign: "left",
                        font: "normal normal medium 15px/23px Rubik",
                        letterSpacing: "0px",
                        color: "#1F2E39",
                        textTransform: "uppercase",
                        opacity: 1,
                        fontWeight: "bold",
                      }}
                    >
                      Additional Industry PROMPT
                    </p>
                    <textarea
                      placeholder="Enter Additional Industry Prompt"
                      name="additionIndustryPrompt"
                      value={additionalbasepromptindusty}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      className={isFocused ? "textarea-focused" : ""}
                      onChange={(e) =>
                        setAdditionIndustryPrompt(e.target.value)
                      }
                      style={{
                        width: "100%",
                        height: isMaximizedForadditionIndustry
                          ? "600px"
                          : "80px",
                        outline: "none",
                        border: " 1px solid #708EA4",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        borderRadius: "5px",
                        opacity: 1,
                        padding: "7px",
                        marginTop: "10px",
                      }}
                    />
                    <img
                      className="w-4 h-4"
                      style={{
                        position: "absolute",
                        top: "34px",
                        bottom: "0px",
                        right: "20px",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={handleToggleForadditionIndustry}
                      src="/images/window-maximize-regular.svg"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    padding: "6px",
                    gap: "20px",
                  }}
                >
                  <div style={{ width: "100%", position: "relative" }}>
                    <p
                      style={{
                        height: "18px",
                        textAlign: "left",
                        font: "normal normal medium 15px/23px Rubik",
                        letterSpacing: "0px",
                        color: "#1F2E39",
                        textTransform: "uppercase",
                        opacity: 1,
                        fontWeight: "bold",
                      }}
                    >
                      Understanding JD PROMPT
                    </p>
                    <textarea
                      placeholder="Enter Understanding JD Prompt "
                      name="understadingJdPrompt"
                      value={understadingJdPrompt}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      className={isFocused ? "textarea-focused" : ""}
                      onChange={(e) => setUnderstandingJdPrompt(e.target.value)}
                      style={{
                        width: "100%",
                        height: isMaximizedForunderstadingJdPrompt
                          ? "600px"
                          : "80px",
                        outline: "none",
                        border: " 1px solid #708EA4",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        borderRadius: "5px",
                        opacity: 1,
                        padding: "7px",
                        marginTop: "10px",
                      }}
                    />
                    <img
                      className="w-4 h-4"
                      style={{
                        position: "absolute",
                        top: "34px",
                        bottom: "0px",
                        right: "20px",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={handleToggleForunderstadingJdPrompt}
                      src="/images/window-maximize-regular.svg"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    padding: "6px",
                    gap: "20px",
                  }}
                >
                  <div style={{ width: "100%", position: "relative" }}>
                    <p
                      style={{
                        height: "18px",
                        textAlign: "left",
                        font: "normal normal medium 15px/23px Rubik",
                        letterSpacing: "0px",
                        color: "#1F2E39",
                        textTransform: "uppercase",
                        opacity: 1,
                        fontWeight: "bold",
                      }}
                    >
                      Understanding Cv PROMPT
                    </p>
                    <textarea
                      placeholder="Enter Understanding CV Prompt"
                      name="understadingCvPrompt"
                      value={understadingCvPrompt}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      className={isFocused ? "textarea-focused" : ""}
                      onChange={(e) => setUnderstandingCvPrompt(e.target.value)}
                      style={{
                        width: "100%",
                        height: isMaximizedForCvPrompt ? "600px" : "80px",
                        border: " 1px solid #708EA4",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        borderRadius: "5px",
                        opacity: 1,
                        padding: "7px",
                        marginTop: "10px",
                      }}
                    />
                    <img
                      className="w-4 h-4"
                      style={{
                        position: "absolute",
                        top: "34px",
                        bottom: "0px",
                        right: "20px",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={handleToggleForCvPrompt}
                      src="/images/window-maximize-regular.svg"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    padding: "6px",
                    gap: "20px",
                  }}
                >
                  <div style={{ width: "100%", position: "relative" }}>
                    <p
                      style={{
                        height: "18px",
                        textAlign: "left",
                        font: "normal normal medium 15px/23px Rubik",
                        letterSpacing: "0px",
                        color: "#1F2E39",
                        textTransform: "uppercase",
                        opacity: 1,
                        fontWeight: "bold",
                      }}
                    >
                      Calculation PROMPT
                    </p>
                    <textarea
                      placeholder="Enter Calculation Prompt "
                      name="calculationPrompt"
                      value={calculationPrompt}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      className={isFocused ? "textarea-focused" : ""}
                      onChange={(e) => setCalculationPrompt(e.target.value)}
                      style={{
                        width: "100%",
                        height: isMaximizedForCalculationPrompt
                          ? "600px"
                          : "80px",
                        outline: "none",
                        border: " 1px solid #708EA4",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        borderRadius: "5px",
                        opacity: 1,
                        padding: "7px",
                        marginTop: "10px",
                      }}
                    />
                    <img
                      className="w-4 h-4"
                      style={{
                        position: "absolute",
                        top: "34px",
                        bottom: "0px",
                        right: "20px",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={handleToggleForCalculationPrompt}
                      src="/images/window-maximize-regular.svg"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    padding: "6px",
                    gap: "20px",
                  }}
                >
                  <div style={{ width: "100%", position: "relative" }}>
                    <p
                      style={{
                        height: "18px",
                        textAlign: "left",
                        font: "normal normal medium 15px/23px Rubik",
                        letterSpacing: "0px",
                        color: "#1F2E39",
                        textTransform: "uppercase",
                        opacity: 1,
                        fontWeight: "bold",
                      }}
                    >
                      Result Format PROMPT
                    </p>
                    <textarea
                      placeholder="Enter Result Format Prompt "
                      name="resultFormatPrompt"
                      value={resultFormatPrompt}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      className={isFocused ? "textarea-focused" : ""}
                      onChange={(e) => setResultFormatPrompt(e.target.value)}
                      style={{
                        width: "100%",
                        height: isMaximizedForresultFormatPrompt
                          ? "600px"
                          : "80px",
                        outline: "none",
                        border: " 1px solid #708EA4",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        borderRadius: "5px",
                        opacity: 1,
                        padding: "7px",
                        marginTop: "10px",
                      }}
                    />
                    <img
                      className="w-4 h-4"
                      style={{
                        position: "absolute",
                        top: "34px",
                        bottom: "0px",
                        right: "20px",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={handleToggleForresultFormatPromp}
                      src="/images/window-maximize-regular.svg"
                    />
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    paddingLeft: "8px",
                    paddingRight: "5px",
                  }}
                >
                  <p
                    style={{
                      height: "18px",
                      textAlign: "left",
                      font: "normal normal medium 15px/23px Rubik",
                      letterSpacing: "0px",
                      textTransform: "uppercase",
                      opacity: 1,
                      fontWeight: "bold",
                    }}
                  >
                    Version Remark
                  </p>
                  <input
                    placeholder="Enter Version Remark"
                    value={versionRemarkPrompt}
                    onChange={(e) => setVersionRemarkPrompt(e.target.value)}
                    style={{
                      display: "block",
                      width: "100%",
                      height: "40px",
                      border: "1px solid #708EA4",
                      outline: "none",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      borderRadius: "5px",
                      opacity: 1,
                      padding: "7px",
                      marginTop: "10px",
                    }}
                  />
                </div>

                
                <div className="flex flex-col border border-gray-400 rounded-lg mt-3 mx-2 py-3">
  <text className="text-lg font-bold pl-5 ">Demographics:</text>
  <div className="grid grid-cols-3 400:grid-cols-2 px-8">
  <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      D, E & I
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={deAndI}
                      onChange={(e) => setDeAndI(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                    Nationality
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={nationality}
                      onChange={(e) => setNationality(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Gender
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={gender}
                      onChange={(e) => setGender(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Age
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={age}
                      onChange={(e) => setAge(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>


                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Arabic Speaking.
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={arabicspeak}
                      onChange={(e) => setArabicspeak(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                
                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Current Job Location.
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={currJobLocation}
                      onChange={(e) => setCurrJobLocation(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

  </div>

</div>


<div className="flex flex-col mt-5 border border-gray-400 rounded-lg mx-2 py-3 ">
  <text className="text-lg font-bold pl-5">Advanced Features:</text>
<div className="flex flex-col gap-1 w-full">
  <div className=" grid grid-cols-3 400:grid-cols-2 w-full pl-8">
  <div>
                      <p >
                        <text
                          style={{
                            color: "#1F2E39",
                            font: "normal normal medium 15px/23px Rubik",
                            fontWeight: "600",
                            fontSize: "15px",
                            // marginRight: "90px",
                          }}
                        >
                          4 Cv Upload
                        </text>
                      </p>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={is4cvupload}
                          onChange={(e) => set4cvupload(e.target.checked)}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>

  <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Bulk Upload
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={bulkupload}
                      onChange={(e) => setBulkupload(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
   
                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "70px",
                      }}
                    >
                   Detailed View
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={detailedview}
                      onChange={(e) => setDetailedview(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                
                   
                <div>
                  <p>
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Screening
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={screening}
                      onChange={(e) => setScreening(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>


                <div className="hidden 400:block">
              <div className="  flex  ml-2 gap-3 items-center 400:flex-col">
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "70px",
                      }}
                    >
                      Source Candidate
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={sourceCandidate}
                      onChange={(e) => setSourceCandidate(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                </div>
  </div>
  <div className="flex justify-start items-center mt-3 700:flex-col   w-full gap-3">    
  <div className="block 400:hidden">
              <div className="  flex  ml-2 gap-3  400:flex-col">
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "70px",
                      }}
                    >
                      Source Candidate
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={sourceCandidate}
                      onChange={(e) => setSourceCandidate(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                </div>
              {sourceCandidate === true && (
                       <div
                          style={{ flex: "1px", width: "100%" }}
                          className="px-3 flex items-center gap-1"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              opacity: 1,
                              fontWeight: "600",
                            
                            }}
                          >
                            Email
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <input
                            name="email"
                            placeholder="Sourcing Agency Email"
                            value={email}
                            onChange={handleInputChange(
                              setEmail,
                              setEmailError
                            )}
                            className="w-[70%] 400:w-[100%]"
                            style={{
                              display: "block",
                              height: "40px",
                              outline: "none",
                              border: "1px solid #708EA4",
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              borderRadius: "5px",
                              opacity: 1,
                              padding: "7px",
                             
                            }}
                          />
                          {emailError && (
                            <p style={{ color: "red", marginTop: "5px" }}>
                              {emailError}
                            </p>
                          )}
                        </div>
             )}

   </div>

  </div>

</div>

<div className="flex flex-col mt-5 border border-gray-400 rounded-lg mx-2 py-3">
  <text className="text-lg font-bold pl-5">Integration:</text>
  <div className="grid grid-cols-3 400:grid-cols-2 px-8">
  <div>
                    <p style={{ marginTop: "10px" }}>
                      <text
                        style={{
                          color: "#1F2E39",
                          font: "normal normal medium 15px/23px Rubik",
                          fontWeight: "600",
                          fontSize: "15px",
                          // marginRight: "175px",
                        }}
                      >
                       Ats Sync
                      </text>
                    </p>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={autoAtsSync}
                        onChange={(e) => setAutoAtsSync(e.target.checked)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
   
           
                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "70px",
                      }}
                    >
                      Standardization
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={standardization}
                      onChange={(e) => setStandardization(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>


  </div>

</div>

<div className="flex flex-col mt-5 border border-gray-400 rounded-lg mx-2 py-3 ">
  <text className="text-lg font-bold pl-5">Skills:</text>
              <div
                className=" grid grid-cols-3 400:grid-cols-2 px-8"
              >

<div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Critical Skills
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={criticalskill}
                      onChange={(e) => setCriticalskill(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Similiar Ind. Exp.
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={expsmiliar}
                      onChange={(e) => setExpsmiliar(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Highest Qualif.
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={higestQulic}
                      onChange={(e) => setHigestQulic(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                
                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Over Experienced
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={overexp}
                      onChange={(e) => setOverexp(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                
                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Over Qualified
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={overquali}
                      onChange={(e) => setOverquali(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                
                <div>
                  <p>
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Driving License
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={drivinglic}
                      onChange={(e) => setDrivinglic(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
        
              </div>
</div>

    
              <div className="pl-6">
                  <p style={{marginTop:"10px"}} >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Active
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

             
                <div className="mt-5 ml-2 flex">
                  <button
                    className="Add-Client"
                    onClick={OnSubmit}
                    style={{
                      top: "725px",
                      left: "300px",
                      width: "150px",
                      height: "40px",
                      background: " #21D6AA 0% 0% no-repeat padding-box",
                      borderRadius: " 5px",
                      opacity: 1,
                    }}
                  >
                    Save
                  </button>

                  <button
                    className="discard"
                    style={{
                      marginLeft: "28px",
                      marginTop: "3px",
                      width: " 66px",
                      height: "18px",
                      textAlign: "left",
                      font: "normal normal medium 15px/23px Rubik",
                      letterSpacing: "0px",
                      color: " #E77388",
                      textTransform: "uppercase",
                      opacity: 1,
                    }}
                    onClick={() => navigate(-1)}
                  >
                    DISCARD
                  </button>
                </div>
              </Box>
            </Box>
          </React.Fragment>
        </>
      ) : (
        <>
          {<AddNewClientModel open={open} setOpen={setOpen} />}
          <Helmet>
            <title>Add New Client</title>
          </Helmet>
          <Box sx={{ display: "flex" }}>
            <Navbar data={activetab} />
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 3, maxHeight: "100vh", overflow: "auto" }}
              //className="px-6 pt-6 "
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <HeaderBar
                    title={"Add New Client "}
                    toggleDrawer={toggleDrawer}
                  />
                </div>
                <div
                  style={{
                    maxWidth: "78vm",
                    // height: "683px",
                    background: " #FFFFFF 0% 0% no-repeat padding-box",
                    // boxShadow: "0px 0px 20px #5B93ED33",
                    borderRadius: " 5px",
                    opacity: 1,

                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      padding: "6px",
                      gap: "50px",
                    }}
                  >
                    <div style={{ flex: "1px", width: "100%" }}>
                      <p
                        style={{
                          height: "18px",
                          textAlign: "left",
                          font: "normal normal medium 15px/23px Rubik",
                          letterSpacing: "0px",
                          textTransform: "uppercase",
                          opacity: 1,
                          fontWeight: "600",
                        }}
                      >
                        CLIENT NAME
                        <span style={{ color: "red", marginLeft: "5px" }}>
                          *
                        </span>
                      </p>
                      <input
                        placeholder="Enter Client Name"
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                        style={{
                          display: "block",
                          width: "100%",
                          height: "40px",
                          border: "1px solid #708EA4",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          borderRadius: "5px",
                          opacity: 1,
                          padding: "7px",
                          marginTop: "10px",
                        }}
                      />
                      {clientNameError && (
                        <p className="text-sm my-1 text-red-500">
                          {clientNameError}
                        </p>
                      )}
                    </div>

                    <div
                      style={{
                        flex: "1px",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "18px",
                          textAlign: "left",
                          font: "normal normal medium 15px/23px Rubik",
                          letterSpacing: "0px",
                          textTransform: "uppercase",
                          opacity: 1,
                          fontWeight: "600",
                        }}
                      >
                        INDUSTRY
                        <span style={{ color: "red", marginLeft: "5px" }}>
                          *
                        </span>
                      </p>
                      {/*first dropdown */}

                      <div
                        style={{ marginTop: "10px" }}
                        className="dropdown__list__parent"
                        ref={dropdownRef}
                      >
                        <button
                          onClick={toggleDropdown}
                          className={`dropdown__list ${isOpen ? "open" : ""}`}
                          style={{ borderColor: "rgb(112, 142, 164)" }}
                        >
                          {selectedOption || "Select Industry"}
                          {!isOpen ? (
                            <img
                              src="images/custom-arrow.svg"
                              alt="Dropdown Arrow"
                            />
                          ) : (
                            <img
                              style={{ transform: "rotate(180deg)" }}
                              src="images/custom-arrow.svg"
                              alt="Dropdown Arrow"
                            />
                          )}
                        </button>

                        {isOpen && (
                          <div
                            className="dropdown__list__child"
                            style={{ borderColor: "rgb(112, 142, 164)" }}
                          >
                            <div
                              id="industryid"
                              value={industryid}
                              onChange={(e) => setIndustryId(e.target.value)}
                            >
                              {industry?.map((industry) => (
                                <p
                                  key={industry.industryid}
                                  value={industry.industryid}
                                  style={{
                                    borderBottom:
                                      "1px solid rgb(112, 142, 164)",
                                  }}
                                  onClick={() =>
                                    handleOptionClick(
                                      industry.industryid,
                                      industry.industryname
                                    )
                                  }
                                >
                                  {industry.industryname}
                                </p>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      {selectedOptionError && (
                        <p className="text-sm my-1 text-red-500">
                          {selectedOptionError}
                        </p>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      padding: "6px",
                      gap: "50px",
                    }}
                  >
                    <div
                      style={{
                        flex: "1px",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          height: "18px",
                          textAlign: "left",
                          font: "normal normal medium 15px/23px Rubik",
                          letterSpacing: "0px",
                          textTransform: "uppercase",
                          color: "#1F2E39",
                          fontWeight: "600",
                        }}
                      >
                        CLIENT PLATFORM
                        <span style={{ color: "red", marginLeft: "5px" }}>
                          *
                        </span>
                      </p>
                      <input
                        placeholder="https://dev.talentin.ai"
                        value={clientPlatform}
                        onChange={(e) => setClientPlatform(e.target.value)}
                        style={{
                          display: "block",
                          width: "100%",
                          height: "40px",
                          border: "1px solid #708EA4",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          borderRadius: "5px",
                          opacity: 1,
                          padding: "7px",
                          marginTop: "10px",
                        }}
                      />
                      {/* <p className="absolute font-rubik top-[36px] right-5">
                        {talentinTag}
                      </p> */}
                      {customerPanelUrlError && (
                        <p className="text-sm my-1 text-red-500">
                          {customerPanelUrlError}
                        </p>
                      )}
                    </div>
                    {/* <div
                      style={{
                        flex: "1px",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <p
                        style={{
                          height: "18px",
                          textAlign: "left",
                          font: "normal normal medium 15px/23px Rubik",
                          letterSpacing: "0px",
                          textTransform: "uppercase",
                          color: "#1F2E39",
                          opacity: 1,
                          fontWeight: "600",
                        }}
                      >
                        ATS
                      </p>

                      <select
                        id="ats"
                        value={ats}
                        onChange={(e) => setAts(e.target.value)}
                        onClick={() => setIsArrowUp(!isArrowUp)}
                        placeholder="Select ATS"
                        style={{
                          display: "block",
                          width: "100%",
                          height: "40px",
                          border: "1px solid #708EA4",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          borderRadius: "5px",
                          opacity: 1,
                          padding: "7px",
                          marginTop: "10px",
                          textAlign: "left",
                          appearance: "none",
                          WebkitAppearance: "none",
                          MozAppearance: "none",
                          cursor: "pointer"
                        }}
                      >
                        <option value="" disabled selected hidden>
                          Select ATS
                        </option>

                        {customers?.map((customer) => (
                          <option key={customer.atsid} value={customer.atsid}>
                            {customer.atsname}
                          </option>
                        ))}
                      </select>
                      <div className="absolute top-[45px] right-5">
                        {!isArrowUp ? (
                          <img
                            src="images/custom-arrow.svg"
                            alt="Dropdown Arrow"
                          />
                        ) : (
                          <img
                            style={{ transform: "rotate(180deg)" }}
                            src="images/custom-arrow.svg"
                            alt="Dropdown Arrow"
                          />
                        )}
                      </div>
                    </div> */}

                    <div
                      style={{
                        flex: "1px",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "18px",
                          textAlign: "left",
                          font: "normal normal medium 15px/23px Rubik",
                          letterSpacing: "0px",
                          textTransform: "uppercase",
                          opacity: 1,
                          fontWeight: "600",
                        }}
                      >
                        ATS
                        <span style={{ color: "red", marginLeft: "5px" }}>
                          *
                        </span>
                      </p>
                      <div
                        style={{ marginTop: "10px" }}
                        ref={secondDropdownRef}
                      >
                        <button
                          onClick={toggleSecondDropdown}
                          className={`dropdown__list ${
                            isArrowUp ? "open" : ""
                          }`}
                          style={{ borderColor: "rgb(112, 142, 164)" }}
                        >
                          {selectedSecondOption || "No ATS"}
                          {!isArrowUp ? (
                            <img
                              src="images/custom-arrow.svg"
                              alt="Dropdown Arrow"
                            />
                          ) : (
                            <img
                              style={{ transform: "rotate(180deg)" }}
                              src="images/custom-arrow.svg"
                              alt="Dropdown Arrow"
                            />
                          )}
                        </button>

                        {isArrowUp && (
                          <div
                            className="dropdown__list__child"
                            style={{ borderColor: "rgb(112, 142, 164)" }}
                          >
                            <div
                              id="ats"
                              value={ats}
                              onChange={(e) => setAts(e.target.value)}
                            >
                              {customers?.map((customer) => (
                                <p
                                  key={customer.atsid}
                                  value={customer.atsid}
                                  style={{
                                    borderBottom:
                                      "1px solid rgb(112, 142, 164)",
                                  }}
                                  onClick={() =>
                                    handleSecondOptionClick(
                                      customer.atsid,
                                      customer.atsname
                                    )
                                  }
                                >
                                  {customer.atsname}
                                </p>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      {selectedSecondOptionError && (
                        <p className="text-sm my-1 text-red-500">
                          {selectedSecondOptionError}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",

                  padding: "5px",
                  gap: "50px",
                }}
              >
                <div style={{ flex: "1px", width: "100%" }}>
                  <p
                    style={{
                      alignItems: "center",
                      height: "18px",
                      textAlign: "left",
                      font: "normal normal medium 15px/23px Rubik",
                      letterSpacing: "0px",
                      textTransform: "uppercase",
                      color: "#1F2E39",
                      opacity: 1,
                      fontWeight: "600",
                    }}
                  >
                    DAILY RESUME LIMIT
                    <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                  </p>
                  <input
                    type="number"
                    className="remove-arrow"
                    placeholder="Enter Daily Limit for Resume "
                    value={dailyResumeLimit}
                    onChange={(e) => setDailyResumeLimit(e.target.value)}
                    onKeyPress={handleKeyPress}
                    style={{
                      display: "block",
                      width: "60%",
                      height: "40px",
                      border: "1px solid #708EA4",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      borderRadius: "5px",
                      opacity: 1,
                      padding: "7px",
                      marginTop: "10px",
                      textAlign: "left",
                    }}
                  />

                  {dailyResumeLimitError && (
                    <p className="text-sm my-1 text-red-500">
                      {dailyResumeLimitError}
                    </p>
                  )}
                </div>

                <div style={{ flex: "1px", width: "100%" }}>
                  <p
                    style={{
                      height: "18px",
                      textAlign: "left",
                      font: "normal normal medium 15px/23px Rubik",
                      letterSpacing: "0px",
                      textTransform: "uppercase",
                      opacity: 1,
                      fontWeight: "600",
                    }}
                  >
                    TOTAL RESUME LIMIT
                    <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                  </p>
                  <input
                    type="number"
                    className="remove-arrow"
                    placeholder="Enter Total Limit for Resume "
                    value={totalResumeLimit}
                    onChange={(e) => setTotalResumeLimit(e.target.value)}
                    onKeyPress={handleKeyPress}
                    style={{
                      display: "block",
                      width: "60%",
                      height: "40px",
                      border: "1px solid #708EA4",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      borderRadius: "5px",
                      opacity: 1,
                      padding: "7px",
                      marginTop: "10px",
                      textAlign: "left",
                    }}
                  />
                  {totalResumeLimitError && (
                    <p className="text-sm my-1 text-red-500">
                      {totalResumeLimitError}
                    </p>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "6px",
                }}
              >
                <div
                  style={{ flex: "1px", width: "100%", position: "relative" }}
                >
                  <p
                    style={{
                      height: "18px",
                      textAlign: "left",
                      font: "normal normal medium 15px/23px Rubik",
                      letterSpacing: "0px",
                      color: "#1F2E39",
                      textTransform: "uppercase",
                      opacity: 1,
                      fontWeight: "600",
                    }}
                  >
                    BASE PROMPT
                    {/* <span style={{ color: "red", marginLeft: "5px" }}>
                          *
                        </span> */}
                  </p>
                  <textarea
                    placeholder="Enter Base Prompt "
                    name="basePrompt"
                    value={basePrompt}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={(e) => setBasePrompt(e.target.value)}
                    style={{
                      width: "100%",
                      height: isMaximized ? "600px" : "80px",
                      border: " 1px solid #708EA4",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      borderRadius: "5px",
                      opacity: 1,
                      padding: "7px",
                      marginTop: "10px",
                    }}
                  />
                  <img
                    className="w-4 h-4"
                    style={{
                      position: "absolute",
                      top: "34px",
                      bottom: "0px",
                      right: "20px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                    onClick={handleToggle}
                    src="/images/window-maximize-regular.svg"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "6px",
                }}
              >
                <div
                  style={{ flex: "1px", width: "100%", position: "relative" }}
                >
                  <p
                    style={{
                      height: "18px",
                      textAlign: "left",
                      font: "normal normal medium 15px/23px Rubik",
                      letterSpacing: "0px",
                      color: " #1F2E39",
                      textTransform: "uppercase",
                      opacity: 1,
                      fontWeight: "600",
                    }}
                  >
                    Additional Industry PROMPT
                    {/* <span style={{ color: "red", marginLeft: "5px" }}>
                          *
                        </span> */}
                  </p>
                  <textarea
                    placeholder="Enter Additional Industry Prompt "
                    name="additionIndustryPrompt"
                    value={additionalbasepromptindusty}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    className={isFocused ? "textarea-focused" : ""}
                    onChange={(e) => setAdditionIndustryPrompt(e.target.value)}
                    style={{
                      width: "100%",
                      height: isMaximizedForadditionIndustry ? "600px" : "80px",
                      border: " 1px solid #708EA4",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      borderRadius: "5px",
                      opacity: 1,
                      padding: "7px",
                      marginTop: "10px",
                    }}
                  />
                  <img
                    className="w-4 h-4"
                    style={{
                      position: "absolute",
                      top: "34px",
                      bottom: "0px",
                      right: "20px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                    onClick={handleToggleForadditionIndustry}
                    src="/images/window-maximize-regular.svg"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "6px",
                }}
              >
                <div
                  style={{ flex: "1px", width: "100%", position: "relative" }}
                >
                  <p
                    style={{
                      height: "18px",
                      textAlign: "left",
                      font: "normal normal medium 15px/23px Rubik",
                      letterSpacing: "0px",
                      color: "#1F2E39",
                      textTransform: "uppercase",
                      opacity: 1,
                      fontWeight: "600",
                    }}
                  >
                    Understanding JD PROMPT
                    {/* <span style={{ color: "red", marginLeft: "5px" }}>
                          *
                        </span> */}
                  </p>
                  <textarea
                    placeholder="Enter Understanding JD Prompt "
                    name="understadingJdPrompt"
                    value={understadingJdPrompt}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    className={isFocused ? "textarea-focused" : ""}
                    onChange={(e) => setUnderstandingJdPrompt(e.target.value)}
                    style={{
                      width: "100%",
                      height: isMaximizedForunderstadingJdPrompt
                        ? "600px"
                        : "80px",
                      border: " 1px solid #708EA4",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      borderRadius: "5px",
                      opacity: 1,
                      padding: "7px",
                      marginTop: "10px",
                    }}
                  />
                  <img
                    className="w-4 h-4"
                    style={{
                      position: "absolute",
                      top: "34px",
                      bottom: "0px",
                      right: "20px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                    onClick={handleToggleForunderstadingJdPrompt}
                    src="/images/window-maximize-regular.svg"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "6px",
                }}
              >
                <div
                  style={{ flex: "1px", width: "100%", position: "relative" }}
                >
                  <p
                    style={{
                      height: "18px",
                      textAlign: "left",
                      font: "normal normal medium 15px/23px Rubik",
                      letterSpacing: "0px",
                      color: "#1F2E39",
                      textTransform: "uppercase",
                      opacity: 1,
                      fontWeight: "600",
                    }}
                  >
                    Understanding CV PROMPT
                    {/* <span style={{ color: "red", marginLeft: "5px" }}>
                          *
                        </span> */}
                  </p>
                  <textarea
                    placeholder="Enter Understanding CV Prompt "
                    name="understadingCvPrompt"
                    value={understadingCvPrompt}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    className={isFocused ? "textarea-focused" : ""}
                    onChange={(e) => setUnderstandingCvPrompt(e.target.value)}
                    style={{
                      width: "100%",
                      height: isMaximizedForCvPrompt ? "600px" : "80px",
                      border: " 1px solid #708EA4",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      borderRadius: "5px",
                      opacity: 1,
                      padding: "7px",
                      marginTop: "10px",
                    }}
                  />
                  <img
                    className="w-4 h-4"
                    style={{
                      position: "absolute",
                      top: "34px",
                      bottom: "0px",
                      right: "20px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                    onClick={handleToggleForCvPrompt}
                    src="/images/window-maximize-regular.svg"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "6px",
                }}
              >
                <div
                  style={{ flex: "1px", width: "100%", position: "relative" }}
                >
                  <p
                    style={{
                      height: "18px",
                      textAlign: "left",
                      font: "normal normal medium 15px/23px Rubik",
                      letterSpacing: "0px",
                      color: "#1F2E39",
                      textTransform: "uppercase",
                      opacity: 1,
                      fontWeight: "600",
                    }}
                  >
                    Calculation PROMPT
                    {/* <span style={{ color: "red", marginLeft: "5px" }}>
                          *
                        </span> */}
                  </p>
                  <textarea
                    placeholder="Enter Calculation Prompt "
                    name="calculationPrompt"
                    value={calculationPrompt}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    className={isFocused ? "textarea-focused" : ""}
                    onChange={(e) => setCalculationPrompt(e.target.value)}
                    style={{
                      width: "100%",
                      height: isMaximizedForCalculationPrompt
                        ? "600px"
                        : "80px",
                      border: " 1px solid #708EA4",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      borderRadius: "5px",
                      opacity: 1,
                      padding: "7px",
                      marginTop: "10px",
                    }}
                  />
                  <img
                    className="w-4 h-4"
                    style={{
                      position: "absolute",
                      top: "34px",
                      bottom: "0px",
                      right: "20px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                    onClick={handleToggleForCalculationPrompt}
                    src="/images/window-maximize-regular.svg"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "6px",
                }}
              >
                <div
                  style={{ flex: "1px", width: "100%", position: "relative" }}
                >
                  <p
                    style={{
                      height: "18px",
                      textAlign: "left",
                      font: "normal normal medium 15px/23px Rubik",
                      letterSpacing: "0px",
                      color: "#1F2E39",
                      textTransform: "uppercase",
                      opacity: 1,
                      fontWeight: "600",
                    }}
                  >
                    Result Format PROMPT
                    {/* <span style={{ color: "red", marginLeft: "5px" }}>
                          *
                        </span> */}
                  </p>
                  <textarea
                    placeholder="Enter Result Format Prompt "
                    name="resultFormatPrompt"
                    value={resultFormatPrompt}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    className={isFocused ? "textarea-focused" : ""}
                    onChange={(e) => setResultFormatPrompt(e.target.value)}
                    style={{
                      width: "100%",
                      height: isMaximizedForresultFormatPrompt
                        ? "600px"
                        : "80px",
                      border: " 1px solid #708EA4",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      borderRadius: "5px",
                      opacity: 1,
                      padding: "7px",
                      marginTop: "10px",
                    }}
                  />
                  <img
                    className="w-4 h-4"
                    style={{
                      position: "absolute",
                      top: "34px",
                      bottom: "0px",
                      right: "20px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                    onClick={handleToggleForresultFormatPromp}
                    src="/images/window-maximize-regular.svg"
                  />
                </div>
              </div>

              <div style={{ flex: "1px", width: "100%", paddingLeft: "5px" }}>
                <p
                  style={{
                    height: "18px",
                    textAlign: "left",
                    font: "normal normal medium 15px/23px Rubik",
                    letterSpacing: "0px",
                    textTransform: "uppercase",
                    opacity: 1,
                    fontWeight: "600",
                  }}
                >
                  Version remark
                </p>
                <input
                  placeholder="Enter Version Remark"
                  value={versionRemarkPrompt}
                  onChange={(e) => setVersionRemarkPrompt(e.target.value)}
                  style={{
                    display: "block",
                    width: "100%",
                    height: "40px",
                    border: "1px solid #708EA4",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    borderRadius: "5px",
                    opacity: 1,
                    padding: "7px",
                    marginTop: "10px",
                  }}
                />
              </div>
             

              

                <div className="flex flex-col border border-gray-400 rounded-lg mt-3 py-3">
  <text className="text-lg font-bold pl-5 ">Demographics:</text>
  <div className=" grid grid-cols-6 1200:grid-cols-3 px-8">
  <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      D, E & I
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={deAndI}
                      onChange={(e) => setDeAndI(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                    Nationality
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={nationality}
                      onChange={(e) => setNationality(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Gender
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={gender}
                      onChange={(e) => setGender(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Age
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={age}
                      onChange={(e) => setAge(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>


                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Arabic Speaking.
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={arabicspeak}
                      onChange={(e) => setArabicspeak(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                
                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Current Job Location.
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={currJobLocation}
                      onChange={(e) => setCurrJobLocation(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

  </div>

</div>


<div className="flex flex-col mt-5 border border-gray-400 rounded-lg py-3 ">
  <text className="text-lg font-bold pl-5">Advanced Features:</text>
<div className="flex flex-row  1200:flex-col gap-1 w-full">
  <div className=" grid grid-cols-4 1200:grid-cols-3 w-full pl-8">
  <div>
                      <p >
                        <text
                          style={{
                            color: "#1F2E39",
                            font: "normal normal medium 15px/23px Rubik",
                            fontWeight: "600",
                            fontSize: "15px",
                            // marginRight: "90px",
                          }}
                        >
                          4 Cv Upload
                        </text>
                      </p>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={is4cvupload}
                          onChange={(e) => set4cvupload(e.target.checked)}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>

  <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Bulk Upload
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={bulkupload}
                      onChange={(e) => setBulkupload(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
   
                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "70px",
                      }}
                    >
                   Detailed View
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={detailedview}
                      onChange={(e) => setDetailedview(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                
                   
                <div>
                  <p>
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Screening
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={screening}
                      onChange={(e) => setScreening(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
  </div>
  <div className="flex justify-start items-center mt-3 pl-6 w-full gap-10">    
              <div className="flex justify-start flex-col 1200:flex-row 1200:gap-3 1200:items-center  ">
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "70px",
                      }}
                    >
                      Source Candidate
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={sourceCandidate}
                      onChange={(e) => setSourceCandidate(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              {sourceCandidate === true && (
                       <div
                          style={{ flex: "1px", width: "100%" }}
                          className="px-3 flex items-center gap-3"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              opacity: 1,
                              fontWeight: "600",
                            
                            }}
                          >
                            Email
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <input
                            name="email"
                            placeholder="Sourcing Agency Email"
                            value={email}
                            onChange={handleInputChange(
                              setEmail,
                              setEmailError
                            )}
                            style={{
                              display: "block",
                              width: "100%",
                              height: "40px",
                              outline: "none",
                              border: "1px solid #708EA4",
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              borderRadius: "5px",
                              opacity: 1,
                              padding: "7px",
                             
                            }}
                          />
                          {emailError && (
                            <p style={{ color: "red", marginTop: "5px" }}>
                              {emailError}
                            </p>
                          )}
                        </div>
             )}

   </div>

  </div>

</div>

<div className="flex flex-col mt-5 border border-gray-400 rounded-lg py-3">
  <text className="text-lg font-bold pl-5">Integration:</text>
  <div className=" grid grid-cols-6 1200:grid-cols-3 px-8">
  <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                     Ats Sync
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={autoAtsSync}
                      onChange={(e) => setAutoAtsSync(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
   
           
                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "70px",
                      }}
                    >
                      Standardization
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={standardization}
                      onChange={(e) => setStandardization(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>


  </div>

</div>

<div className="flex flex-col mt-5 border border-gray-400 rounded-lg py-3 ">
  <text className="text-lg font-bold pl-5">Skills:</text>
              <div
                className=" grid grid-cols-6 1200:grid-cols-3 px-8"
              >

<div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Critical Skills
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={criticalskill}
                      onChange={(e) => setCriticalskill(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Similiar Ind. Exp.
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={expsmiliar}
                      onChange={(e) => setExpsmiliar(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Highest Qualif.
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={higestQulic}
                      onChange={(e) => setHigestQulic(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                
                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Over Experienced
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={overexp}
                      onChange={(e) => setOverexp(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                
                <div>
                  <p >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Over Qualified
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={overquali}
                      onChange={(e) => setOverquali(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                
                <div>
                  <p>
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Driving License
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={drivinglic}
                      onChange={(e) => setDrivinglic(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
        
              </div>
</div>

    
              <div className="pl-6">
                  <p style={{marginTop:"10px"}} >
                    <text
                      style={{
                        color: "#1F2E39",
                        font: "normal normal medium 15px/23px Rubik",
                        fontWeight: "600",
                        fontSize: "15px",
                        // marginRight: "90px",
                      }}
                    >
                      Active
                    </text>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

          

              <div className="mb-2 mt-4 flex p-2">
                <button
                  className="Add-Client"
                  onClick={OnSubmit}
                  style={{
                    top: "725px",
                    left: "300px",
                    width: "150px",
                    height: "40px",
                    background: " #21D6AA 0% 0% no-repeat padding-box",
                    borderRadius: " 5px",
                    opacity: 1,
                  }}
                >
                  Save
                </button>

                <button
                  className="discard"
                  style={{
                    top: "736px",
                    left: "480px",
                    marginLeft: "44px",
                    marginTop: "3px",
                    width: " 66px",
                    height: "18px",
                    textAlign: "left",
                    font: "normal normal medium 15px/23px Rubik",
                    letterSpacing: "0px",
                    color: " #E77388",
                    textTransform: "uppercase",
                    opacity: 1,
                  }}
                  onClick={() => navigate(-1)}
                >
                  DISCARD
                </button>
              </div>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default AddNewClient;
