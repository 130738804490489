import React, { useState, useEffect } from "react";
import { Drawer } from "@mui/material";
import { useCandidateData } from "../../data/candidatedata";
import MyDocument from "../../pages/CreatePdf/CreatePdf";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import { useMediaQuery, useTheme } from "@mui/material";
import EmailModal from "../modals/SendEmail";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ShareResult = ({ analysisid, candidatename, jobid  , customer}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [downloadPdf, setDownloadPdf] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));

  const [to, setTo] = useState(localStorage.getItem("emailid"));
  const [subject, setSubject] = useState("Resume Analysis Report for");
  const [body, setBody] = useState("");

  useEffect(() => {
    if (candidatename && jobid) {
      setSubject(`Resume Analysis Report for ${jobid} - ${candidatename}`);
    }
  }, [candidatename, jobid]);

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
  };

  const [data, setData] = useState(null);
  const { GetCandidateResult, sendEmailPdf } = useCandidateData();

  useEffect(() => {}, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await GetCandidateResult(parseInt(analysisid));
        setData(result.data[0]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [GetCandidateResult, analysisid]);

  useEffect(() => {
    if (downloadPdf) {
      setIsOpen(false);
      setDownloadPdf(false);
    }
  }, [downloadPdf]);


  const handlePrint = async () => {
    const pdfBlob = await pdf(
      <MyDocument 
      analysisid={analysisid} 
      data={data}  
      customer={customer} 
      />
    ).toBlob();
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const printWindow = window.open(pdfUrl);
    setIsOpen(false); // Close the drawer
    printWindow.onload = () => {
      printWindow.print();
    };
  };

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  const getTimestamp = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);

    return `${day}-${month}-${year}  ${hours}:${minutes}:${seconds}`;
  };

  const handleEmail = async () => {
    try {
      const pdfBlob = await pdf(
        <MyDocument analysisid={analysisid} data={data} customer={customer} />
      ).toBlob();
      const formData = new FormData();
      formData.append("file", pdfBlob, candidatename + "_report.pdf");
      formData.append("to", to);
      formData.append("subject", subject);
      formData.append("message", body);

      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }

      const response = await sendEmailPdf(formData);

      // console.log(response);

      if (response.header.status === 200) {
        toast.success(
          <text
            style={{
              font: "rubik regular",
              color: "black",
              fontWeight: "normal",
              fontSize: "15px",
            }}
          >
            Email sent successfully!
          </text>
        );
        setSubject(`Resume Analysis Report For` + jobid + "-" + candidatename);
        setIsOpen(false);
      }
    } catch (error) {
      // console.error("Error sending email:", error);
      toast.error("Failed to send email.");
    }
  };

  const timestamp = getTimestamp().replace(/:/g, "_");

  return (
    <>
      <ToastContainer />
      {isMobile ? (
        <>
          <div
            className="flex gap-2 cursor-pointer"
            onClick={handleDrawerToggle}
          >
            <div>
              <img src="/Share_Icon.svg" alt="Share Icon" />
            </div>
          </div>

          <Drawer anchor="bottom" open={isOpen} onClose={handleDrawerToggle}>
            {isOpen && (
              <>
                <div
                  className="absolute top-0 left-0 w-full h-full bg-black opacity-30 cursor-pointer"
                  onClick={handleDrawerToggle}
                ></div>
                <div className="fixed mx-auto w-full inset-x-0 bottom-0 z-50 transform transition-transform ease-in-out duration-300">
                  <div
                    style={{ scrollbarWidth: "none" }}
                    className={`h-[206px] overflow-y-auto  ${
                      isOpen ? "translate-y-0" : "translate-y-full"
                    }`}
                  >
                    {/* Drawer content */}
                    <div className="p-2 bg-white">
                      <div className="flex justify-end">
                        <img
                          src="/images/greenCross2.svg"
                          onClick={handleClosePopup}
                          alt="Close"
                        />
                      </div>

                      <div className="flex justify-center gap-10">
                        <div className="flex flex-col ">
                          <div className="cursor-pointer">
                            <div className="ml-[20%]">
                              <img src="/Push.svg" alt="Push to ATS" />
                            </div>
                            <p className="font-medium text-[15px] leading-[23px] w-16  font-rubik">
                              PUSH TO
                            </p>
                            <p className="font-medium text-[15px] leading-[23px] w-16 ml-[18%]  font-rubik">
                              ATS
                            </p>
                          </div>
                          {/* <div className='cursor-pointer'>
                            <div className='ml-[20%]'>
                              <img src='/Email.svg' alt='Email' />
                            </div>
                            <p className='font-medium text-[15px] ml-[20%] leading-[23px] font-rubik'>
                              EMAIL
                            </p>
                          </div> */}
                          <EmailModal
                            to={to}
                            setTo={setTo}
                            setBody={setBody}
                            setSubject={setSubject}
                            handleEmail={handleEmail}
                            subject={subject}
                            candidatename={candidatename}
                          />
                        </div>
                        <div className="flex flex-col">
                          <div
                            onClick={handlePrint}
                            className="cursor-pointer mb-[40%]"
                          >
                            <div className="ml-[18%]">
                              <img src="/Print.svg" alt="Print" />
                            </div>
                            <p className="font-medium ml-[18%] text-[15px] leading-[23px] font-rubik">
                              PRINT
                            </p>
                          </div>
                          <div onClick={() => setDownloadPdf(true)}>
                            <PDFDownloadLink
                              document={
                                <MyDocument
                                  analysisid={analysisid}
                                  data={data}
                                  customer={customer}
                                />
                              }
                              fileName={`${candidatename} ${timestamp}.pdf`}
                            >
                              <button className="cursor-pointer">
                                <div className="ml-[20%]">
                                  <img src="/Export.svg" alt="Export to PDF" />
                                </div>
                                <p className="font-medium text-[15px] w-16 leading-[23px] font-rubik">
                                  EXPORT TO PDF
                                </p>
                              </button>
                            </PDFDownloadLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Drawer>
        </>
      ) : (
        <>
          <div
            className="flex mt-6 gap-2 cursor-pointer items-center"
            onClick={handleDrawerToggle}
          >
            <div>
              <img src="/Share_Icon.svg" alt="Share Icon" />
            </div>
            SHARE RESULT
          </div>

          <Drawer anchor="bottom" open={isOpen} onClose={handleDrawerToggle}>
            {isOpen && (
              <>
                <div
                  className="absolute top-0 left-0 w-full h-full bg-black opacity-30 cursor-pointer"
                  onClick={handleDrawerToggle}
                ></div>
                <div className="fixed mx-auto w-[550px] inset-x-0 bottom-0 z-50 transform transition-transform ease-in-out duration-300">
                  <img src="/Border.png" alt="" className="fixed h-[550px]" />

                  <div
                    style={{ scrollbarWidth: "none" }}
                    className={`h-[150px] overflow-y-auto px-4 py-6 ${
                      isOpen ? "translate-y-0" : "translate-y-full"
                    }`}
                  >
                    {/* Drawer content */}
                    <div className="p-4 bg-white">
                      <div className="flex justify-end -mt-4">
                        <img
                          src="/images/greenCross2.svg"
                          onClick={handleClosePopup}
                          alt="Close"
                        />
                      </div>

                      <div className="flex  items-center gap-12">
                        <div className="cursor-pointer">
                          <div className="ml-6">
                            <img src="/Push.svg" alt="Push to ATS" />
                          </div>
                          <p className="font-medium w-max text-[15px] leading-[23px] font-rubik">
                            PUSH TO ATS
                          </p>
                        </div>
                        <div className="cursor-pointer">
                          {/* <img src='/Email.svg' alt='Email' />
                          <p className='font-medium text-[15px] leading-[23px] font-rubik'>
                            EMAIL
                          </p> */}
                          <EmailModal
                            to={to}
                            setTo={setTo}
                            setBody={setBody}
                            setSubject={setSubject}
                            handleEmail={handleEmail}
                            subject={subject}
                            candidatename={candidatename}
                          />
                        </div>
                        <div onClick={() => setDownloadPdf(true)}>
                          <PDFDownloadLink
                            document={
                              <MyDocument analysisid={analysisid} data={data} customer={customer} />
                            }
                            fileName={`${candidatename} ${timestamp}.pdf`}
                          >
                            <button className="cursor-pointer">
                              <div className="ml-9">
                                <img src="/Export.svg" alt="Export to PDF" />
                              </div>
                              <p className="font-medium w-max text-[15px] leading-[23px] font-rubik">
                                EXPORT TO PDF
                              </p>
                            </button>
                          </PDFDownloadLink>
                        </div>
                        <div onClick={handlePrint} className="cursor-pointer">
                          <img src="/Print.svg" alt="Print" />
                          <p className="font-medium text-[15px] leading-[23px] font-rubik">
                            PRINT
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Drawer>
        </>
      )}
    </>
  );
};

export default ShareResult;
