import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import { useFeedbacksData } from "../../data/feedbacks";
import { storage } from "../../libs/localStorage";
import { Drawer, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import Pagination from "../../components/Pagination/Pagination";
import { commonData } from "../../data/commondata";
import { useJobsData } from "../../data/jobsdata";
import { useNavigate } from "react-router-dom";
import HeaderBar from "../../components/HeaderBar/HeaderBar";

const optionData = [
  {
    id: "all",
    name: "All",
  },
  {
    id: "7d",
    name: "Last 7 Days",
  },
  {
    id: "30d",
    name: "Last 30 Days",
  },
  {
    id: "90d",
    name: "Last 90 Days",
  },
  {
    id: "ty",
    name: "This Year",
  },
  {
    id: "ly",
    name: "Last Year",
  },
];
const SortData = [
  {
    id: "all",
    name: "All",
  },
  {
    id: "great",
    name: "Great!",
  },
  {
    id: "good",
    name: "Good",
  },
  {
    id: "meh",
    name: "Meh",
  },
  {
    id: "notsatisfied",
    name: "Not Satisfied",
  },
];

const Feedback = () => {
  const { GetFeedbacks } = useFeedbacksData();
  const { storeToken, retrieveToken, clearToken } = storage();
  // const [isLoading, setLoading] = useState(true);
  const token = retrieveToken();
  const customerid = Number(localStorage.getItem("customerid"));
  const [data, setData] = useState(null);
  const pageSize = 10;
  const [totalCounts, setTotalCounts] = useState(null);
  const totalPages = Math.ceil(totalCounts / pageSize);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const [currentPage, setCurrentPage] = useState(0);
  const activetab = "feedback";
  const { GetAtsUrlByAtsJobcode } = useJobsData();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const userType = localStorage.getItem("usertype");
  /*dropdown list */
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(customerid);
  const [filterdate, setFilterdate] = useState("All");
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [selectedFilterdate, setSelectedFilterdate] = useState("all");
  const [filterdata, setFilterdata] = useState("All");
  const [selectedFilterdata, setSelectedFilterdata] = useState("all");
  const [openPhoneDrawer, setOpenPhoneDrawer] = useState(false);
  const containerRef = useRef(null);

  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  const dropdownRef = useRef(null);
  const secondDropdownRef = useRef(null);
  const thirdDropdownRef = useRef(null);

  const toggleDropdown = async () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option?.customername);
    setSelectedCustomer(option?.customerid);
    toggleDropdown();
  };

  /*second dropdown */
  const [isSecondOpen, setSecondIsOpen] = useState(false);
  const { GetAllCustomers } = commonData();
  const toggleSecondDropdown = () => {
    setSecondIsOpen((prev) => !prev);
  };

  const handleSecondOptionClick = (Secondoption) => {
    setSelectedFilterdate(Secondoption.id);
    setFilterdate(Secondoption.name);
    toggleSecondDropdown();
  };

  /*Third dropdown */
  const [isThirdOpen, setThirdIsOpen] = useState(false);
  // const { GetAllCustomers } = commonData();
  const toggleThirdDropdown = () => {
    setThirdIsOpen((prev) => !prev);
  };

  const handleThirdOptionClick = (Secondoption) => {
    setSelectedFilterdata(Secondoption.id);
    setFilterdata(Secondoption.name);
    toggleThirdDropdown();
  };
  const togglePhoneDrawer = () => {
    setOpenPhoneDrawer(!openPhoneDrawer);
  };

  const clearFilter = () => {
    setSelectedOption(null);
    setSelectedCustomer(0);

    setFilterdate("All");
    setSelectedFilterdate("all");

    setFilterdata("All");
    setSelectedFilterdata("all");

    GetFeedbacks({
      pageno: 1,
      pagesize: pageSize,
      search: "",
      customer: selectedCustomer,
      filterdate: "all",
      ratingby: "all",
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setData([]);
          setTotalCounts(0);
          setNoDataAvailable(true);
        } else {
          setData(response.data);
          response?.data?.[0]?.totalcount &&
            setTotalCounts(response?.data?.[0]?.totalcount);
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const applyFilter = () => {
    GetFeedbacks({
      pageno: 1,
      pagesize: pageSize,
      search: "",
      customer: selectedCustomer,
      filterdate: selectedFilterdate || "",
      ratingby: selectedFilterdata || "",
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setData([]);
          setTotalCounts(0);
          setNoDataAvailable(true);
        } else {
          setData(response.data);
          response?.data?.[0]?.totalcount &&
            setTotalCounts(response?.data?.[0]?.totalcount);
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setOpenPhoneDrawer(!openPhoneDrawer);
  };

  /*search */
  const btnSearch = (currentPage = 1) => {
    GetFeedbacks({
      pageno: currentPage,
      pagesize: pageSize,
      search: searchText,
      customer: selectedCustomer,
      filterdate: selectedFilterdate || "",
      ratingby: selectedFilterdata || "",
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setData([]);
          setTotalCounts(0);
          setNoDataAvailable(true);
        } else {
          setData(response.data);
          response?.data?.[0]?.totalcount &&
            setTotalCounts(response?.data?.[0]?.totalcount);
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setCurrentPage(1);
  };

  const clearSearch = () => {
    setSearchText("");
    setCurrentPage(1);
    GetFeedbacks({
      pageno: 1,
      pagesize: pageSize,
      search: "",
      customer: selectedCustomer,
      filterdate: selectedFilterdate || "",
      ratingby: selectedFilterdata || "",
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setData([]);
          setTotalCounts(0);
          setNoDataAvailable(true);
        } else {
          setData(response.data);
          response?.data?.[0]?.totalcount &&
            setTotalCounts(response?.data?.[0]?.totalcount);
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    if (token) {
      GetAllCustomers()
        .then((response) => {
          setCustomers(response?.data);
          response?.data?.map((item) => {
            if (item.customerid == customerid) {
              setSelectedCustomer(item?.customerid);
              setSelectedOption(item?.customername);
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
      // GetFeedbacks({
      //   pageno: 1,
      //   pagesize: pageSize,
      //   search: "",
      //   customer: customerid,
      //   filterdate: selectedFilterdate || "",
      //   ratingby: selectedFilterdata || "",
      // })
      //   .then((response) => {
      //     if (response?.data == (undefined || null)) {
      //       setData([]);
      //       setTotalCounts(0);
      //       setNoDataAvailable(true);
      //     } else {
      //       setData(response.data);
      //       response?.data?.[0]?.totalcount &&
      //         setTotalCounts(response?.data?.[0]?.totalcount);
      //       setNoDataAvailable(false);
      //     }
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
      setCurrentPage(1);
    } else {
      window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
    }
  }, []);

  const fetchData = async (
    searchText = "",
    selectedCustomer = "",
    selectedFilterdate = "",
    selectedFilterdata = "",
    page = 1
  ) => {
    try {
      setIsLoading(true);
      const response = await GetFeedbacks({
        pageno: page,
        pagesize: pageSize,
        search: searchText,
        customer: selectedCustomer,
        filterdate: selectedFilterdate || "",
        ratingby: selectedFilterdata || "",
      });

      if (response?.data == null || response?.data?.length === 0) {
        setHasMoreData(false);
        if (page === 1) {
          setData([]);
          setTotalCounts(0);
          setNoDataAvailable(true);
        }
      } else {
        setData((prevData) =>
          page === 1 ? response?.data : [...prevData, ...response?.data]
        );
        setHasMoreData(response?.data?.length >= pageSize);
        setTotalCounts(response?.data[0]?.totalcount);
        setIsLoading(false);
        setNoDataAvailable(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentPage > 0) {
      fetchData(
        searchText,
        selectedCustomer,
        selectedFilterdate,
        selectedFilterdata,
        currentPage
      );
    }
  }, [currentPage]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      const container = containerRef.current;
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMoreData, isMobile]);

  const handleScroll = () => {
    const container = containerRef.current;
    if (!container || isLoading) return;

    // Adjusting scroll detection for mobile
    const isNearBottom =
      container.scrollHeight - container.scrollTop <=
      container.clientHeight + 5; // Small buffer

    if (isNearBottom && hasMoreData) {
      setIsLoading(true);
      setTimeout(() => {
        setCurrentPage((prevPage) => prevPage + 1);
      }, 500);
    }
  };

  const redirectToAts = (params) => {
    const { atsjobcode, atsid } = params;
    if (atsid) {
      GetAtsUrlByAtsJobcode({
        atsjobcode: atsjobcode,
      })
        .then((response) => {
          window.open(response?.data[0]?.atsurl, "_blank");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
    }
  };

  const redirectToDetail = (analysisid) => {
    navigate(`/candidateresult/${analysisid}`);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    return `${month}-${day}-${year}`;
  }

  function formatDate2(dateStr) {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }
  // Ensure the date string is in a recognized format

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      if (
        secondDropdownRef.current &&
        !secondDropdownRef.current.contains(event.target)
      ) {
        setSecondIsOpen(false);
      }
      if (
        thirdDropdownRef.current &&
        !thirdDropdownRef.current.contains(event.target)
      ) {
        setThirdIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <style>{"body { background-color: #F3F5F9;}"}</style>
        <title> Feedback</title>
        {/* <meta name="description" content="App Description" /> */}
        {/* <meta name="theme-color" content="#008f68" /> */}
      </Helmet>

      {isMobile ? (
        <>
          <Navbar data={activetab} />
          {/* <React.Fragment>
            <div
              style={{
                position: "sticky",
                top: "0",
                bottom: "0",
                background: "#F3F5F9",
              }}
            >
              <div className="col-mobile">
                <p className="pro-mobile">
                  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; Feedback
                </p>
              </div>
            </div>
          </React.Fragment> */}

          <div
            style={{
              margin: "18px 10px 8px 10px",
            }}
          >
            <div class="search-containers">
              <input
                type="text"
                id="search"
                placeholder="Search Job ID, Job Title & Candidate"
                value={searchText}
                onChange={searchTextChange}
                onKeyDown={(event) => {
                  if (event.key === "Enter") btnSearch();
                }}
              />

              {searchText && (
                <button
                  style={{
                    position: "absolute",
                    right: "50px",
                    border: "none",
                    outline: "none",
                    background: "transparent",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={clearSearch}
                >
                  <img
                    src="/images/greenCross1.svg"
                    style={{ width: "10px", height: "10px" }}
                    alt="Clear"
                  />
                </button>
              )}
              <button type="submit" onClick={() => btnSearch()}>
                <img
                  style={{ background: "inherit" }}
                  src="/images/Search Icon.svg"
                />
              </button>
            </div>
          </div>

          <Box
            sx={{ display: "flex", flexDirection: "column", borderBottom: " " }}
          >
            <>
              <div>
                {noDataAvailable ? (
                  <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                    <div className="rounded-full border-gray-500 border-2 flex w-12 h-12 justify-center items-center">
                      <p className="text-[30px] text-gray-500 font-bold">!</p>
                    </div>
                    <p className="text-[20px] text-gray-500 font-bold">
                      Data not available
                    </p>
                  </div>
                ) : (
                  <>
                    <div
                      ref={containerRef}
                      style={{
                        marginBottom: "25px",
                        height: "81vh",
                        overflowY: "auto",
                      }}
                    >
                      {data?.map((item, index) => (
                        <div
                          key={index}
                          className="bg-white rounded-lg p-4 mb-4 shadow-md m-3 "
                          style={{
                            overflowWrap: "anywhere",
                            wordWrap: "break-word",
                          }}
                        >
                          <p className="text-[#1F2E39] text-[15px] font-semibold">
                            {item.customername}
                          </p>
                          <p className="text-[#1F2E39] text-[15px] py-2">
                            {item.candidatename}
                          </p>
                          <div className="flex justify-between">
                            <div className="flex flex-wrap gap-1 items-center">
                              <p className=" text-blue font-bold text-[13px] uppercase">
                                Job ID:
                              </p>
                              <Tooltip
                                title={
                                  item?.atsjobcode.length > 12
                                    ? item.atsjobcode
                                    : ""
                                }
                              >
                                <p
                                  className="text-[#21D6AA] font-semibold cursor-pointer"
                                  onClick={() =>
                                    navigate("/jobdescribtion", {
                                      state: {
                                        jobid: item?.jobid,
                                        jobcode: item?.atsjobcode,
                                      },
                                    })
                                  }
                                >
                                  {item.atsjobcode.length > 12
                                    ? `${item?.atsjobcode.substring(0, 12)}...`
                                    : item?.atsjobcode}
                                </p>
                              </Tooltip>
                            </div>

                            <div className="flex justify-end gap-1 items-center">
                              {/* <p className="  font-bold text-[13px] uppercase">
                    Date:
                  </p> */}
                              <p className="text-[13px] font-semibold cursor-pointer">
                                {formatDate(item?.rating_datetime)}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-wrap items-center justify-between">
                            <p className="text-blue-500 font-medium text-xs uppercase">
                              {item.feedback}
                            </p>
                            <div className="">
                              <a
                                onClick={() =>
                                  navigate("/candidateresult", {
                                    state: item.analysisid,
                                  })
                                }
                                className="text-[#21D6AA] uppercase text-[15px] font-semibold cursor-pointer"
                              >
                                VIEW RESULT
                              </a>
                            </div>
                          </div>
                          <div className="w-full">
                            <text className="text-gray-700 w-full">
                              {item.comment === null ? "-" : item.comment}
                            </text>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
              <div class="pagination-mainstick">
                <div className="sort-filter-mobile" onClick={togglePhoneDrawer}>
                  <p className="px-5">FILTER</p>
                </div>
              </div>

              <Pagination
                canNextPage={currentPage < totalPages}
                canPrevPage={currentPage > 1}
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
              <React.Fragment>
                <Drawer
                  anchor="bottom"
                  open={openPhoneDrawer}
                  onClose={togglePhoneDrawer}
                  transitionDuration={500}
                >
                  <Box
                    sx={{ width: "100%", height: "60%" }}
                    role="presentation"
                    onClick={togglePhoneDrawer}
                  >
                    <div
                      className="flex flex-row justify-end px-5 pt-3 "
                      onClick={() => applyFilter()}
                    >
                      <img
                        src="/images/greenCross1.svg"
                        style={{ width: "15px", height: "15px" }}
                        alt="Clear"
                      />
                    </div>
                    <div className="flex flex-row justify-center text-[20px]  font-bold">
                      Filter By
                    </div>
                    {userType === "A" && customers && (
                      <div
                        className="dropdown__list__parent p-5"
                        style={{ zIndex: 90 }}
                        onClick={(event) => event.stopPropagation()}
                      >
                        <button
                          onClick={toggleDropdown}
                          className={`dropdown__list ${
                            isOpen ? "secondopen" : ""
                          }`}
                        >
                          {selectedOption || "Admin"}
                          {!isOpen ? (
                            <img src="images/custom-arrow.svg" />
                          ) : (
                            <img
                              style={{ transform: "rotate(180deg)" }}
                              src="images/custom-arrow.svg"
                            />
                          )}
                        </button>

                        {isOpen && (
                          <div className="dropdown__list__mobile">
                            <div>
                              {customers &&
                                customers.map((data, i) => (
                                  <p
                                    key={i}
                                    style={{ borderBottom: "1px solid black" }}
                                    onClick={() => handleOptionClick(data)}
                                  >
                                    {data.customername}
                                  </p>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    <div
                      className="dropdown__list__parent_second px-5"
                      style={{ zIndex: 80 }}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <button
                        onClick={toggleSecondDropdown}
                        className={`dropdown__list ${
                          isSecondOpen ? "secondopen" : ""
                        }`}
                      >
                        {filterdate || "All"}
                        {!isSecondOpen ? (
                          <img src="images/custom-arrow.svg" />
                        ) : (
                          <img
                            style={{ transform: "rotate(180deg)" }}
                            src="images/custom-arrow.svg"
                          />
                        )}
                      </button>

                      {isSecondOpen && (
                        <div className="dropdown__list__mobile">
                          <div>
                            {optionData &&
                              optionData.map((data, i) => (
                                <p
                                  key={i}
                                  style={{ borderBottom: "1px solid black" }}
                                  onClick={() => handleSecondOptionClick(data)}
                                >
                                  {data.name}
                                </p>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className="dropdown__list__parent_second px-5"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <button
                        onClick={toggleThirdDropdown}
                        className={`dropdown__list ${
                          isThirdOpen ? "secondopen" : ""
                        }`}
                      >
                        {filterdata || "All"}
                        {!isThirdOpen ? (
                          <img src="images/custom-arrow.svg" />
                        ) : (
                          <img
                            style={{ transform: "rotate(180deg)" }}
                            src="images/custom-arrow.svg"
                          />
                        )}
                      </button>

                      {isThirdOpen && (
                        <div className="dropdown__list__mobile">
                          <div>
                            {SortData &&
                              SortData.map((data, i) => (
                                <p
                                  key={i}
                                  style={{ borderBottom: "1px solid black" }}
                                  onClick={() => handleThirdOptionClick(data)}
                                >
                                  {data.name}
                                </p>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        color: "#21D6AA",
                        fontSize: "14px",
                        fontWeight: "500",
                        marginTop: "170px",
                        paddingLeft: "20px",
                        paddingRight: "0px",
                      }}
                    >
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => clearFilter()}
                      >
                        CLEAR ALL FILTERS
                      </p>
                      <p
                        style={{ marginRight: "20px", cursor: "pointer" }}
                        onClick={() => applyFilter()}
                      >
                        APPLY
                      </p>
                    </div>
                  </Box>
                </Drawer>
              </React.Fragment>
            </>
          </Box>
        </>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Navbar data={activetab} />
          <Box
            className="px-6 pt-4"
            component="main"
            sx={{ flexGrow: 1, height: "100vh" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <HeaderBar title={"Feedback"} toggleDrawer={toggleDrawer} />
              <div className="flex flex-row gap-5 px-3 py-3">
                <div
                  className="search-container"
                  style={{
                    position: "relative",
                    width: "42%",
                  }}
                >
                  <input
                    type="text"
                    id="search"
                    placeholder="Search Client, Candidate & Job ID"
                    style={{
                      border: "none",
                      outline: "none",
                      height: "40px",
                      paddingLeft: "10px",
                      width: "200px",
                      flex: 1,
                    }}
                    value={searchText}
                    onChange={searchTextChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") btnSearch();
                    }}
                  />

                  {searchText && (
                    <button
                      style={{
                        position: "absolute",
                        right: "40px",
                        border: "none",
                        outline: "none",
                        background: "transparent",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={clearSearch}
                    >
                      <img
                        src="/images/greenCross1.svg"
                        style={{ width: "10px", height: "10px" }}
                        alt="Clear"
                      />
                    </button>
                  )}

                  <button
                    style={{
                      border: "none",
                      outline: "none",
                      height: "40px",
                      paddingTop: "9px",
                    }}
                    type="submit"
                    onClick={() => btnSearch()}
                  >
                    <img
                      style={{ background: "inherit" }}
                      src="/images/Search Icon.svg"
                    />
                  </button>
                </div>
                {userType === "A" && customers && (
                  <div
                    className={`dropdown__list__parent `}
                    style={{ width: "23%", zIndex: "99" }}
                    ref={dropdownRef}
                  >
                    <button
                      onClick={toggleDropdown}
                      className={`dropdown__list_second ${
                        isOpen ? "secondopen" : ""
                      }`}
                    >
                      {selectedOption || "Admin"}
                      {!isOpen ? (
                        <img src="images/custom-arrow.svg" />
                      ) : (
                        <img
                          style={{ transform: "rotate(180deg)" }}
                          src="images/custom-arrow.svg"
                        />
                      )}
                    </button>

                    {isOpen && (
                      <div className="dropdown__list__child">
                        <div>
                          {customers &&
                            customers.map((data, i) => (
                              <p
                                key={i}
                                style={{ borderBottom: "1px solid black" }}
                                onClick={() => handleOptionClick(data)}
                              >
                                {data?.customername}
                              </p>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div
                  className="relative w-[21%] z-[99]"
                  ref={secondDropdownRef}
                >
                  <button
                    onClick={toggleSecondDropdown}
                    className={`dropdown__list_second ${
                      isSecondOpen ? "secondopen" : ""
                    }`}
                  >
                    {filterdate || "All"}
                    {!isSecondOpen ? (
                      <img src="images/custom-arrow.svg" />
                    ) : (
                      <img
                        style={{ transform: "rotate(180deg)" }}
                        src="images/custom-arrow.svg"
                      />
                    )}
                  </button>

                  {isSecondOpen && (
                    <div className="dropdown__list__child_second">
                      <div>
                        {optionData &&
                          optionData.map((data, i) => (
                            <p
                              key={i}
                              style={{ borderBottom: "1px solid black" }}
                              onClick={() => handleSecondOptionClick(data)}
                            >
                              {data?.name}
                            </p>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="relative w-[20%] z-[99]" ref={thirdDropdownRef}>
                  <button
                    onClick={toggleThirdDropdown}
                    className={`dropdown__list_second ${
                      isSecondOpen ? "secondopen" : ""
                    }`}
                  >
                    {filterdata || "All"}
                    {!isThirdOpen ? (
                      <img src="images/custom-arrow.svg" />
                    ) : (
                      <img
                        style={{ transform: "rotate(180deg)" }}
                        src="images/custom-arrow.svg"
                      />
                    )}
                  </button>

                  {isThirdOpen && (
                    <div className="dropdown__list__child_second">
                      <div>
                        {SortData &&
                          SortData.map((data, i) => (
                            <p
                              key={i}
                              style={{ borderBottom: "1px solid black" }}
                              onClick={() => handleThirdOptionClick(data)}
                            >
                              {data?.name}
                            </p>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex items-center cursor-pointer w-[14%]">
                  <p
                    className=" bg-[#21D6AA] px-6 py-2.5 rounded-md text-white  text-sm font-medium"
                    onClick={() => applyFilter()}
                  >
                    Apply
                  </p>
                </div>
              </div>
              {noDataAvailable ? (
                <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                  <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                    <p className="text-[30px] text-gray-500 font-bold">!</p>
                  </div>
                  <p className="text-[20px] text-gray-500 font-bold">
                    Data not available
                  </p>
                </div>
              ) : (
                <div className="mx-3 shadow-md ">
                  <div className="sticky z-10 top-0 bg-white border-b border-[#E0E0E0]">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="w-[105px] px-5 py-4 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                            Date
                          </th>
                          <th className="w-[130px] px-5 py-4 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                            Client
                          </th>
                          <th className="w-[160px] px-5 py-4 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                            Candidate
                          </th>
                          <th className="w-[160px] px-5 py-4 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                            Job&nbsp;ID
                          </th>
                          <th className="w-[130px] px-5 py-4 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                            Feedback
                          </th>
                          <th className="w-[180px] px-5 py-4 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                            Comment
                          </th>
                          <th className="px-5 py-4 text-center font-medium text-blue text-[16px] uppercase tracking-wider w-[100px]">
                            RESULT
                          </th>
                          {data?.length > 6 && (
                            <th className=" w-[10px]">{}</th>
                          )}
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              )}
              <div
                ref={containerRef}
                className="mx-3 1538:max-h-[520px] max-h-[calc(100vh-230px)] overflow-auto shadow-md "
                style={{ scrollbarWidth: "thin" }}
              >
                <div className="">
                  <table className="min-w-full divide-y divide-gray-200">
                    <tbody className="bg-white divide-y divide-gray-200">
                      {data?.map((item, index) => (
                        <tr
                          key={index}
                          className={`${
                            index % 2 === 0
                              ? "bg-[#EDF4FF] border-none"
                              : "bg-white border-none"
                          }`}
                        >
                          <td className="w-[105px] px-5 py-4  text-sm text-gray-700 ">
                            {formatDate(item?.rating_datetime)}
                          </td>
                          <td className="w-[130px] px-5 py-4  text-sm text-gray-700 ">
                            <Tooltip
                              title={
                                item.customername.length > 14
                                  ? item.customername
                                  : ""
                              }
                            >
                              {truncateString(item.customername, 14)}
                            </Tooltip>
                          </td>
                          <td className="px-5 py-4  text-sm text-gray-700 w-[160px]">
                            <Tooltip
                              title={
                                item.candidatename.length > 12
                                  ? item.candidatename
                                  : ""
                              }
                            >
                              {truncateString(item.candidatename, 12)}
                            </Tooltip>
                          </td>

                          <td
                            className="px-5 py-4 text-sm cursor-pointer text-left  text-[#21D6AA] font-semibold w-[160px]"
                            onClick={() =>
                              navigate("/jobdescribtion", {
                                state: {
                                  jobid: item?.jobid,
                                  jobcode: item?.atsjobcode,
                                },
                              })
                            }
                          >
                            <Tooltip
                              title={
                                item?.atsjobcode.length > 10
                                  ? item.atsjobcode
                                  : ""
                              }
                            >
                              {item?.atsjobcode.length > 10
                                ? `${item?.atsjobcode.substring(0, 10)}...`
                                : item?.atsjobcode}
                            </Tooltip>
                          </td>

                          <td className="px-5 py-4 text-sm text-gray-700 w-[130px]">
                            {item.feedback}
                          </td>
                          <td className="px-5  py-4 text-sm  text-gray-700 w-[180px]">
                            {item.comment === null ? (
                              "-"
                            ) : (
                              <Tooltip
                                title={
                                  item.comment.length > 15 ? item.comment : ""
                                }
                              >
                                {truncateString(item.comment, 15)}
                              </Tooltip>
                            )}
                          </td>
                          <td className="px-5 py-4   text-center  text-sm text-gray-700 w-[100px] ">
                            <a
                              onClick={() =>
                                navigate("/candidateresult", {
                                  state: item.analysisid,
                                })
                              }
                              className="text-[#21D6AA]  w-[100px] uppercase text-[15px] font-semibold cursor-pointer"
                            >
                              view
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* {noDataAvailable ? (
              ""
            ) : (
              <Pagination
                canNextPage={currentPage < totalPages}
                canPrevPage={currentPage > 1}
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
            )} */}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Feedback;
