import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { storage } from "../../libs/localStorage";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center", 
  },

  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "300px",
    maxHeight: "260px",
  },
}));

export default function StdResumeListPopup(props) {
  const { open, setOpen,templatedata ,data} = props;
  const { retrieveToken } = storage();
  const navigate = useNavigate();
  const handleClose = (event, reason) => {
 
      setOpen(false);
      
  };

  const token = retrieveToken();

  const btnStdTemplete = (templateid) => {
    console.log("TemplateID",templateid)
    if (data?.standardizedcvjson) {
      window.open(
        `${process.env.REACT_APP_API_BASE_URL}/api/downloadstandardizecv?token=${token}&analysisid=${data?.analysisid}&templateid=${templateid}`,
        "_blank"
      );
    }
    handleClose()
  };

  return (
    <React.Fragment>
      <BootstrapDialog
      sx={{  padding:0}}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        BackdropProps={{
            onClick: handleClose, // Close the dialog when clicking outside
          }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 4,
            color: "green",
          }}
        >
          <img
            style={{ width: "15px", height: "15px" }}
            src="/images/Path 1670.svg"
          />
        </IconButton>
        <DialogContent style={{padding:"0px"}}>
          <Typography  style={{ font: "normal normal bold 20px/23px Rubik" , marginTop:"20px", marginBottom:"20px"}}>
            Select Templete 
          </Typography>
           {templatedata?.map((item,index) =>
           <>
           <div className={`w-full  bg-[${index % 2 === 0 ? "#EDF4FF" : "#ffffff"}]`} >
          <Typography
            gutterBottom
            style={{  font: "normal normal medium 16px/23px Rubik", color:"#1F2E39" , marginTop:"6px", marginLeft:"14px",   cursor:"pointer"   }}
            onClick={() => btnStdTemplete(item?.templateid)}
          >
           {item?.templatename}
          </Typography>
         
          {/* <hr  color="black" className="w-full h-[0.8px] my-1" /> */}
          </div>
          
          </>
          )}
         
         
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
