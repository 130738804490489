import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .MuiPaper-root": {
    width: "300px",
    maxHeight: "460px",
  },
}));

export default function VerifyPasswordPopup(props) {
  const { handleSaveChanges, handleClosePopup, isPopupOpen } = props;
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    setPassword("");
    setErrorMessage("");
    setIsPasswordVisible(false);
  }, [isPopupOpen]);

  const validatePassword = (value) => {
    if (!value) {
      return "Password is required.";
    }
    return "";
  };
  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };
  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    const validationError = validatePassword(value);
    setErrorMessage(validationError);
  };

  const handleSave = () => {
    const validationError = validatePassword(password);
    if (validationError) {
      setErrorMessage(validationError);
    } else {
      setErrorMessage("");
      handleSaveChanges(password);
    }
  };

  const handleCancel = () => {    
    handleClosePopup();
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClosePopup}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
      >
        <DialogContent>
          <div className="w-full flex flex-raw justify-between pb-5">
            <Typography>
              <p className="text-left pl-3 font-bold">
                Enter password to continue{" "}
              </p>
            </Typography>
            <div onClick={handleCancel}>
              <img
                className="w-[15px] h-[15px] cursor-pointer"
                src="/images/Path 1670.svg"
                alt=""
              />
            </div>
          </div>
          <Typography className="px-3 w-full font-normal text-[15px] text-gray-700">
            <div>
              <div className="relative w-full">
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                  className="block w-full h-10 border border-gray-400 rounded-md bg-white px-2 mb-2 text-gray-700 pr-10"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute top-2.5 right-3"
                >
                  <img
                    src={
                      isPasswordVisible
                        ? "/images/open-eye.svg"
                        : "/images/close-eye.svg"
                    }
                    alt="toggle visibility"
                    className="w-5 h-5"
                  />
                </button>
              </div>

              {errorMessage && <p className="text-red-500">{errorMessage}</p>}
              <div className="flex w-full justify-between">
                <button
                  type="button"
                  className="w-[100px] h-[35px] mt-2 bg-green-500 rounded-md text-black font-medium "
                  onClick={handleSave}
                  // disabled={!!errorMessage}
                >
                  Verify
                </button>
              </div>
            </div>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
