import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { storage } from "../../libs/localStorage";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useMediaQuery } from "@mui/material";
import "./Overview.css";
import { Tooltip } from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";
import { useJobsData } from "../../data/jobsdata";
import { useNavigate } from "react-router-dom";
import { useCandidateData } from "../../data/candidatedata";
import { commonData } from "../../data/commondata";

const optionData = [
  {
    id: "all",
    name: "All time",
  },
  {
    id: "7d",
    name: "Last 7 Days",
  },
  {
    id: "30d",
    name: "Last 30 Days",
  },
  {
    id: "90d",
    name: "Last 90 Days",
  },
  {
    id: "ty",
    name: "This Year",
  },
  {
    id: "ly",
    name: "Last Year",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    fontFamily: "Rubik, sans-serif",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Rubik, sans-serif",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#EDF4FF",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#ffffff",
  },
}));

export default function Overview() {

  const { storeToken, retrieveToken, clearToken } = storage();


  const token = retrieveToken();
  const customerid = Number(localStorage.getItem("customerid"));
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const userType = localStorage.getItem("usertype");
  const [latestProcessJobs, setLatestProcessJobs] = useState(null);
  const [latestResumes, setLatestResumes] = useState(null);
  const [overviewCounts, setOverviewCounts] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [filterdate, setFilterdate] = useState("All time");
  const [selectedFilterdate, setSelectedFilterdate] = useState("all");
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(customerid);
  const navigate = useNavigate();
  const { GetAtsUrlByAtsJobcode } = useJobsData();
  const { GetOverviewCounts, GetAllCustomers } = commonData();
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleCustomerClick = (option) => {
    setSelectedOption(option?.customername);
    setSelectedCustomer(option?.customerid);
    toggleDropdown();
  };

  // const redirectToDetail = (analysisid) => {
  //   navigate(`/candidateresult/${analysisid}`);
  // };

  const btnViewMore = (params) => {
    navigate("/processedcandidate", { state: params });
  };

  /*second dropdown */
  const [isSecondOpen, setSecondIsOpen] = useState(false);
  const toggleSecondDropdown = () => {
    setSecondIsOpen((prev) => !prev);
  };
  const handleSecondOptionClick = (Secondoption) => {
    setSelectedFilterdate(Secondoption.id);
    setFilterdate(Secondoption.name);
    toggleSecondDropdown();
  };

  const redirectToAts = (params) => {
    const { atsjobcode, atsid } = params;
    if (atsid) {
      GetAtsUrlByAtsJobcode({
        atsjobcode: atsjobcode,
      })
        .then((response) => {
          window.open(response?.data[0]?.atsurl, "_blank");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
    }
  };

  //mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const activetab = "overview";
  const { GetLatestProcessedJobs } = useJobsData();
  const { GetLatestResumes } = useCandidateData();

  const loadData = () => {
    GetLatestProcessedJobs({
      customer: selectedCustomer,
      filterdate: selectedFilterdate || "",
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setLatestProcessJobs([]);
          setNoDataAvailable(true);
        } else {
          setLatestProcessJobs(response?.data);
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    GetLatestResumes({
      customer: selectedCustomer,
      filterdate: selectedFilterdate || "",
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setLatestResumes([]);
          setNoDataAvailable(true);
        } else {
          setLatestResumes(response?.data);
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    GetOverviewCounts({
      customer: selectedCustomer,
      filterdate: selectedFilterdate || "",
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setOverviewCounts(null);
          setNoDataAvailable(true);
        } else {
          setOverviewCounts(response?.data[0]);
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const dropdownRef = useRef(null);
  const secondDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      if (
        secondDropdownRef.current &&
        !secondDropdownRef.current.contains(event.target)
      ) {
        setSecondIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedCustomer || selectedFilterdate) {
      loadData();
    }
  }, [selectedCustomer, selectedFilterdate]);

  useEffect(() => {
    if (token) {
      GetAllCustomers()
        .then((response) => {
          setCustomers(response?.data);
          response?.data?.map((item) => {
            if (item.customerid == customerid) {
              setSelectedCustomer(item?.customerid);
              setSelectedOption(item?.customername);
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });


    } else {
      window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
    }
  }, []);

  return (
    <>
      <Helmet>
        <style>{"body { background-color: #F3F5F9;}"}</style>
        <title> Overview</title>
      </Helmet>
      {/* <Navbar data={activetab} /> */}
      {isMobile ? (
        <React.Fragment>
          <Navbar data={activetab} />
          {/* <div style={{position:'sticky',top:'0',zIndex:'2',background:'white',paddingBottom:'1px'}}> */}
          {/* <div className='col-mobile'>
            <p className='pro-mobile'>
            &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              Overview
            </p>
            </div> */}

          {/*first dropdown */}
          <div className="bg-white pb-1 pt-1 ">
            {userType == "A" && (
              <div
                style={{ margin: "10px" }}
                className="dropdown__list__parent"
              >
                <button
                  onClick={toggleDropdown}
                  className={`dropdown__list ${isOpen ? "open" : ""}`}
                >
                  {selectedOption || "All"}
                  {!isOpen ? (
                    <img src="images/custom-arrow.svg" alt="Dropdown Arrow" />
                  ) : (
                    <img
                      style={{ transform: "rotate(180deg)" }}
                      src="images/custom-arrow.svg"
                      alt="Dropdown Arrow"
                    />
                  )}
                </button>

                {isOpen && (
                  <div className="dropdown__list__child">
                    <div>
                      {customers &&
                        customers.map((data, i) => (
                          <p
                            key={i}
                            style={{ borderBottom: "1px solid black" }}
                            onClick={() => handleCustomerClick(data)}
                          >
                            {data?.customername}
                          </p>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* second dropdown */}
          <div className="bg-white pb-0.5 pt-1">
            <div
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                marginBottom: "20px",
              }}
              className="dropdown__list__parent_second"
            >
              <button
                onClick={toggleSecondDropdown}
                className={`dropdown__list_second ${isSecondOpen ? "secondopen" : ""
                  }`}
              >
                {filterdate || "All time"}
                {!isSecondOpen ? (
                  <img src="images/custom-arrow.svg" />
                ) : (
                  <img
                    style={{ transform: "rotate(180deg)" }}
                    src="images/custom-arrow.svg"
                  />
                )}
              </button>

              {isSecondOpen && (
                <div className="dropdown__list__child_second">
                  <div>
                    {optionData &&
                      optionData.map((data, i) => (
                        <p
                          key={i}
                          style={{ borderBottom: "1px solid black" }}
                          onClick={() => handleSecondOptionClick(data)}
                        >
                          {data?.name}
                        </p>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* </div> */}

          {/*4 boxes*/}

          <div
            class="container-boxxxes"
            style={{ margin: "20px 10px 20px 10px" }}
          >
            <p class="container-boxees-child">
              <div>
                <p class="container-boxees-child1">PROCESSED JOBS</p>
              </div>
              <div class="container-boxees-child2">
                <text class="container-boxees-child2-1">
                  {overviewCounts == null ? 0 : overviewCounts?.processedjobs}
                </text>
                <text class="container-boxees-child2-2">
                  Out of{" "}
                  {overviewCounts == null ? 0 : overviewCounts?.totaljobs}
                </text>
              </div>
            </p>

            <button onClick={() => navigate("/processedjobs")}>
              <p class="see-more">
                <p>SEE</p>
                <p>MORE</p>
              </p>
            </button>
          </div>

          <div class="container-boxxxes" style={{ margin: "0 10px 20px 10px" }}>
            <p class="container-boxees-child">
              <div>
                <p class="container-boxees-child1">FEEDBACKS RECEIVED</p>
              </div>
              <div class="container-boxees-child2">
                <p class="container-boxees-child2-1">
                  {overviewCounts == null ? 0 : overviewCounts?.receivedrating}
                </p>
                <p class="container-boxees-child2-2">
                  Out of{" "}
                  {overviewCounts == null ? 0 : overviewCounts?.totalrating}
                </p>
              </div>
            </p>

            <button onClick={() => navigate("/feedback")}>
              <p class="see-more">
                <p>SEE</p>
                <p>MORE</p>
              </p>
            </button>
          </div>

          <div class="container-boxxxes" style={{ margin: "0 10px 20px 10px" }}>
            <p class="container-boxees-child">
              <div>
                <p class="container-boxees-child1">PROCESSED RESUMES</p>
              </div>
              <div class="container-boxees-child2">
                <p class="container-boxees-child2-1">
                  {overviewCounts == null
                    ? 0
                    : overviewCounts?.processedresumes}
                </p>
                <p class="container-boxees-child2-2">
                  Out of{" "}
                  {overviewCounts == null ? 0 : overviewCounts?.totalresumes}
                </p>
              </div>
            </p>

            <button onClick={() => navigate("/processedjobs")}>
              <p class="see-more">
                <p>SEE</p>
                <p>MORE</p>
              </p>
            </button>
          </div>

          <div
            class="container-boxxxes"
            style={{ margin: "0px 10px 20px 10px" }}
          >
            <p class="container-boxees-child" style={{ borderRadius: "5px" }}>
              <div>
                <p class="container-boxees-child1">API COST</p>
              </div>
              <div class="container-boxees-child2">
                <p class="container-boxees-child2-1">
                  ${overviewCounts == null ? 0 : overviewCounts?.apicost}
                </p>
                <p class="container-boxees-child2-2">
                  Out of $
                  {overviewCounts == null ? 0 : overviewCounts?.totalcost}
                </p>
              </div>
            </p>
          </div>

          {/*table 1 */}

          <div style={{ margin: "10px 10px" }}>
            <div>
              <p
                style={{
                  color: "#1F2E39",
                  fontWeight: "800",
                  fontSize: "18px",
                  background: "white",
                  padding: "10px",
                  borderRadius: "5px 5px 0 0",
                }}
              >
                Latest Processed Jobs
              </p>

              {noDataAvailable ? (
                <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                  <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                    <p className="text-[30px] text-gray-500 font-bold">!</p>
                  </div>
                  <p className="text-[20px] text-gray-500 font-bold">
                    Data not available
                  </p>
                </div>
              ) : (
                latestProcessJobs &&
                latestProcessJobs.map((data, i) => (
                  <div
                    key={i}
                    className={`${i % 2 === 0
                      ? "bg-[#EDF4FF] border-none"
                      : "bg-white border-none"
                      }`}
                  >
                    <div style={{ padding: "5px 5px" }} key={i}>
                      <p
                        style={{
                          color: "#21D6AA",
                          fontSize: "15px",
                          fontWeight: "600",
                          paddingTop: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip
                          title={
                            data?.atsjobcode.length > 12 ? data.atsjobcode : ""
                          }
                          onClick={() =>
                            navigate("/jobdescribtion",
                              {
                                state: {
                                  jobid: data?.jobid,
                                  jobcode: data?.atsjobcode,
                                }
                              })
                          }
                        >
                          ATS JOB ID:{" "}
                          {data.atsjobcode.length > 12
                            ? `${data.atsjobcode.substring(0, 12)}...`
                            : data.atsjobcode}
                        </Tooltip>
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ color: "#1F2E39" }}>
                          <p style={{ margin: "5px 0" }}>{data.jobtitle}</p>
                          <p style={{ margin: "0" }}>{data.customername}</p>
                        </div>
                        <div>
                          <p
                            style={{
                              color: "#708EA4",
                              fontSize: "12px",
                              margin: "5px 0",
                            }}
                          >
                            {data.resumeCount} Resumes
                          </p>
                          <p
                            onClick={() => btnViewMore(data)}
                            style={{
                              color: "#21D6AA",
                              fontSize: "14px",
                              fontWeight: "600",
                              textAlign: "end",
                              cursor: "pointer",
                            }}
                          >
                            VIEW
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>

            {/*table 2 */}

            <div style={{ marginTop: "20px" }}>
              <p
                style={{
                  color: "#1F2E39",
                  fontWeight: "800",
                  fontSize: "18px",
                  background: "white",
                  padding: "10px",
                  borderRadius: "5px 5px 0 0",
                }}
              >
                Latest Processed Resumes
              </p>

              {noDataAvailable ? (
                <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                  <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                    <p className="text-[30px] text-gray-500 font-bold">!</p>
                  </div>
                  <p className="text-[20px] text-gray-500 font-bold">
                    Data not available
                  </p>
                </div>
              ) : (
                latestResumes &&
                latestResumes.map((data, i) => (
                  <div
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? "#EDF4FF" : "white",
                      border: "none",
                    }}
                  >
                    <div style={{ padding: "5px" }}>
                      <div style={{ color: "#1F2E39" }}>
                        <p style={{ margin: "5px 0", fontWeight: "500" }}>
                          {data.candidatename}
                        </p>
                        <p style={{ margin: "0 0 5px 0" }}>
                          {data.current_job_title}
                        </p>
                      </div>
                      <p
                        style={{
                          color: "#21D6AA",
                          fontSize: "15px",
                          fontWeight: "600",
                          paddingBottom: "5px",
                          marginTop: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate("/candidateresult", {
                            state: data.analysisid,
                          })
                        }
                      >
                        VIEW SUMMARY
                      </p>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          {/* <Navbar data={activetab} /> */}
        </React.Fragment>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Navbar data={activetab} />
          <Box component="main" sx={{ flexGrow: 1, p: 3, height: "100vh" }}>
            <div class="container-boxxxes-row-main">
              <div class="container-boxxxes-row">
                <div class="container-boxxxes-column">
                  <div
                    className="dropdown__list__parent_second"
                    ref={secondDropdownRef}
                  >
                    <button
                      onClick={toggleSecondDropdown}
                      className={`dropdown__list_second ${isSecondOpen ? "secondopen" : ""
                        }`}
                    >
                      {filterdate || "All time"}
                      {!isSecondOpen ? (
                        <img src="images/custom-arrow.svg" />
                      ) : (
                        <img
                          style={{ transform: "rotate(180deg)" }}
                          src="images/custom-arrow.svg"
                        />
                      )}
                    </button>

                    {isSecondOpen && (
                      <div className="dropdown__list__child_second">
                        <div>
                          {optionData &&
                            optionData.map((data, i) => (
                              <p
                                key={i}
                                style={{ borderBottom: "1px solid black" }}
                                onClick={() => handleSecondOptionClick(data)}
                              >
                                {data?.name}
                              </p>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div
                    className="dropdown__list__parent mb-5"
                    ref={dropdownRef}
                  >
                    <button
                      onClick={toggleDropdown}
                      className={`dropdown__list ${isOpen ? "secondopen" : ""}`}
                    >
                      {selectedOption || "Admin"}
                      {!isOpen ? (
                        <img src="images/custom-arrow.svg" />
                      ) : (
                        <img
                          style={{ transform: "rotate(180deg)" }}
                          src="images/custom-arrow.svg"
                        />
                      )}
                    </button>

                    {isOpen && (
                      <div className="dropdown__list__child">
                        <div>
                          {customers &&
                            customers.map((data, i) => (
                              <p
                                key={i}
                                style={{ borderBottom: "1px solid black" }}
                                onClick={() => handleCustomerClick(data)}
                              >
                                {data?.customername}
                              </p>
                            ))}
                        </div>
                      </div>
                    )}
                  </div> */}

                  <div class="container-boxxxes">
                    <p class="container-boxees-child">
                      <div>
                        <p class="container-boxees-child1">PROCESSED JOBS</p>
                      </div>
                      <div class="container-boxees-child2">
                        <p class="container-boxees-child2-1">
                          {overviewCounts == null
                            ? 0
                            : overviewCounts?.processedjobs}
                        </p>
                        <p class="container-boxees-child2-2">
                          Out of{" "}
                          {overviewCounts == null
                            ? 0
                            : overviewCounts?.totaljobs}
                        </p>
                      </div>
                    </p>

                    <button onClick={() => navigate("/processedjobs")}>
                      <p class="see-more">
                        <p>SEE</p>
                        <p>MORE</p>
                      </p>
                    </button>
                  </div>
                </div>

                <div class="container-boxxxes-column">
                  <div class="container-boxxxes">
                    <p class="container-boxees-child">
                      <div>
                        <p class="container-boxees-child1">
                          FEEDBACKS RECEIVED
                        </p>
                      </div>
                      <div class="container-boxees-child2">
                        <p class="container-boxees-child2-1">
                          {overviewCounts == null
                            ? 0
                            : overviewCounts?.receivedrating}
                        </p>
                        <p class="container-boxees-child2-2">
                          Out of{" "}
                          {overviewCounts == null
                            ? 0
                            : overviewCounts?.totalrating}
                        </p>
                      </div>
                    </p>

                    <button onClick={() => navigate("/feedback")}>
                      <p class="see-more">
                        <p>SEE</p>
                        <p>MORE</p>
                      </p>
                    </button>
                  </div>
                </div>

                <div className=" shadow-md shadow-white">
                  <div className="">
                    <div className="relative top-0  bg-white ">
                      <table className="min-w-full divide-y divide-gray-200 ">
                        <thead className="bg-gray-50">
                          <tr>
                            <td
                              colSpan={4}
                              className="pl-[20px] pt-[10px] border-0 text-black text-[18px] font-bold"
                            >
                              Latest Processed Jobs
                            </td>
                          </tr>

                          {noDataAvailable ? (
                            <div className="flex justify-center w-full h-[44vh] flex-col gap-2 items-center">
                              <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                                <p className="text-[30px] text-gray-500 font-bold">
                                  !
                                </p>
                              </div>
                              <p className="text-[20px] text-gray-500 font-bold">
                                Data not available
                              </p>
                            </div>
                          ) : (
                            <tr>
                              <th className="w-[130px] pl-4 py-5 text-left font-medium text-blue text-[15px] uppercase tracking-wider">
                                JOB ID
                              </th>
                              <th className="w-[185px] py-5 text-left font-medium text-blue text-[15px] uppercase tracking-wider">
                                JOB TITLE
                              </th>
                              <th className="w-[140px]  py-5 text-left font-medium text-blue text-[15px] uppercase tracking-wider">
                                CLIENT
                              </th>
                              <th className="w-[80px]  py-5 text-left font-medium text-blue text-[15px] uppercase tracking-wider"></th>
                            </tr>
                          )}
                        </thead>
                      </table>
                    </div>
                  </div>

                  <div
                    className={`shadow-md  ${overviewCounts == null
                      ? ""
                      : " overflow-auto h-[calc(100vh-443px)]"
                      } `}
                    style={{ scrollbarWidth: "none" }}
                  >
                    <div className="">
                      <table className="min-w-full divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200">
                          {latestProcessJobs &&
                            latestProcessJobs.map((data, i) => (
                              <tr
                                key={i}
                                className={`${i % 2 === 0
                                  ? "bg-[#EDF4FF] border-none"
                                  : "bg-white border-none"
                                  }`}
                              >
                                {/*   <td className="w-[100px] px-5 py-4">
                            <p  className="custoBr:text-[13px] text-[15px] font-semibold text-primary  cursor-pointer">{data?.atsjobcode.length > 10 ? `${data?.atsjobcode.substring(0, 10)}...` : data?.atsjobcode}</p>
                          </td>*/}
                                <td className="w-[130px] pl-4 py-4">
                                  <p
                                    onClick={() =>
                                      navigate("/jobdescribtion",
                                        {
                                          state: {
                                            jobid: data?.jobid,
                                            jobcode: data?.atsjobcode,
                                          }
                                        })
                                    }
                                    className="custoBr:text-[13px] text-[15px] font-semibold text-gray-700  cursor-pointer"
                                  >
                                    <Tooltip
                                      title={
                                        data?.atsjobcode?.length > 7
                                          ? data?.atsjobcode
                                          : ""
                                      }
                                    >
                                      {data?.atsjobcode?.length > 7
                                        ? `${data?.atsjobcode?.substring(
                                          0,
                                          7
                                        )}...`
                                        : data?.atsjobcode}
                                    </Tooltip>
                                  </p>
                                </td>
                                <td className=" py-4  text-sm w-[185px]">
                                  <p className="custoBr:text-[13px] text-[15px] font-normal text-black">
                                    {data?.jobtitle?.length > 14
                                      ? `${data?.jobtitle?.substring(0, 14)}...`
                                      : data?.jobtitle}
                                  </p>
                                  <p className="text-[10px] text-[#708EA4]">
                                    {data?.countofresumes} Resumes{" "}
                                    {data?.totalresumes}
                                  </p>
                                </td>

                                <td className=" py-4 w-[140px]">
                                  <p className="custoBr:text-[13px] text-[15px] font-normal text-black">
                                    {data?.customername?.length > 14
                                      ? `${data?.customername.substring(
                                        0,
                                        14
                                      )}...`
                                      : data?.customername}
                                  </p>
                                </td>
                                <td className="px-2 py-4 w-[80px]">
                                  <p
                                    onClick={() => btnViewMore(data)}
                                    className="custoBr:text-[13px] text-[15px] font-semibold  cursor-pointer"
                                  >
                                    <img
                                      className="w-12"
                                      src="/images/viewdetails.svg"
                                      alt="Button 1"
                                    />
                                  </p>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container-boxxxes-row">
                <div class="container-boxxxes-column">
                  <div
                    className={`dropdown__list__parent mb-5 ${userType == "A" ? "" : "h-10"
                      }`}
                    ref={dropdownRef}
                  >
                    {userType == "A" && (
                      <button
                        onClick={toggleDropdown}
                        className={`dropdown__list ${isOpen ? "secondopen" : ""
                          }`}
                      >
                        {selectedOption || "Admin"}
                        {!isOpen ? (
                          <img src="images/custom-arrow.svg" />
                        ) : (
                          <img
                            style={{ transform: "rotate(180deg)" }}
                            src="images/custom-arrow.svg"
                          />
                        )}
                      </button>
                    )}
                    {isOpen && (
                      <div className="dropdown__list__child">
                        <div>
                          {customers &&
                            customers.map((data, i) => (
                              <p
                                key={i}
                                style={{ borderBottom: "1px solid black" }}
                                onClick={() => handleCustomerClick(data)}
                              >
                                {data?.customername}
                              </p>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>

                  <div class="container-boxxxes">
                    <p class="container-boxees-child">
                      <div>
                        <p class="container-boxees-child1">PROCESSED RESUMES</p>
                      </div>
                      <div class="container-boxees-child2">
                        <p class="container-boxees-child2-1">
                          {overviewCounts == null
                            ? 0
                            : overviewCounts?.processedresumes}
                        </p>
                        <p class="container-boxees-child2-2">
                          Out of{" "}
                          {overviewCounts == null
                            ? 0
                            : overviewCounts?.totalresumes}
                        </p>
                      </div>
                    </p>

                    <button onClick={() => navigate("/processedjobs")}>
                      <p class="see-more">
                        <p>SEE</p>
                        <p>MORE</p>
                      </p>
                    </button>
                  </div>
                </div>

                <div class="container-boxxxes-column">
                  <div class="container-boxxxes">
                    <p
                      class="container-boxees-child"
                      style={{ borderRadius: "5px" }}
                    >
                      <div>
                        <p class="container-boxees-child1">API COST</p>
                      </div>
                      <div class="container-boxees-child2">
                        <p class="container-boxees-child2-1">
                          $
                          {overviewCounts == null ? 0 : overviewCounts?.apicost}
                        </p>
                        <p class="container-boxees-child2-2">
                          Out of $
                          {overviewCounts == null
                            ? 0
                            : overviewCounts?.totalcost}
                        </p>
                      </div>
                    </p>
                  </div>
                </div>

                <div className=" border-none">
                  <div>
                    <div className="relative top-0 bg-white ">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <td
                              colSpan={4}
                              className="pl-[20px] pt-[10px]  text-black text-[18px] font-bold"
                            >
                              Latest Processed Resumes
                            </td>
                          </tr>
                          {noDataAvailable ? (
                            <div className="flex justify-center w-full  h-[44vh] flex-col gap-2 items-center">
                              <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                                <p className="text-[30px] text-gray-500 font-bold">
                                  !
                                </p>
                              </div>
                              <p className="text-[20px] text-gray-500 font-bold">
                                Data not available
                              </p>
                            </div>
                          ) : (
                            <tr>
                              <th className="w-[150px] px-5 py-5 text-left font-medium text-blue text-[15px] uppercase tracking-wider">
                                CANDIDATE
                              </th>
                              <th className="w-[170px] px-5 py-5 text-left font-medium text-blue text-[15px] uppercase tracking-wider">
                                JD JOB TITLE
                              </th>
                              <th className="w-[130px] px-5 py-5 text-left font-medium text-blue text-[15px] uppercase tracking-wider">
                                SUMMARY
                              </th>
                            </tr>
                          )}
                        </thead>
                      </table>
                    </div>
                  </div>

                  <div
                    className={`  ${overviewCounts == null
                      ? ""
                      : " overflow-auto h-[calc(100vh-443px)]"
                      } `}
                    style={{ scrollbarWidth: "none" }}
                  >
                    <div className="">
                      <table className="min-w-full divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200">
                          {latestResumes &&
                            latestResumes.map((data, i) => (
                              <tr
                                key={i}
                                className={`${i % 2 === 0
                                  ? "bg-[#EDF4FF] border-none"
                                  : "bg-white border-none"
                                  }`}
                              >
                                <td className="w-[160px] px-5 py-[24.5px]">
                                  <p className="text-[15px] font-medium text-black">
                                    {data?.candidatename.length > 12
                                      ? `${data?.candidatename.substring(
                                        0,
                                        12
                                      )}...`
                                      : data?.candidatename}
                                  </p>
                                </td>

                                <td className="px-5 py-4  text-sm w-[190px]">
                                  <p className="text-[15px] font-normal text-black">
                                    {data?.current_job_title.length > 18
                                      ? `${data?.current_job_title.substring(
                                        0,
                                        18
                                      )}...`
                                      : data?.current_job_title}
                                  </p>
                                </td>

                                <td className="px-5 py-4 w-[130px]">
                                  <p
                                    onClick={() =>
                                      navigate("/candidateresult", {
                                        state: data.analysisid,
                                      })
                                    }
                                    className="text-[15px] font-semibold text-primary cursor-pointer"
                                  >
                                    VIEW
                                  </p>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      )}
    </>
  );
}
