import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useCandidateData } from "../../data/candidatedata";
import MarkFavorite from "../../components/MarkFavorite/MarkFavorite";
import { toast, ToastContainer } from "react-toastify";
import { Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { storage } from "../../libs/localStorage";
import { Helmet } from "react-helmet";
import HeaderBar from "../../components/HeaderBar/HeaderBar";


const Screening = () => {
    const [toggleView, setToggleView] = useState(false);
    const [detailsView, setDetailsView] = useState(false);
    const [searchText, setSearchText] = useState("");
    const location = useLocation();
    const { GetCustomerById } = useCandidateData();
    const params = location.state;
    const { retrieveToken } = storage();
    const [data, setData] = useState({});
    const navigate = useNavigate();
    const activetab = "ScreeningList";
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("820"));
    const token = retrieveToken();
    const [isSecondOpen, setSecondIsOpen] = useState(false);
    const [isthirdOpen, setthirdIsOpen] = useState(false);
    const [CvStatusfilterdata, setCvStatusFilterdata] = useState("Status");
    const [filterdata, setFilterdata] = useState("Sort By");
    const [customer, setcustomer] = useState(null);
  
    const [selectedFilterdata, setSelectedFilterdata] = useState("percentage");
    const [selectedCvStatusFilterdata, setSelectedCvStatusFilterdata] =
      useState("All");
    const [sortOrder, setSortOrder] = useState("DESC");
    const userType = localStorage.getItem("usertype");
    const [openDrawer, setOpenDrawer] = useState(false);
    const toggleDrawer = () => {
      setOpenDrawer(!openDrawer);
    };

  
    const searchTextChange = (event) => {
      setSearchText(event.target.value);
      setSortOrder("");
      setSelectedFilterdata("");
      setSelectedCvStatusFilterdata("All");
      setCvStatusFilterdata("Status");
      setFilterdata("Sort By");
    };
    const [notificationActive, setNotificationActive] = useState(false);
    const [noDataAvailable, setNoDataAvailable] = useState(false);

    const toggleThirdDropdown = () => {
        setthirdIsOpen((prev) => !prev);
      };
    
      const handleThirdOptionClick = (data) => {
        setSelectedCvStatusFilterdata(data.cvstatusname);
        setCvStatusFilterdata(data.cvstatusname);
    
        toggleThirdDropdown();
      };

      const capitalizeWords = (str) => {
        return str?.replace(/\b\w/g, (char) => char?.toUpperCase());
      };

      function formatDateTime(dateString) {
        const date = new Date(dateString);
    
        // Format the date as MM/DD/YY
        const formattedDate = date.toLocaleDateString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        });
    
        // Format the time as HH:MMam/pm
        let formattedTime = date
          .toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
          .replace(" ", " ")
          .toUpperCase(); // Remove the space and convert to lowercase
    
        return `${formattedDate} ${formattedTime}`;
      }

      const yourColumnsArray = [
        { id: 1, title: "Column 1", isVisible: true },
    { id: 2, title: "Column 2", isVisible: true },
    { id: 3, title: "Column 7", isVisible: true },
    { id: 4, title: "Column 3", isVisible: true},
    { id: 5, title: "Column 4", isVisible: true},
    { id: 6, title: "Column 5", isVisible: true },
    { id: 7, title: "Column 6", isVisible: true },
    { id: 8, title: "Column 8", isVisible: true },
    { id: 9, title: "Column 9", isVisible: true },
       
      ];
    
      const totalColumns = 9;
      const fullWidth = 1600; // Maximum width when all columns are visible
      const columnWidth = fullWidth / totalColumns;
    
      // Calculate the current table width based on visible columns
      const visibleColumns = yourColumnsArray.filter(
        (column) => column.isVisible
      ).length;
      const tableWidth = Math.round(visibleColumns * columnWidth);

      const CvStatus = [{cvstatusname: "Pending"}, {cvstatusname: "Done"}];

    return (
        <>
        <Helmet>
        <style>{"body { background-color: #F3F5F9;}"}</style>
        <title>Screening List</title>
      </Helmet>
            <>
            
              <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
                <Navbar data={activetab} />
                <Box component="main" sx={{ flexGrow: 1, p: 3, width: "80%" }}>
                  {/* <div class="analyst" className="flex ">
                    <div className="w-[28%] ms-5 ">
                      <button onClick={() => navigate(-1)} className="p-match">
                        <img
                          style={{ width: "16px" }}
                          src="/images/arrow-left.svg"
                        />
                        Back
                      </button>
                    </div>
                  </div> */}

<div className="mb-3">
                <HeaderBar title="Screening" toggleDrawer={toggleDrawer} />
              </div>
                  
    
                  <div className="flex flex-row justify-between mt-2 gap-3">
                    <div
                      className="search-container"
                      style={{
                        marginLeft: "18px",
                        marginTop: "10px",
                        marginBottom: "22px",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        width: "500px",
                      }}
                    >
                      <input
                        type="text"
                        id="search"
                        placeholder="Search Candidate"
                        style={{
                          border: "none",
                          outline: "none",
                          height: "40px",
                          paddingLeft: "10px",
                          width: "200px",
                          flex: 1,
                        }}
                        value={searchText}
                        onChange={searchTextChange}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") ;
                        }}
                      />
    
                      {searchText && (
                        <button
                          style={{
                            position: "absolute",
                            right: "40px",
                            border: "none",
                            outline: "none",
                            background: "transparent",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                        //   onClick={clearSearch}
                        >
                          <img
                            src="/images/greenCross1.svg"
                            style={{ width: "10px", height: "10px" }}
                            alt="Clear"
                          />
                        </button>
                      )}
    
                      <button
                        style={{
                          border: "none",
                          outline: "none",
                          height: "40px",
                          paddingTop: "9px",
                        }}
                        type="submit"
                        
                      >
                        <img
                          style={{ background: "inherit" }}
                          src="/images/Search Icon.svg"
                        />
                      </button>
                    </div>
                    <div className="flex flex-row justify-between  mt-3 gap-3">
                      <div
                        className="dropdown_sort_by"
                        style={{ width: "250px", zIndex: "99"  }}
                        // ref={secondDropdownRef}
                      >
                        <button
                          onClick={toggleThirdDropdown}
                          className={`dropdown_sort ${
                            isthirdOpen ? "thirdopen" : ""
                          }`}
                        >
                          {CvStatusfilterdata || "All"}
                          {!isthirdOpen ? (
                            <img src="images/custom-arrow.svg" />
                          ) : (
                            <img
                              style={{ transform: "rotate(180deg)" }}
                              src="images/custom-arrow.svg"
                            />
                          )}
                        </button>
    
                        {isthirdOpen && (
                          <div className="dropdown_sort_by_list">
                            <div>
                              {CvStatus &&
                                [
                                  { cvstatusname: "All" }, // Add the "All" option as the first item
                                  ...(CvStatus || []),
                                ]?.map((data, i) => (
                                  <p
                                    key={i}
                                    onClick={() => handleThirdOptionClick(data)}
                                  >
                                    {data?.cvstatusname}
                                  </p>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
    
                   
                    </div>
                  </div>
    
               
                   { noDataAvailable ? (
                      <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                        <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                          <p className="text-[30px] text-gray-500 font-bold">!</p>
                        </div>
                        <p className="text-[20px] text-gray-500 font-bold">
                          Data not available
                        </p>
                      </div>
                    ) : (
                      <>
                        <div
                          className="2xl:min-w-[100%]   overflow-y-hidden ms-5"
                          style={{
                            scrollbarWidth: "thin",
                            scrollbarColor: "auto transparent",
                          }}
                        >
                          <div
                            className={`overflow-auto `}
                            style={{
                                width: `${tableWidth }px`,
                              scrollbarWidth: "thin",
                              scrollbarColor: "auto transparent",
                            }}
                          >
                            <table className="w-full bg-white border-b  border-[#E0E0E0] divide-gray-200">
                              <thead>
                                <tr>
                                  <th
                                    className={`!w-[100px]  px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase`}
                                    // onClick={() => {
                                    //   setSortOrder(
                                    //     sortOrder === "ASC" ? "DESC" : "ASC"
                                    //   );
                                    //   setSelectedFilterdata("atoz");
                                    //   setFilterdata("Sort By");
                                    // }}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    Customer{" "}
                                    {/* <span
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {selectedFilterdata != "atoz" ? (
                                        <img
                                          src="/images/descendant.svg"
                                          alt="Sort"
                                          style={{
                                            width: "1em",
                                            height: "1em",
                                          }}
                                        />
                                      ) : sortOrder === "DESC" ? (
                                        <img
                                          src="/images/asc.svg"
                                          alt="Ascending"
                                          style={{
                                            width: "1em",
                                            height: "1em",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src="/images/desc.svg"
                                          alt="Descending"
                                          style={{
                                            width: "1em",
                                            height: "1em",
                                          }}
                                        />
                                      )}
                                    </span> */}
                                  </th>
                                  <th
                                    className="!w-[86px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase"
                                    // onClick={() => {
                                    //   setSortOrder(
                                    //     sortOrder === "ASC" ? "DESC" : "ASC"
                                    //   );
                                    //   setSelectedFilterdata("percentage");
                                    //   setFilterdata("Sort By");
                                    // }}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                   Job ID
                                    {/* <span
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {selectedFilterdata != "percentage" ? (
                                        <img
                                          src="/images/descendant.svg"
                                          alt="Sort"
                                          style={{
                                            width: "1em",
                                            height: "1em",
                                          }}
                                        />
                                      ) : sortOrder === "DESC" ? (
                                        <img
                                          src="/images/asc.svg"
                                          alt="Ascending"
                                          style={{
                                            width: "1em",
                                            height: "1em",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src="/images/desc.svg"
                                          alt="Descending"
                                          style={{
                                            width: "1em",
                                            height: "1em",
                                          }}
                                        />
                                      )}
                                    </span> */}
                                  </th>
    
                              
                                    <th
                                      className="!w-[108px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase"
                                    //   onClick={() => {
                                    //     setSortOrder(
                                    //       sortOrder === "ASC" ? "DESC" : "ASC"
                                    //     );
                                    //     setSelectedFilterdata("ethnicity");
                                    //     setFilterdata("Sort By");
                                    //   }}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      Job Title{" "}
                                      {/* <span
                                        style={{
                                          display: "inline-flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {selectedFilterdata != "ethnicity" ? (
                                          <img
                                            src="/images/descendant.svg"
                                            alt="Sort"
                                            style={{
                                              width: "1em",
                                              height: "1em",
                                            }}
                                          />
                                        ) : sortOrder === "DESC" ? (
                                          <img
                                            src="/images/asc.svg"
                                            alt="Ascending"
                                            style={{
                                              width: "1em",
                                              height: "1em",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src="/images/desc.svg"
                                            alt="Descending"
                                            style={{
                                              width: "1em",
                                              height: "1em",
                                            }}
                                          />
                                        )}
                                      </span> */}
                                    </th>
                             
                               
                                    <th
                                      className={` !w-[100px]  px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase`}
                                    //   onClick={() => {
                                    //     setSortOrder(
                                    //       sortOrder === "ASC" ? "DESC" : "ASC"
                                    //     );
                                    //     setSelectedFilterdata("nationality");
                                    //     setFilterdata("Sort By");
                                    //   }}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      Name{" "}
                                      {/* <span
                                        style={{
                                          display: "inline-flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {selectedFilterdata != "nationality" ? (
                                          <img
                                            src="/images/descendant.svg"
                                            alt="Sort"
                                            style={{
                                              width: "1em",
                                              height: "1em",
                                            }}
                                          />
                                        ) : sortOrder === "DESC" ? (
                                          <img
                                            src="/images/asc.svg"
                                            alt="Ascending"
                                            style={{
                                              width: "1em",
                                              height: "1em",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src="/images/desc.svg"
                                            alt="Descending"
                                            style={{
                                              width: "1em",
                                              height: "1em",
                                            }}
                                          />
                                        )}
                                      </span> */}
                                    </th>
                             
                            
                                    <th
                                      className="!w-[80px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase"
                                    //   onClick={() => {
                                    //     setSortOrder(
                                    //       sortOrder === "ASC" ? "DESC" : "ASC"
                                    //     );
                                    //     setSelectedFilterdata("gender");
                                    //     setFilterdata("Sort By");
                                    //   }}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      GENDER{" "}
                                      {/* <span
                                        style={{
                                          display: "inline-flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {selectedFilterdata != "gender" ? (
                                          <img
                                            src="/images/descendant.svg"
                                            alt="Sort"
                                            style={{
                                              width: "1em",
                                              height: "1em",
                                            }}
                                          />
                                        ) : sortOrder === "DESC" ? (
                                          <img
                                            src="/images/asc.svg"
                                            alt="Ascending"
                                            style={{
                                              width: "1em",
                                              height: "1em",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src="/images/desc.svg"
                                            alt="Descending"
                                            style={{
                                              width: "1em",
                                              height: "1em",
                                            }}
                                          />
                                        )}
                                      </span> */}
                                    </th>
                           
                            
                                    <th
                                      className="!w-[110px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase"
                                    //   onClick={() => {
                                    //     setSortOrder(
                                    //       sortOrder === "ASC" ? "DESC" : "ASC"
                                    //     );
                                    //     setSelectedFilterdata("currentloc");
                                    //     setFilterdata("Sort By");
                                    //   }}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                     Nationality{" "}
                                      {/* <span
                                        style={{
                                          display: "inline-flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {selectedFilterdata != "currentloc" ? (
                                          <img
                                            src="/images/descendant.svg"
                                            alt="Sort"
                                            style={{
                                              width: "1em",
                                              height: "1em",
                                            }}
                                          />
                                        ) : sortOrder === "DESC" ? (
                                          <img
                                            src="/images/asc.svg"
                                            alt="Ascending"
                                            style={{
                                              width: "1em",
                                              height: "1em",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src="/images/desc.svg"
                                            alt="Descending"
                                            style={{
                                              width: "1em",
                                              height: "1em",
                                            }}
                                          />
                                        )}
                                      </span> */}
                                    </th>
                               
                                  <th
                                    className="!w-[90px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase"
                                    // onClick={() => {
                                    //   setSortOrder(
                                    //     sortOrder === "ASC" ? "DESC" : "ASC"
                                    //   );
                                    //   setSelectedFilterdata("totalexp");
                                    //   setFilterdata("Sort By");
                                    // }}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    Screening Note{" "}
                                    {/* <span
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {selectedFilterdata != "totalexp" ? (
                                        <img
                                          src="/images/descendant.svg"
                                          alt="Sort"
                                          style={{
                                            width: "1em",
                                            height: "1em",
                                          }}
                                        />
                                      ) : sortOrder === "DESC" ? (
                                        <img
                                          src="/images/asc.svg"
                                          alt="Ascending"
                                          style={{
                                            width: "1em",
                                            height: "1em",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src="/images/desc.svg"
                                          alt="Descending"
                                          style={{
                                            width: "1em",
                                            height: "1em",
                                          }}
                                        />
                                      )}
                                    </span> */}
                                  </th>
                                  <th className="!w-[80px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                    Edit
                                  </th>
                                  <th className="!w-[100px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                 Date
                                  </th>

                                 
                                </tr>
                              </thead>
                            </table>
    
                            <div
                              className={` ${
                                noDataAvailable
                                  ? "max-h-[calc(100vh-350px)]"
                                  : "max-h-[calc(100vh-305px)]"
                              }  overflow-auto shadow-md `}
                              style={{
                                scrollbarWidth: "thin",
                                scrollbarColor: "auto transparent",
                              }}
                            >
                              <table className="w-full">
                                <tbody>
                                  {/* {data &&
                                    data[0]?.candidatesdata?.map((candidate, i) => ( */}
                                      <tr
                                        // key={i}
                                        // className={`${
                                        //   i % 2 === 0
                                        //     ? "bg-[#EDF4FF] border-none"
                                        //     : "bg-white border-none"
                                        // }`}
                                      >
                                       
                                        <td className=" !w-[100px]  px-2 py-4 text-left text-sm text-gray-700 font-normal text-[15px]">
                                              Xyz
                                          {/* <div className="flex flex-col gap-1">
                                            <div className="w-full flex justify-between">
                                              <Tooltip
                                                title={
                                                  candidate?.candidatename?.length >
                                                  20
                                                    ? candidate.candidatename
                                                    : ""
                                                }
                                              >
                                                {candidate?.candidatename.length >
                                                20
                                                  ? `${candidate?.candidatename.substring(
                                                      0,
                                                      20
                                                    )}...`
                                                  : candidate?.candidatename}
                                              </Tooltip>
                                            
                                              <div
                                                className="cursor-pointer"
                                                onClick={(e) =>
                                                  navigate("/candidateresult", {
                                                    state: candidate?.analysisid,
                                                  })
                                                }
                                              >
                                                <img src="/images/arrow.svg" />
                                              </div>
                                            </div>
                                            <div className="text-[#708EA4] text-sm font-normal">
                                              {formatDateTime(
                                                candidate?.createddate
                                              )}
                                            </div>
                                          </div> */}
                                        </td>
    
                                        <td className=" !w-[86px]  px-2 py-4 text-center text-sm text-gray-700 font-normal text-[15px]">
                                       34567
                                        </td>
    
                                        
                                          <td className=" !w-[108px] text-left px-2 py-4  text-sm text-gray-700 font-normal text-[15px]">
                                          Computer Eng
                                          </td>
                                  
                                       
                                          <td
                                            className={` !w-[100px] text-left px-2 py-4  font-normal text-[15px] text-gray-700`}
                                          >
                                           Panda
                                          </td>
                                       
                                       
                                          <td className="!w-[80px] text-left   cursor-paste px-2 py-4  text-sm text-gray-700 font-normal text-[15px]">
                                           Male
                                          </td>
                                    
                                       
                                          <td className=" !w-[110px] text-left cursor-paste px-2 py-4  text-sm text-gray-700 font-normal text-[15px]">
                                           Arabic
                                          </td>
                                    
                                        <td className=" !w-[90px] text-left   cursor-paste px-2 py-4  text-sm text-gray-700 font-normal text-[15px]">
                                         perfect in work
                                        </td>
                                        <td className="!w-[80px] text-center  items-center cursor-paste px-2  py-4  text-sm text-gray-700 font-normal text-[15px]">
                                            <div className="flex justify-center">
                                                             <img
                                                                 className=" cursor-pointer"
                                                                 src="/Group 247.svg"
                                                                 alt="edit_icons"
                                                             />
                                            </div>
                                        </td>
                                        <td className="!w-[100px] text-left px-2  py-4  text-sm text-gray-700 font-normal text-[15px]">
                                          07/11/2024
                                        </td>
                                      
                                       
                                       
    
                                      
                                      </tr>
                                    {/* ))} */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
              
                   
                </Box>
              </Box>
            </>
            </>
      );
    }

export default Screening