import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Box from "@mui/material/Box";
import "./FavoriteCandidates.css";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { storage } from "../../libs/localStorage";
import TableRow from "@mui/material/TableRow";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import Paper from "@mui/material/Paper";
import { useJobsData } from "../../data/jobsdata";
import { useCandidateData } from "../../data/candidatedata";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Tooltip, useMediaQuery, useTheme } from "@mui/material";
// import { storage } from "../../libs/localStorage";
import "react-calendar/dist/Calendar.css";
import MarkFavorite from "../../components/MarkFavorite/MarkFavorite";
import Pagination from "../../components/Pagination/Pagination";
import Loading from "../../components/Loading/Loading";

export default function FavoriteCandidates() {
  const [data, setData] = useState(null);
  const pageSize = 10;
  const { retrieveToken } = storage();
  const token = retrieveToken();
  const [currentPage, setCurrentPage] = useState(1);
  // const totalPages = Math.ceil(totalCounts / pageSize);
  const [searchText, setSearchText] = useState("");
  const [toggleView, setToggleView] = useState(true);
  const [totalCounts, setTotalCounts] = useState(null);
  const totalPages = Math.ceil(totalCounts / pageSize);
  const theme = useTheme();
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  // const { retrieveToken } = storage();
  const { GetProcessedJobsCandidates } = useCandidateData();
  const activetab = "favorites";
  // const token = retrieveToken();
  const searchTextChange = (event) => {
    setSearchText(event.target.value);
  };
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const { MarkAnalysisFavorite } = useCandidateData();
  const { GetAtsUrlByAtsJobcode } = useJobsData();
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  function getOpenDayLabel(openDays) {
    const dayLabels = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const today = new Date();
    const openDate = new Date(today);
    openDate.setDate(today.getDate() - openDays);

    const daysAgo = Math.floor((today - openDate) / (1000 * 60 * 60 * 24));
    const openDayOfWeek = openDate.getDay();

    if (daysAgo === 0) {
      return "Opened Today";
    } else if (daysAgo === 1) {
      return "Opened Yesterday";
    } else if (daysAgo < 7) {
      return `Opened ${dayLabels[openDayOfWeek]}`;
    } else {
      return `Opened on\n${openDate
        .toLocaleDateString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-")}`;
    }
  }

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  // useEffect(() => {
  //   if (token) {
  //     GetProcessedJobsCandidates({
  //       search: searchText,
  //       isfavorite: true
  //     })
  //       .then((response) => {
  //         setData(response.data);
  //         response?.data?.[0]?.processedcandidatecount &&
  //         setTotalCounts(response?.data?.[0]?.processedcandidatecount);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //     // setCurrentPage(1);
  //   } else {
  //     window.location.replace("https://login.talentin.ai/");
  //   }
  // }, []);

  const fetchData = async (searchText = "", page = 1) => {
    try {
      setIsLoading(true);
      const response = await GetProcessedJobsCandidates({
        pageno: page,
        pagesize: pageSize,
        search: searchText,
        isfavorite: true,
      });

      if (response?.data == null || response?.data?.length === 0) {
        setHasMoreData(false);
        if (page === 1) {
          setData([]);
          setTotalCounts(0);
          setNoDataAvailable(true);
        }
      } else {
        setData((prevData) =>
          page === 1 ? response?.data : [...prevData, ...response?.data]
        );
        setHasMoreData(response?.data?.length >= pageSize);
        setTotalCounts(response?.data[0]?.totalcount);
        setIsLoading(false);
        setNoDataAvailable(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  // useEffect((page = 1) => {
  //   if (token) {
  //     GetProcessedJobsCandidates({
  //       pageno: page,
  //       pagesize: pageSize,
  //       search: searchText,
  //       isfavorite: true,
  //     })
  //       .then((response) => {
  //         if (response?.data == (undefined || null)) {
  //           setData([]);
  //           setTotalCounts(0);
  //           setNoDataAvailable(true);
  //         } else {
  //           setData(response?.data);
  //           response?.data?.[0]?.processedcandidatecount &&
  //             setTotalCounts(response?.data?.[0]?.processedcandidatecount);
  //           window.scrollTo(0, 0);
  //           setNoDataAvailable(false);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   } else {
  //     window.location.replace("https://login.talentin.ai/");
  //   }
  // }, [ currentPage]);

  useEffect(() => {
    try {
      if (token) {
        // await fetchJobs(searchText);
        setCurrentPage(1);
      } else {
        window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleScroll = () => {
    const container = containerRef.current;
    if (!container || isLoading) return;

    // Adjusting scroll detection for mobile
    const isNearBottom =
      container.scrollHeight - container.scrollTop <=
      container.clientHeight + 5; // Small buffer

    if (isNearBottom && hasMoreData) {
      setIsLoading(true);
      setTimeout(() => {
        setCurrentPage((prevPage) => prevPage + 1);
      }, 500);
    }
  };

  useEffect(() => {
    if (currentPage > 0) {
      fetchData(searchText, currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      const container = containerRef.current;
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMoreData, isMobile]);

  const btnSearch = (currentPage = 1) => {
    setCurrentPage(1);
    GetProcessedJobsCandidates({
      pageno: currentPage,
      pagesize: pageSize,
      search: searchText,
      isfavorite: true,
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setData([]);
          setTotalCounts(0);
          setNoDataAvailable(true);
        } else {
          setData(response.data);
          response?.data?.[0]?.processedcandidatecount &&
            setTotalCounts(response?.data?.[0]?.processedcandidatecount);
          window.scrollTo(0, 0);
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const clearSearch = () => {
    setSearchText("");
    setNoDataAvailable(false);
    setCurrentPage(1);
    GetProcessedJobsCandidates({
      pageno: 1,
      pagesize: pageSize,
      search: "",
      isfavorite: true,
    })
      .then((response) => {
        setData(response.data);
        response?.data?.[0]?.processedcandidatecount &&
          setTotalCounts(response?.data?.[0]?.processedcandidatecount);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const btnMarkFavorite = (cand) => {
    MarkAnalysisFavorite({
      analysisid: cand?.analysisid,
      isfavorite: !cand?.isfavorite,
    })
      .then((response) => {
        const itemIndex = data?.findIndex(
          (item) => item.analysisid === cand?.analysisid
        );
        if (itemIndex !== -1) {
          const updatedCandidatesData = [...data];
          updatedCandidatesData[itemIndex] = {
            ...updatedCandidatesData[itemIndex],
            isfavorite: !cand?.isfavorite,
          };
          setData(updatedCandidatesData);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // const redirectToDetail = (analysisid) => {
  //   navigate(`/candidateresult/${analysisid}`);
  // };

  // const btnViewMore = (params) => {
  //   console.log(params);
  //   navigate("/processedcandidate", { state: params });
  // };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.white,
      fontFamily: "Rubik, sans-serif",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontFamily: "Rubik, sans-serif",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#EDF4FF",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#ffffff",
    },
  }));
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    lineHeight: "24px",
    color: theme.palette.text.secondary,
  }));

  const redirectToAts = (params) => {
    const { atsjobcode, atsid } = params;
    console.log(params);
    if (atsid) {
      GetAtsUrlByAtsJobcode({
        atsjobcode: atsjobcode,
      })
        .then((response) => {
          window.open(response?.data[0]?.atsurl, "_blank");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
    }
  };

  return (
    <>
      {isMobile ? (
        <>
          <Navbar data={activetab} />
          <Helmet>
            <style>{"body { background-color: #F3F5F9;}"}</style>
          </Helmet>

          <React.Fragment>
            <div
              style={{
                margin: "18px 10px 8px 10px",
              }}
            >
              <div class="search-containers">
                <input
                  type="text"
                  id="search"
                  placeholder="Search Job ID, Job Title & Candidate"
                  value={searchText}
                  onChange={searchTextChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") btnSearch();
                  }}
                />

                {searchText && (
                  <button
                    style={{
                      position: "absolute",
                      right: "50px",
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={clearSearch}
                  >
                    <img
                      src="/images/greenCross1.svg"
                      style={{ width: "10px", height: "10px" }}
                      alt="Clear"
                    />
                  </button>
                )}
                <button type="submit" onClick={() => btnSearch()}>
                  <img
                    style={{ background: "inherit" }}
                    src="/images/Search Icon.svg"
                  />
                </button>
              </div>
            </div>

            {noDataAvailable ? (
              <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                  <p className="text-[30px] text-gray-500 font-bold">!</p>
                </div>
                <p className="text-[20px] text-gray-500 font-bold">
                  Data not available
                </p>
              </div>
            ) : (
              <div
                ref={containerRef}
                style={{
                  marginBottom: "25px",
                  height: "81vh",
                  overflowY: "auto",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  columns={isMobile ? 1 : { xs: 4, sm: 8, md: 12 }}
                >
                  {data &&
                    data.map((data, i) => (
                      <Grid item xs={2} sm={4} md={4}>
                        <Item
                          style={{
                            marginTop: "15px",
                            marginRight: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <Tooltip
                                title={
                                  data?.atsjobcode.length > 12
                                    ? data.atsjobcode
                                    : ""
                                }
                              >
                                <p
                                  style={{
                                    color: "#21D6AA",
                                    fontWeight: "600",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    navigate("/jobdescribtion", {
                                      state: {
                                        jobid: data?.jobid,
                                        jobcode: data?.atsjobcode,
                                      },
                                    })
                                  }
                                >
                                  {data?.atsjobcode.length > 12
                                    ? `${data?.atsjobcode.substring(0, 12)}...`
                                    : data?.atsjobcode}
                                </p>
                              </Tooltip>
                              <p class="gray">
                                {/* {data?.opendays > 0
                                ? data?.opendays > 1
                                  ? "Opened " + data?.opendays + " Days Ago"
                                  : "Opened " + data?.opendays + " Day Ago"
                                : "Opened Today"} */}
                                {getOpenDayLabel(data?.opendays)}
                              </p>
                            </div>
                            <div>
                              <p
                                style={{
                                  color: "#1F2E39",
                                  fontWeight: "600",
                                  cursor: "pointer",
                                }}
                              >
                                <Tooltip
                                  title={
                                    data?.jobtitle?.length > 25
                                      ? data?.jobtitle
                                      : ""
                                  }
                                >
                                  {data?.jobtitle?.length > 25
                                    ? `${data?.jobtitle.substring(0, 25)}...`
                                    : data?.jobtitle}
                                </Tooltip>
                              </p>
                            </div>
                          </div>

                          <div class="grid-details">
                            <div class="under-det">
                              {data?.candidatename ? (
                                <p
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    // redirectToDetail(data?.analysisid)
                                    navigate("/candidateresult", {
                                      state: data?.analysisid,
                                    })
                                  }
                                >
                                  {data?.candidatename}
                                </p>
                              ) : (
                                <p></p>
                              )}
                              <p
                                style={{ color: "#21D6AA", cursor: "pointer" }}
                                onClick={() =>
                                  navigate("/candidateresult", {
                                    state: data?.analysisid,
                                  })
                                }
                              >
                                View
                              </p>
                            </div>
                            <div
                              class="under-details"
                              style={{ textAlign: "center" }}
                            >
                              {data?.candidatename ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    gap: "7px",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "#708EA4",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {data?.match_percentage}%
                                  </p>
                                  <img
                                    style={{ width: "20px", cursor: "pointer" }}
                                    onClick={() =>
                                      navigate("/candidateresult", {
                                        state: data?.analysisid,
                                      })
                                    }
                                    src="/images/arrow.svg"
                                  />
                                </div>
                              ) : (
                                <div></div>
                              )}
                              <MarkFavorite
                                onClick={(e) =>
                                  btnMarkFavorite({
                                    analysisid: data?.analysisid,
                                    isfavorite: data?.isfavorite,
                                  })
                                }
                                imgUrl={
                                  data?.isfavorite
                                    ? "/images/filled-bookmark.svg"
                                    : "/images/bookmark.svg"
                                }
                                style={{
                                  marginLeft: "40px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </div>
                        </Item>
                      </Grid>
                    ))}
                </Grid>
              </div>
            )}
            {/* {noDataAvailable ? (
              ""
            ) : (
              <Pagination
                canNextPage={currentPage < totalPages}
                canPrevPage={currentPage > 1}
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
            )} */}
          </React.Fragment>
        </>
      ) : (
        <>
          <Helmet>
            <style>{"body { background-color: #F3F5F9;}"}</style>
            <title> Favorite Candidates</title>
            {/* <meta name="description" content="App Description" /> */}
            {/* <meta name="theme-color" content="#008f68" /> */}
          </Helmet>

          <Box sx={{ display: "flex", height: "100vh" }}>
            <Navbar data={activetab} />
            <Box component="main" className="px-6 pt-3" sx={{ flexGrow: 1 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <HeaderBar
                  title={"Favorite Candidates"}
                  toggleDrawer={toggleDrawer}
                />
                {/* <Badge color="error" variant="dot" onClick={toggleDrawer} sx={{
                                    '& .MuiBadge-dot': {
                                        backgroundColor: '#f00', // Adjust background color as needed
                                        border: '2px solid #fff', // Add white border
                                        position: 'absolute', // Position the dot absolutely
                                        top: '3px', // Adjust top positioning as needed
                                        right: '8px', // Adjust right positioning as needed
                                        transform: 'translate(50%, -50%)', // Center the dot
                                    },
                                }}>
                                    <NotificationsIcon className='noti' />
                                </Badge> */}
                {/* </div> */}

                <div
                  className="search-container px-3 pt-3"
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    width: "500px",
                  }}
                >
                  <input
                    type="text"
                    id="search"
                    placeholder="Search Job ID, Job Title & Candidate"
                    style={{
                      border: "none",
                      outline: "none",
                      height: "40px",
                      paddingLeft: "10px",
                      width: "200px",
                      flex: 1,
                    }}
                    value={searchText}
                    onChange={searchTextChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") btnSearch();
                    }}
                  />

                  {searchText && (
                    <button
                      style={{
                        position: "absolute",
                        right: "40px",
                        border: "none",
                        outline: "none",
                        background: "transparent",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={clearSearch}
                    >
                      <img
                        src="/images/greenCross1.svg"
                        style={{ width: "10px", height: "10px" }}
                        alt="Clear"
                      />
                    </button>
                  )}

                  <button
                    style={{
                      border: "none",
                      outline: "none",
                      height: "40px",
                      paddingTop: "9px",
                    }}
                    type="submit"
                    onClick={() => btnSearch()}
                  >
                    <img
                      style={{ background: "inherit" }}
                      src="/images/Search Icon.svg"
                    />
                  </button>
                </div>

                <Box sx={{ display: "flex", width: "100%" }}>
                  <Box
                    component="main"
                    sx={{ flexGrow: 1, pl: 3, width: "100%" }}
                  >
                    {noDataAvailable ? (
                      <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                        <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                          <p className="text-[30px] text-gray-500 font-bold">
                            !
                          </p>
                        </div>
                        <p className="text-[20px] text-gray-500 font-bold">
                          Data not available
                        </p>
                      </div>
                    ) : (
                      <div className="table w-full">
                        <TableContainer
                          component={Paper}
                          sx={{ borderRadius: "0px", boxShadow: 2 }}
                        >
                          <div
                            style={{
                              position: "sticky",
                              top: 0,
                              zIndex: 1,
                              backgroundColor: "white",
                            }}
                          >
                            <Table
                              sx={{ minWidth: 700 }}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    style={{
                                      backgroundColor: "white",
                                      color: "#5A93ED",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      maxWidth: "80px",
                                    }}
                                  >
                                    {" "}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      backgroundColor: "white",
                                      color: "#5A93ED",
                                      fontSize: "16px",
                                      // maxWidth: "95px",
                                      fontFamily: "Rubik, Medium",
                                    }}
                                    className="max-w-[95px] 2xl:max-w-[100px] "
                                  >
                                    ATS JOB ID
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      backgroundColor: "white",
                                      color: "#5A93ED",
                                      fontSize: "16px",
                                      fontFamily: "Rubik, Medium",
                                      // maxWidth: "150px"
                                    }}
                                    className="max-w-[150px] 2xl:max-w-[155px] "
                                  >
                                    JOB TITLE
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      backgroundColor: "white",
                                      color: "#5A93ED",
                                      fontSize: "16px",
                                      // maxWidth: "100px",
                                      fontFamily: "Rubik, Medium",
                                    }}
                                    className="max-w-[100px] 2xl:max-w-[95px] "
                                  >
                                    CANDIDATE{" "}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      backgroundColor: "white",
                                      color: "transparent",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      maxWidth: "200px",
                                    }}
                                  >
                                    RESULT
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          </div>
                          <div
                            ref={containerRef}
                            className="1538:max-h-[540px] max-h-[calc(100vh-200px)]"
                            style={{
                              overflow: "auto",
                              WebkitOverflowScrolling: "touch",
                              scrollbarWidth: "thin",
                              msOverflowStyle: "none",
                              "&::-webkit-scrollbar": {
                                display: "none",
                              },
                            }}
                          >
                            <Table
                              sx={{ minWidth: 700 }}
                              aria-label="customized table"
                            >
                              <TableBody>
                                {data &&
                                  data?.map((data, i) => (
                                    <StyledTableRow
                                      key={i}
                                      style={{ border: "noneb" }}
                                    >
                                      <StyledTableCell
                                        style={{ borderBottom: "none" }}
                                        className="xl:w-[7.5%] w-[7%] 2xl:w-[7.5%]"
                                      >
                                        <div className="raw justify-end w-8 2xl:w-[14px]">
                                          <MarkFavorite
                                            onClick={(e) =>
                                              btnMarkFavorite({
                                                analysisid: data?.analysisid,
                                                isfavorite: data?.isfavorite,
                                              })
                                            }
                                            imgUrl={
                                              data?.isfavorite
                                                ? "/images/filled-bookmark.svg"
                                                : "/images/bookmark.svg"
                                            }
                                            style={{
                                              width: "auto",
                                              height: "auto",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </div>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{ borderBottom: "none" }}
                                        className="xl:w-[22%] w-[23%] 2xl:w-[23%]"
                                        onClick={() =>
                                          navigate("/jobdescribtion", {
                                            state: {
                                              jobid: data?.jobid,
                                              jobcode: data?.atsjobcode,
                                            },
                                          })
                                        }
                                      >
                                        <Tooltip
                                          title={
                                            data?.atsjobcode.length > 14
                                              ? data.atsjobcode
                                              : ""
                                          }
                                        >
                                          <p
                                            class="open"
                                            style={{
                                              fontFamily: "Rubik, SemiBold",
                                              maxWidth: "140px",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              cursor: "pointer",
                                              color: "#21D6AA",
                                              fontSize: "15px",
                                              fontWeight: "600",
                                            }}
                                            className="2xl:w-[110px] lg:w-[145px]"
                                            onClick={() =>
                                              redirectToAts({
                                                atsjobcode: data?.atsjobcode,
                                                atsid: data?.atsid,
                                              })
                                            }
                                          >
                                            <Tooltip
                                              title={
                                                data?.atsjobcode > 15
                                                  ? data?.atsjobcode
                                                  : ""
                                              }
                                            >
                                              {truncateString(
                                                data?.atsjobcode,
                                                15
                                              )}
                                            </Tooltip>
                                          </p>
                                        </Tooltip>
                                        <p className="open">
                                          {/* {data?.opendays > 0
                                            ? data?.opendays > 1
                                              ? "Opened " +
                                                data?.opendays +
                                                " Days Ago"
                                              : "Opened " +
                                                data?.opendays +
                                                " Day Ago"
                                            : "Opened Today"} */}
                                          {getOpenDayLabel(data?.opendays)}
                                        </p>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        style={{ borderBottom: "none" }}
                                        className="xl:w-[26.5%]  2xl:w-[27%]"
                                      >
                                        <p
                                          style={{
                                            fontFamily: "Rubik, Regular",
                                            // maxWidth: "200px",
                                          }}
                                          className="2xl:w-auto  lg:w-[180px]"
                                        >
                                          <Tooltip
                                            title={
                                              data?.jobtitle.length > 24
                                                ? data?.jobtitle
                                                : ""
                                            }
                                          >
                                            {truncateString(data?.jobtitle, 24)}
                                          </Tooltip>
                                        </p>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        style={{ borderBottom: "none" }}
                                      >
                                        {/* <div class="raw"> */}
                                        {/* <div class="colum"> */}
                                        <p
                                          style={{
                                            cursor: "pointer",
                                            fontFamily: "Rubik, Regular",
                                            // fontSize: "15px",
                                            maxWidth: "200px",
                                            border: "none",
                                          }}
                                          className="lg:w-[200px]"
                                          onClick={() =>
                                            navigate("/candidateresult", {
                                              state: data?.analysisid,
                                            })
                                          }
                                        >
                                          <Tooltip
                                            title={
                                              data?.candidatename.length > 20
                                                ? data?.candidatename
                                                : ""
                                            }
                                          >
                                            {truncateString(
                                              data?.candidatename,
                                              20
                                            )}
                                          </Tooltip>
                                        </p>
                                        {/* </div> */}
                                        {/* </div> */}
                                      </StyledTableCell>

                                      <StyledTableCell
                                        style={{ borderBottom: "none" }}
                                      >
                                        <div class="raw">
                                          <p
                                            style={{
                                              cursor: "default",
                                              maxWidth: "50px",
                                            }}
                                          >
                                            {" "}
                                            <span class="per">
                                              {data?.match_percentage}%
                                            </span>
                                            &nbsp;
                                          </p>
                                        </div>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{
                                          position: "relative",
                                          zIndex: "0",
                                          borderBottom: "none",
                                        }}
                                      >
                                        <div class="boxbtn-parentt">
                                          <div
                                            class="box"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              navigate("/candidateresult", {
                                                state: data?.analysisid,
                                              })
                                            }
                                          >
                                            <span class="text">VIEW</span>&nbsp;
                                            <img
                                              class="icon"
                                              style={{
                                                maxWidth: "20px",
                                                height: "20px",
                                              }}
                                              src="/images/black-arrow.svg"
                                            />
                                          </div>
                                        </div>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </div>
                        </TableContainer>
                      </div>
                    )}
                  </Box>
                </Box>

                {/* <Outlet/> */}
                {/* Pagination */}
                {/* {noDataAvailable ? (
                  ""
                ) : (
                  <Pagination
                    canNextPage={currentPage < totalPages}
                    canPrevPage={currentPage > 1}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                )} */}
              </div>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
