import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();
const GetFeedbacks = async (body) => {
  const { pageno, pagesize, search, customer, filterdate, ratingby } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getfeedbacklist",
      pageno: pageno,
      pagesize: pagesize,
      search: search,
      customer: customer,
      filterdate: filterdate,
      ratingby: ratingby,
    },
  });
  return data;
};

const UpdateRating = async (body) => {
  const { analysisid, rating, remark } = body;
  const { data } = await axios.request({
    url: "/api/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updateratingbyanalysisid",
      analysisid: analysisid,
      rating: rating,
      remark: remark,
    },
  });
  return data;
};

export const useFeedbacksData = () => ({
  GetFeedbacks,
  UpdateRating,
});
