import React from 'react'
import NotificationsIcon from "@mui/icons-material/Notifications";
function HeaderBar(props) {

  const username = localStorage.getItem("username");
  return (
    <div className="col">
                  <div>
                    <p className="pro">
                      {props.title}
                    </p>
                  </div>
                  <div className="flex flex-row gap-2">
                    <NotificationsIcon
                      className="noti mr-2`"
                      // onClick={props.toggleDrawer}
                    />
                    {<img src="/jobLogo.svg"></img>}
                    {username && <p>{username}</p>}
                  </div>

                  
                </div>
  )
}

export default HeaderBar