import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar/Navbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Tooltip, useMediaQuery } from "@mui/material";

import {
  checkAtsCredentials,
  // updateClientDetails,
  updateClientSetting,
} from "../../data/addNewClient";
import { useNavigate } from "react-router-dom";
import { useClientSettingData } from "../../data/clientSettings";
import Pagination from "../../components/Pagination/Pagination";
import "./ClientSetting.css";
import HeaderBar from "../../components/HeaderBar/HeaderBar";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    fontFamily: "Rubik, sans-serif",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Rubik, sans-serif",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#EDF4FF",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#ffffff",
  },
}));

export default function ClientSetting() {
  //mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const activetab = "setting";
  const [data, setData] = useState(null);
  const { GetCustomers } = useClientSettingData();
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);
  // const totalPages = Math.ceil(totalCounts / pageSize);
  const [searchText, setSearchText] = useState("");
  const [totalCounts, setTotalCounts] = useState(null);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const totalPages = Math.ceil(totalCounts / pageSize);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const containerRef = useRef(null);
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const btnEdit = (params) => {
    navigate("/editclientsetting", { state: params });
  };

  const btnAddClient = () => {
    navigate("/addnewclient");
  };

  const searchTextChange = (event) => {
    setSearchText(event.target.value);
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      window.scrollTo(0, 0);
    }
  };

  const [deAndI, setDeAndI] = useState();
  const [autoAtsSync, setAutoAtsSync] = useState();
  const [standardization, setStandardization] = useState();
  const [isactive, setIsActive] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    return `${month}-${day}-${year}`;
  }
  function formatDate2(dateStr) {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return ` ${day} ${month} ${year} `;
  }

  const clearSearch = () => {
    setSearchText("");
    setCurrentPage(1);
    GetCustomers({
      pageno: 1,
      pagesize: pageSize,
      search: "",
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setData([]);
          setNoDataAvailable(true);
        } else {
          setData(response.data);
          // setTotalCounts(response?.data[0]?.totalcount);

          response?.data?.[0]?.totalcount &&
            setTotalCounts(response?.data?.[0]?.totalcount);
          window.scrollTo(0, 0);
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const btnSearch = () => {
    setCurrentPage(1);
    GetCustomers({
      pagesize: pageSize,
      pageno: 1,
      search: searchText,
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setData([]);
          setNoDataAvailable(true);
        } else {
          setData(response.data);
          // setTotalCounts(response?.data[0]?.totalcount);
          response?.data?.[0]?.totalcount &&
            setTotalCounts(response?.data?.[0]?.totalcount);
          window.scrollTo(0, 0);
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, []);

  const fetchData = async (searchText = "", page = 1) => {
    try {
      setIsLoading(true);
      const response = await GetCustomers({
        pageno: currentPage,
        pagesize: pageSize,
        search: searchText,
      });

      if (response?.data == null || response?.data?.length === 0) {
        setHasMoreData(false);
        if (page === 1) {
          setData([]);
          setTotalCounts(0);
          setNoDataAvailable(true);
        }
      } else {
        setData((prevData) =>
          page === 1 ? response?.data : [...prevData, ...response?.data]
        );
        setHasMoreData(response?.data?.length >= pageSize);
        setTotalCounts(response?.data[0]?.totalcount);
        setIsLoading(false);
        setNoDataAvailable(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (!container || isLoading) return;

    // Adjusting scroll detection for mobile
    const isNearBottom =
      container.scrollHeight - container.scrollTop <=
      container.clientHeight + 5; // Small buffer

    if (isNearBottom && hasMoreData) {
      setIsLoading(true);
      setTimeout(() => {
        setCurrentPage((prevPage) => prevPage + 1);
      }, 500);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      const container = containerRef.current;
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isLoading, hasMoreData, isMobile]);

  useEffect(() => {
    if (currentPage > 0) {
      fetchData(searchText, currentPage);
    }
  }, [currentPage, deAndI, standardization, isactive, autoAtsSync]);

  const initializeState = (data) => {
    return data?.reduce((acc, row) => {
      acc[row.customerid] = {
        deAndI: row.show_deni,
        autoAtsSync: row.atssync,
        standardization: row.isstandardization,
        isactive: row.isactive,
      };
      return acc;
    }, {});
  };

  const [rowData, setRowData] = useState(() => initializeState(data));

  useEffect(() => {
    setRowData(initializeState(data));
  }, [data]);

  const handleSave = (customerid, updatedRow) => {
    const rowIndex = data?.findIndex((row) => row?.customerid === customerid);

    if (rowIndex === -1) {
      console.error(`No data found for customerid ${customerid}`);
      return;
    }

    // Call the API to update client settings
    updateClientSetting({
      customer: customerid,
      show_deni: updatedRow?.deAndI,
      atssync: updatedRow?.autoAtsSync,
      isstandardization: updatedRow?.standardization,
      isactive: updatedRow?.isactive,
    })
      .then(() => {
        // Update the local state with the new values
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[rowIndex] = {
            ...updatedData[rowIndex],
            show_deni: updatedRow?.deAndI,
            atssync: updatedRow?.autoAtsSync,
            isstandardization: updatedRow?.standardization,
            isactive: updatedRow?.isactive,
          };
          return updatedData;
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        // Optionally handle any final logic here
      });
  };

  const handleCheckboxChange = async (customerid, key, value) => {
    let authCheckData;
    if (key == "autoAtsSync") {
      authCheckData = await checkAtsCredentials(customerid);
    }

    if (key == "autoAtsSync") {
      if (authCheckData.header.status == 200) {
        setRowData((prev) => {
          const newState = {
            ...prev,
            [customerid]: {
              ...prev[customerid],
              [key]: value,
            },
          };
          handleSave(customerid, newState[customerid]);
          return newState;
        });
      } else {
        alert("Credentials required to activate auto sync");

        // Reset checkbox state
        setRowData((prev) => {
          const newState = {
            ...prev,
            [customerid]: {
              ...prev[customerid],
              [key]: false, // Reset to unchecked
            },
          };
          return newState;
        });
      }
    } else {
      setRowData((prev) => {
        const newState = {
          ...prev,
          [customerid]: {
            ...prev[customerid],
            [key]: value,
          },
        };
        handleSave(customerid, newState[customerid]);
        return newState;
      });
    }
  };

  return (
    <>
      <Helmet>
        <style>{"body { background-color: #F3F5F9;}"}</style>
      </Helmet>

      {isMobile ? (
        <React.Fragment>
          <Navbar data={activetab} />

          <div
            style={{
              margin: "18px 10px 8px 10px",
            }}
          >
            <div class="search-containers">
              <input
                type="text"
                id="search"
                placeholder="Search Client Name & Industry"
                value={searchText}
                onChange={searchTextChange}
                onKeyDown={(event) => {
                  if (event.key === "Enter") btnSearch();
                }}
              />

              {searchText && (
                <button
                  style={{
                    position: "absolute",
                    right: "50px",
                    border: "none",
                    outline: "none",
                    background: "transparent",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={clearSearch}
                >
                  <img
                    src="/images/greenCross1.svg"
                    style={{ width: "10px", height: "10px" }}
                    alt="Clear"
                  />
                </button>
              )}
              <button type="submit" onClick={() => btnSearch()}>
                <img
                  style={{ background: "inherit" }}
                  src="/images/Search Icon.svg"
                />
              </button>
            </div>
          </div>
          {noDataAvailable ? (
            <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
              <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                <p className="text-[30px] text-gray-500 font-bold">!</p>
              </div>
              <p className="text-[20px] text-gray-500 font-bold">
                Data not available
              </p>
            </div>
          ) : (
            <div
              ref={containerRef}
              style={{
                marginBottom: "25px",
                height: "90vh",
                overflowY: "auto",
              }}
            >
              {data?.map((data, i) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "10px 10px",
                    background: "white",
                    padding: "10px",
                    alignItems: "end",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                        fontSize: "15px",
                        color: "#1F2E39",
                        fontWeight: "600",
                      }}
                    >
                      {/* <div className="green-dot"></div> */}
                      {data?.customername}
                    </p>
                    <div className="flex flex-raw justify-between gap-28">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <text
                          style={{
                            color: "#5A93ED",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          INDUSTRY:
                        </text>
                        <text style={{ color: "#1F2E39", fontSize: "15px" }}>
                          {" "}
                          {data?.industryname ? data?.industryname : ""}
                        </text>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                        }}
                      >
                        <text
                          style={{
                            color: "#5A93ED",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          DATE&nbsp;ADDED:
                        </text>
                        <text style={{ color: "#1F2E39", fontSize: "15px" }}>
                          {formatDate(data?.createddate)}
                        </text>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <text
                        style={{
                          color: "#5A93ED",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                      >
                        CLIENT URL:
                      </text>
                      <text style={{ color: "#1F2E39", fontSize: "15px" }}>
                        {data?.customerpanelurl ? data?.customerpanelurl : ""}
                      </text>
                    </div>

                    <p>
                      <text
                        style={{
                          color: "#5A93ED",
                          fontWeight: "600",
                          fontSize: "15px",
                          marginRight: "62px",
                        }}
                      >
                        ATS SYNC:
                      </text>
                      <label class="switch">
                        <input
                          type="checkbox"
                          id={`${data?.customerid}-autoAtsSync`}
                          onChange={(e) =>
                            handleCheckboxChange(
                              data?.customerid,
                              "autoAtsSync",
                              e.target.checked
                            )
                          }
                          checked={
                            rowData
                              ? rowData[data.customerid]?.autoAtsSync
                              : false
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                    </p>

                    <p style={{ marginTop: "10px" }}>
                      <text
                        style={{
                          color: "#5A93ED",
                          fontWeight: "600",
                          fontSize: "15px",
                          marginRight: "85px",
                        }}
                      >
                        D, E & I:
                      </text>
                      <label class="switch">
                        <input
                          type="checkbox"
                          id={`${data?.customerid}-deAndI`}
                          onChange={(e) =>
                            handleCheckboxChange(
                              data?.customerid,
                              "deAndI",
                              e.target.checked
                            )
                          }
                          checked={
                            rowData ? rowData[data?.customerid]?.deAndI : false
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                    </p>

                    <p style={{ marginTop: "10px" }}>
                      <text
                        style={{
                          color: "#5A93ED",
                          fontWeight: "600",
                          fontSize: "15px",
                          marginRight: "95px",
                        }}
                      >
                        STDZ:
                      </text>
                      <label class="switch">
                        <input
                          type="checkbox"
                          id={`${data?.customerid}-standardization`}
                          onChange={(e) =>
                            handleCheckboxChange(
                              data?.customerid,
                              "standardization",
                              e.target.checked
                            )
                          }
                          checked={
                            rowData
                              ? rowData[data?.customerid]?.standardization
                              : false
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                    </p>
                    <p style={{ marginTop: "10px" }}>
                      <text
                        style={{
                          color: "#5A93ED",
                          fontWeight: "600",
                          fontSize: "15px",
                          marginRight: "80px",
                        }}
                      >
                        ACTIVE:
                      </text>
                      <label class="switch">
                        <input
                          type="checkbox"
                          id={`${data?.customerid}-isactive`}
                          onChange={(e) =>
                            handleCheckboxChange(
                              data?.customerid,
                              "isactive",
                              e.target.checked
                            )
                          }
                          checked={
                            rowData
                              ? rowData[data?.customerid]?.isactive
                              : false
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                    </p>
                  </div>

                  <div id={data?.customerid}>
                    <p
                      style={{
                        color: "#21D6AA",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                      onClick={() => btnEdit(data)}
                    >
                      EDIT
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
          {/* {noDataAvailable ? (
            ""
          ) : (
            <Pagination
              canNextPage={currentPage < totalPages}
              canPrevPage={currentPage > 1}
              totalPages={totalPages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          )} */}
          <div
            style={{
              textAlign: "center",
              position: "sticky",
              bottom: "0",
              paddingBottom: "20px",
            }}
          >
            <p
              class="Add-Client"
              onClick={btnAddClient}
              style={{
                color: "white",
                fontSize: "15px",
                fontWeight: "500",
                display: "inline-flex",
                padding: "12px 28px",
              }}
            >
              ADD CLIENT
            </p>
          </div>
        </React.Fragment>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Navbar data={activetab} />
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 2, height: "100vh", borderRadius: "0px" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <HeaderBar
                title={"Client Settings"}
                toggleDrawer={toggleDrawer}
              />

              <div className=" flex flex-row justify-between px-3 py-3">
                <div
                  className="search-container"
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    width: "500px",
                  }}
                >
                  <input
                    type="text"
                    id="search"
                    placeholder="Search Client Name & Industry"
                    style={{
                      border: "none",
                      outline: "none",
                      height: "40px",
                      paddingLeft: "10px",
                      width: "200px",
                      flex: 1,
                    }}
                    value={searchText}
                    onChange={searchTextChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") btnSearch();
                    }}
                  />

                  {searchText && (
                    <button
                      style={{
                        position: "absolute",
                        right: "40px",
                        border: "none",
                        outline: "none",
                        background: "transparent",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={clearSearch}
                    >
                      <img
                        src="/images/greenCross1.svg"
                        style={{ width: "10px", height: "10px" }}
                        alt="Clear"
                      />
                    </button>
                  )}

                  <button
                    style={{
                      border: "none",
                      outline: "none",
                      height: "40px",
                      paddingTop: "9px",
                    }}
                    type="submit"
                    onClick={() => btnSearch()}
                  >
                    <img
                      style={{ background: "inherit" }}
                      src="/images/Search Icon.svg"
                    />
                  </button>
                </div>

                <div className="flex items-center cursor-pointer">
                  <p
                    className="bg-[#21D6AA] px-7 py-2 rounded-md text-white text-[15px] font-medium"
                    onClick={btnAddClient}
                  >
                    ADD CLIENT
                  </p>
                </div>
              </div>
            </div>

            {/* Table */}
            {noDataAvailable ? (
              <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                <div className=" rounded-full  flex w-12 h-12 justify-center items-center">
                  <p className="text-[30px] text-gray-500 font-bold">!</p>
                </div>
                <p className="text-[20px] text-gray-500 font-bold">
                  Data not available
                </p>
              </div>
            ) : (
              <div className="px-3">
                <div className="shadow-md">
                  <TableContainer
                    component={Paper}
                    sx={{ borderRadius: "0px", boxShadow: 2 }}
                  >
                    <div
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "white",
                      }}
                    >
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                color: "#5A93ED",
                                fontSize: "14px",
                                fontWeight: "600",
                                maxWidth: "102px",
                              }}
                            >
                              CLIENT NAME
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                color: "#5A93ED",
                                fontSize: "14px",
                                fontWeight: "600",
                                maxWidth: "90px",
                              }}
                            >
                              INDUSTRY
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                color: "#5A93ED",
                                fontSize: "14px",
                                fontWeight: "600",
                                maxWidth: "90px",
                              }}
                            >
                              DATE ADDED
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                color: "#5A93ED",
                                fontSize: "14px",
                                fontWeight: "600",
                                maxWidth: "160px",
                              }}
                            >
                              CLIENT URL
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                color: "#5A93ED",
                                fontSize: "14px",
                                fontWeight: "600",
                                maxWidth: "78px",
                              }}
                            >
                              ATS SYNC
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                color: "#5A93ED",
                                fontSize: "14px",
                                fontWeight: "600",
                                maxWidth: "65px",
                              }}
                            >
                              D, E, & I
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                color: "#5A93ED",
                                fontSize: "14px",
                                fontWeight: "600",
                                maxWidth: "65px",
                              }}
                            >
                              STDZ
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                color: "#5A93ED",
                                fontSize: "14px",
                                fontWeight: "600",
                                maxWidth: "65px",
                              }}
                            >
                              ACTIVE
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                color: "#5A93ED",
                                fontSize: "14px",
                                fontWeight: "600",
                                maxWidth: "65px",
                              }}
                            ></StyledTableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </div>
                    <div
                      ref={containerRef}
                      className="1538:h-[525px] max-h-[calc(100vh-224px)]"
                      style={{
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        scrollbarWidth: "thin",
                        msOverflowStyle: "none",
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                      }}
                    >
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableBody>
                          {data?.map((data, i) => (
                            <StyledTableRow key={data?.customerid}>
                              <StyledTableCell
                                id={data?.customerid}
                                style={{
                                  borderBottom: "none",
                                  maxWidth: "108px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    // gap: "10px",
                                  }}
                                >
                                  {/* <div className="green-dot"></div> */}
                                  <p
                                    style={{
                                      fontSize: "15px",
                                      // fontWeight: "500",
                                      // color: "#1F2E39",
                                      cursor: "default",
                                    }}
                                  >
                                    <Tooltip
                                      title={
                                        data?.customername?.length > 10
                                          ? data?.customername
                                          : ""
                                      }
                                    >
                                      {data?.customername?.length > 10
                                        ? `${data?.customername.substring(
                                            0,
                                            10
                                          )}...`
                                        : data?.customername}
                                    </Tooltip>
                                  </p>
                                </div>
                              </StyledTableCell>

                              <StyledTableCell
                                id={data?.customerid}
                                style={{
                                  borderBottom: "none",
                                  maxWidth: "105px",
                                }}
                              >
                                <div className="cursor-past">
                                  <Tooltip
                                    title={
                                      data?.industryname?.length > 10
                                        ? data?.industryname
                                        : ""
                                    }
                                  >
                                    {data?.industryname
                                      ? data?.industryname?.length > 10
                                        ? `${data?.industryname.substring(
                                            0,
                                            10
                                          )}...`
                                        : data?.industryname
                                      : ""}
                                  </Tooltip>
                                </div>
                              </StyledTableCell>

                              <StyledTableCell
                                id={data?.customerid}
                                style={{
                                  borderBottom: "none",
                                  maxWidth: "90px",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "#1F2E39",
                                  }}
                                >
                                  {formatDate(data?.createddate)}
                                </p>
                              </StyledTableCell>

                              <StyledTableCell
                                id={data?.customerid}
                                style={{
                                  borderBottom: "none",
                                  maxWidth: "150px",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "#1F2E39",
                                    cursor: "default",
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      data?.customerpanelurl?.length > 24
                                        ? data?.customerpanelurl
                                        : ""
                                    }
                                  >
                                    {data?.customerpanelurl?.length > 24
                                      ? `${data?.customerpanelurl.substring(
                                          0,
                                          24
                                        )}...`
                                      : data?.customerpanelurl}
                                  </Tooltip>
                                </p>
                              </StyledTableCell>

                              <StyledTableCell
                                id={data?.customerid}
                                style={{
                                  borderBottom: "none",
                                  maxWidth: "75px",
                                }}
                              >
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    id={`${data?.customerid}-autoAtsSync`}
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        data?.customerid,
                                        "autoAtsSync",
                                        e.target.checked
                                      )
                                    }
                                    checked={
                                      rowData
                                        ? rowData[data?.customerid]?.autoAtsSync
                                        : false
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </StyledTableCell>

                              <StyledTableCell
                                id={data?.customerid}
                                style={{
                                  borderBottom: "none",
                                  maxWidth: "65px",
                                }}
                              >
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    id={`${data?.customerid}-deAndI`}
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        data?.customerid,
                                        "deAndI",
                                        e.target.checked
                                      )
                                    }
                                    checked={
                                      rowData
                                        ? rowData[data?.customerid]?.deAndI
                                        : false
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </StyledTableCell>

                              <StyledTableCell
                                id={data?.customerid}
                                style={{
                                  borderBottom: "none",
                                  maxWidth: "65px",
                                }}
                              >
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    id={`${data?.customerid}-standardization`}
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        data?.customerid,
                                        "standardization",
                                        e.target.checked
                                      )
                                    }
                                    checked={
                                      rowData
                                        ? rowData[data?.customerid]
                                            ?.standardization
                                        : false
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </StyledTableCell>

                              <StyledTableCell
                                id={data?.customerid}
                                style={{
                                  borderBottom: "none",
                                  maxWidth: "55px",
                                }}
                              >
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    id={`${data?.customerid}-isactive`}
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        data?.customerid,
                                        "isactive",
                                        e.target.checked
                                      )
                                    }
                                    checked={
                                      rowData
                                        ? rowData[data?.customerid]?.isactive
                                        : false
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </StyledTableCell>

                              <StyledTableCell
                                id={data?.customerid}
                                style={{
                                  borderBottom: "none",
                                  maxWidth: "50px",
                                }}
                              >
                                <Tooltip title={"Edit"}>
                                  <img
                                    onClick={() => btnEdit(data)}
                                    className="w-[18px] h-[18px] cursor-pointer"
                                    src="/Group 247.svg"
                                    alt="edit_icons"
                                  />
                                </Tooltip>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </TableContainer>
                </div>
              </div>
            )}
            {/* {noDataAvailable ? (
              ""
            ) : (
              <Pagination
                canNextPage={currentPage < totalPages}
                canPrevPage={currentPage > 1}
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
            )} */}
          </Box>
        </Box>
      )}
    </>
  );
}
