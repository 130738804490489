import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();
const GetCustomers = async (body) => {
  const { pageno, pagesize, search } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getcustomers",
      pagesize: pagesize,
      pageno: pageno,
      search: search,
    },
  });
  return data;
};

const GetUsers = async (body) => {
  const { customerid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getuserlist",
      customer: customerid,
    },
  });
  return data;
};

const CheckEmailExist = async (body) => {
  const { emailid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "emailexist",
      emailid: emailid,
    },
  });
  return data;
};

const InsertUser = async (body) => {
  const { customer, emailid, lastname, firstname, password, isactive } = body;
  const { data } = await axios.request({
    url: "/v1/insertuser",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      customer: customer,
      emailid: emailid,
      lastname: lastname,
      firstname: firstname,
      password: password,
      isactive: isactive,
    },
  });
  return data;
};

const DeleteUser = async (body) => {
  const { userid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "deleteuser",
      user: userid,
    },
  });
  return data;
};

// Edit user details
const EditUser = async (body) => {
  const { user } = body;
  const { data } = await axios.request({
    url: "/v1/getuserbyid",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getuserbyuserid",
      user: user,
    },
  });
  return data;
};

const UpdateUser = async (body) => {
  const { user, emailid, lastname, firstname, password, isactive } = body;
  const { data } = await axios.request({
    url: "/v1/updateuserbyid",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      user: user,
      emailid: emailid,
      lastname: lastname,
      firstname: firstname,
      password: password,
      isactive: isactive,
    },
  });
  return data;
};

export const useClientSettingData = () => ({
  GetCustomers,
  GetUsers,
  CheckEmailExist,
  InsertUser,
  DeleteUser,
  EditUser,
  UpdateUser
});
