import React from "react";

function MarkFavorite({ onClick, imgUrl, style }) {
  return (
    <div className="flex justify-center">
      <img src={imgUrl} alt="Image" onClick={onClick} style={style} />
    </div>
  );
}

export default MarkFavorite;
