import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();
 export const GetPrompt = async (body) => {
  const { pageno, pagesize,customer} = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getcustomerprompts",
      pageno: pageno,
      pagesize: pagesize,
      customer:customer
      
    },
  });
  return data;
};

// Active - Inactive prompt
export const ActiveInactivePrompt = async (body) => {
  const { promptid,isactive} = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updateprompt",
      promptid: promptid,
      isactive: isactive,
      
    },
  });
  return data;
};