import React from "react";
import ExportPdf from "../../components/ExportPdf/ExportPdf";
import { useParams } from "react-router-dom";

const ExportPdfPage = () => {
  const { analysisid } = useParams();

  return (
    <div>
      <ExportPdf analysisid={analysisid} />
    </div>
  );
};

export default ExportPdfPage;
