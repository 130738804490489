import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();
const GetProcessedJobs = async (body) => {
  const {
    pageno,
    pagesize,
    search,
    isactivecandidate,
    isactivejob,
    startdate,
    enddate,
  } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getprocessedjobs",
      pageno: pageno,
      pagesize: pagesize,
      search: search,
      isactivecandidate: isactivecandidate,
      isactivejob: isactivejob,
      startdate: startdate,
      enddate: enddate,
    },
  });
  return data;
};

const GetAtsUrlByAtsJobcode = async (body) => {
  const { atsjobcode } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getatsurlbyatsjobcode",
      atsjobcode: atsjobcode,
    },
  });
  return data;
};

const GetLatestProcessedJobs = async (body) => {
  const { customer, filterdate } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getlatestprocessedjobs",
      customer: customer,
      filterdate: filterdate,
    },
  });
  return data;
};

const GetJobList = async (body) => {
  const { pageno, pagesize, search, jobfilter } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getjoblist",
      pageno: pageno,
      pagesize: pagesize,
      search: search,
      jobfilter: jobfilter,
    },
  });
  return data;
};

const MarkJobFavorite = async (body) => {
  const { jobid, isfavorite } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatefavoritejob",
      jobid: jobid,
      isfavorite: isfavorite,
    },
  });
  return data;
};

const UpdateJob = async (body) => {
  const {
    jobid,
    jobtitle,
    employmenttype,
    jobdescription,
    isactive,
    atsjobcode,
  } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatejob",
      atsjobcode: atsjobcode,
      jobid: jobid,
      jobtitle: jobtitle,
      employmenttype: employmenttype,
      jobdescription: jobdescription,
      isactive: isactive,
    },
  });
  return data;
};

const AddJob = async (body) => {
  const { atsjobcode, jobtitle, employmenttype, jobdescription, isactive } =
    body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "insertjob",
      atsjobcode: atsjobcode,
      jobtitle: jobtitle,
      employmenttype: employmenttype,
      jobdescription: jobdescription,
      isactive: isactive,
    },
  });
  return data;
};

const GenerateAiDesc = async (body) => {
  const { jobtitle, employmenttype, industry } = body;

  try {
    const response = await fetch(
      "https://standardization-api.talentin.ai/generate_jd",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          job_title: jobtitle,
          job_type: employmenttype,
          industry: industry,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const GetJobByJobCode = async (body) => {
  const { jobcode } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getjobbyatsjobcode",
      atsjobcode: jobcode,
    },
  });
  return data;
};

const GetAllJobs = async () => {
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getalljobs",
    },
  });
  return data;
};
export const useJobsData = () => ({
  GetProcessedJobs,
  GetAtsUrlByAtsJobcode,
  GetLatestProcessedJobs,
  GetJobList,
  MarkJobFavorite,
  UpdateJob,
  AddJob,
  GenerateAiDesc,
  GetJobByJobCode,
  GetAllJobs,
});
