import React, { useEffect, useState } from "react";
import { Drawer } from "@mui/material";
import "./RatingMatch.css";
import { useMediaQuery } from "@mui/material";
import {  useTheme } from "@mui/material/styles";
import { useFeedbacksData } from "../../data/feedbacks";

const RatingMatch = ({
  analysisid,
  preRating,
  preRemark,
  updateParentRating,
}) => {

 
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredtwo, setIsHoveredtwo] = useState(false);
  const [isHoveredtheree, setIsHoveredtheree] = useState(false);
  const [isHoveredfour, setIsHoveredfour] = useState(false);
  const [rating, setRating] = useState(0);
  const { UpdateRating } = useFeedbacksData();

  const theme = useTheme();


  const isMobile = useMediaQuery(theme.breakpoints.down("820"));

  const handleChange = (event) => {
    const newText = event.target.value;
    setText(newText);
    setErrorMessage("");
    setIsValid(newText.trim() !== "");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (rating > 0) {
      UpdateRating({
        analysisid: analysisid,
        rating: rating,
        remark: text,
      })
        .then((response) => {
          updateParentRating({
            rating: rating,
            remark: text,
          });
          setIsOpen(false);
          return;
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setErrorMessage("Please select the rating");
      return;
    }
  };

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleRating = (rating) => {
    setRating(rating);
  };

  useEffect(() => {
    setRating(preRating || 0);
  }, [preRating]);

  useEffect(() => {
    setText(preRemark || "");
  }, [preRemark]);

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  return (
    <>

{ isMobile  ? 
<div> 
  <img  onClick={handleDrawerToggle} className="w-5 h-5"  style={{color:"#22d6aa"}} src="/RateSmileyIcon.svg" />
</div>
:
      <div
        style={{ width: "180px", cursor: "pointer" }}
        onClick={handleDrawerToggle}
        className="buttonboxsecond"
      >
        <img className="iconboxsecond" src="/images/Rate.svg" />
        <div className="backbuttonsecond" style={{ marginLeft: "5px" }}>
          <span
            className="ratethismatch"
            style={{ whiteSpace: "nowrap", width: "100%" }}
          >
            <span style={{ paddingRight: preRating ? "25px" : "0px" }}>
              {preRating ? "EDIT RATING" : "RATE THIS MATCH"}
            </span>
          </span>
        </div>
      </div>
}

      <Drawer anchor="bottom" open={isOpen} onClose={handleDrawerToggle}>
        {isOpen && (
          <>
            <div
              className="absolute top-0 left-0 w-full h-full bg-black opacity-30 cursor-pointer"
              onClick={handleDrawerToggle}
            ></div>
            <div className={`fixed mx-auto ${isMobile ? "w-full" : "w-[50%] lg:w-[60%] md:w-[70%]" }  inset-x-0 bottom-0 z-50  transform transition-transform ease-in-out duration-300`}>
           {isMobile ? "" :  <img src="/Border.png" alt="" className="fixed h-[550px]" /> }  

              <div
                style={{ scrollbarWidth: "none" }}
                className={`h-full overflow-y-auto ${isMobile ? "" :  "px-4 py-6" } ${
                  isOpen ? "translate-y-0" : "translate-y-full"
                }`}
              >
                {/* Drawer content */}
                <div className="p-2 bg-white">
                  <div className="flex  items-end justify-end">
                <button
                        className="text-[#22D6AA]  pr-2"
                        onClick={handleDrawerToggle}
                        style={{
                          background: "transparent",
                          color: "#22D6AA",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          className=" w-4 h-"
                          src="/clear.svg"
                          alt="Clear Dates"
                        />
                      </button>
                      </div>
                  <div className="flex justify-center">
                    <h1 className="text-xl font-bold  text-black mb-4">
                      Rate this Match
                    </h1>
                    {/* <div  style={{
                      position:"absolute",
                      marginTop:"0",
                      marginLeft:"600px",
                 
                    }}>
                      <img
                        src="/images/greenCross2.svg"
                        onClick={handleClosePopup}
                        style={{ cursor: "pointer" }}
                        alt="Close"
                      ></img>
                    </div> */}
                  </div>
                  <div className={` ${isMobile ? "grid grid-cols-2" : "flex items-center justify-center gap-14  w-full"}`}>
                    <div className="flex justify-center flex-col items-center">
                      <div
                        className=" w-20 h-20 bg-cover  duration-300 ease-in-out"
                        style={{
                          backgroundImage:
                            rating == 4
                              ? "url(/images/Emoji_1.gif)"
                              : isHovered
                              ? "url(/images/Emoji_1.gif)"
                              : "url(/images/great-star.svg)",
                          cursor: "pointer",
                        }}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={() => handleRating(4)}
                      ></div>
                      <span
                        className="text-base font-medium"
                        style={{
                          color: rating == 4 ? "#5A93ED" : "#1F2E39",
                          cursor: "pointer",
                        }}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={() => handleRating(4)}
                      >
                        GREAT!
                      </span>
                    </div>
                    <div className="flex justify-center flex-col items-center">
                      <div
                        className=" w-20 h-20 bg-cover  duration-300 ease-in-out"
                        style={{
                          backgroundImage:
                            rating == 3
                              ? "url(/images/Emoji_4.gif)"
                              : isHoveredfour
                              ? "url(/images/Emoji_4.gif)"
                              : "url(/images/good-star.svg)",
                          cursor: "pointer",
                        }}
                        onMouseEnter={() => setIsHoveredfour(true)}
                        onMouseLeave={() => setIsHoveredfour(false)}
                        onClick={() => handleRating(3)}
                      ></div>
                      <span
                        className="text-base font-medium"
                        style={{
                          color: rating == 3 ? "#5A93ED" : "#1F2E39",
                          cursor: "pointer",
                        }}
                        onMouseEnter={() => setIsHoveredfour(true)}
                        onMouseLeave={() => setIsHoveredfour(false)}
                        onClick={() => handleRating(3)}
                      >
                        GOOD
                      </span>
                    </div>
                    <div className="flex justify-center flex-col items-center">
                      <div
                        className=" w-20 h-20 bg-cover duration-400 ease-in-out"
                        style={{
                          backgroundImage:
                            rating == 2
                              ? "url(/images/Emoji_2.gif)"
                              : isHoveredtwo
                              ? "url(/images/Emoji_2.gif)"
                              : "url(/images/meh-star.svg)",
                          cursor: "pointer",
                        }}
                        onMouseEnter={() => setIsHoveredtwo(true)}
                        onMouseLeave={() => setIsHoveredtwo(false)}
                        onClick={() => handleRating(2)}
                      ></div>
                      <span
                        className="text-base font-medium"
                        style={{
                          color: rating == 2 ? "#5A93ED" : "#1F2E39",
                          cursor: "pointer",
                        }}
                        onMouseEnter={() => setIsHoveredtwo(true)}
                        onMouseLeave={() => setIsHoveredtwo(false)}
                        onClick={() => handleRating(2)}
                      >
                        MEH!
                      </span>
                    </div>
                    <div className="flex justify-center flex-col  items-center">
                      <div
                        className=" w-20 h-20 bg-cover duration-300 ease-in-out"
                        style={{
                          backgroundImage:
                            rating == 1
                              ? "url(/images/Emoji_3.gif)"
                              : isHoveredtheree
                              ? "url(/images/Emoji_3.gif)"
                              : "url(/images/not-statisfied-star.svg)",
                          cursor: "pointer",
                        }}
                        onMouseEnter={() => setIsHoveredtheree(true)}
                        onMouseLeave={() => setIsHoveredtheree(false)}
                        onClick={() => handleRating(1)}
                      ></div>
                      <span
                        className="text-base font-medium"
                        style={{
                          color: rating == 1 ? "#5A93ED" : "#1F2E39",
                          cursor: "pointer",
                        }}
                        onMouseEnter={() => setIsHoveredtheree(true)}
                        onMouseLeave={() => setIsHoveredtheree(false)}
                        onClick={() => handleRating(1)}
                      >
                        NOT&nbsp;SATISFIED
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-center w-full mt-4">
                    <form onSubmit={handleSubmit}>
                      <div className="w-full">
                        <textarea
                          value={text}
                          onChange={handleChange}
                          placeholder="Type here to explain more or add your insight"
                          rows={6}
                          cols={60}
                          className={`border border-solid text-[#1f2e39] text-[14px] ${isMobile ? "w-[100%]" : ""} rounded-md  ${
                            isValid ? "border-[#708EA4]" : "!border-red-500 "
                          } p-2 font-[Rubik]`}
                        />
                        {!isValid && (
                          <p className="text-red-500">{errorMessage}</p>
                        )}
                      </div>
                      <div className="flex justify-center mt-5">
                        <div
                          className="buttonbox"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => handleSubmit(e)}
                        >
                          <p type="submit" className="backbutton ">
                            SUBMIT
                          </p>
                          <img
                            className="iconbox"
                            style={{ width: "20px" }}
                            src="/images/black-arrow.svg"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Drawer>
    </>
  );
};

export default RatingMatch;
