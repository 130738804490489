import React, { useState, useEffect, useRef } from "react";

function CandidateFilter({
  selectedFilter,
  filterList,
  filterSelectionChange,
  onApplyFilter,
  clearSelection,
  setIsOpen,
  isSearchVisible = true,
}) {
  const [searchText, setSearchText] = useState("");
  const searchTextChange = (event) => {
    setSearchText(event.target.value);
  };
  const [data, setData] = useState(filterList);
  const filterDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target) && // Check if the click is outside the dropdown
        !event.target.closest("img")
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    btnSearch();
  }, [searchText]);

  useEffect(() => {
    // Check if filterList is an array and iterate over it to update data
    if (Array.isArray(filterList)) {
      setData((prevList) =>
        prevList.map((item) => {
          // Check if the current item id exists in filterList
          const filterItem = filterList.find(
            (filterItem) => filterItem.id === item.id
          );
          // If found, update isChecked based on filterItem, else keep the item unchanged
          return filterItem
            ? { ...item, isChecked: filterItem.isChecked }
            : item;
        })
      );
    }
  }, [filterList]);

  const btnSearch = async () => {
    if (searchText) {
      const results = filterList?.filter((item) =>
        item?.name?.toLowerCase().includes(searchText.toLowerCase())
      );
      results && setData(results);
    } else {
      setData(filterList);
    }
  };

  const clearSearch = async () => {
    setSearchText("");
  };

  return (
    <div
      ref={filterDropdownRef}
      style={{
        position: "absolute",
        top: "110%",
        left: 0,
        backgroundColor: "#ffffff",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
        borderRadius: "6px",
        width: "220px",
        padding: "8px 8px",
        zIndex: 10,
        fontFamily: "Arial, sans-serif",
        fontSize: "14px",
      }}
    >
      <div
        style={{
          paddingTop: "5px",
          paddingBottom: "5px",
          paddingLeft: "8px",
          borderBottom: "1px solid #e0e0e0",
          fontWeight: "bold",
        }}
      >
        {selectedFilter}
      </div>
      <div style={{ padding: "8px" }}>
        {isSearchVisible && <div
          className="search-container"
          style={{
            marginBottom: "10px",
            position: "relative",
            display: "flex",
            width: "100%",
          }}
        >
          <input
            type="text"
            id="search-dei"
            placeholder="Search"
            value={searchText}
            onChange={searchTextChange}
            onKeyDown={(event) => {
              if (event.key === "Enter") btnSearch();
            }}
            style={{
              border: "none",
              outline: "none",
              height: "35px",
              paddingLeft: "10px",
              width: "100%",
              flex: 1,
              fontSize: "12px",
              color: "#000",
              fontWeight: "normal",
              fontFamily: "'Rubik', sans-serif",
            }}
          />
          {searchText && (
            <button
              style={{
                position: "absolute",
                right: "40px",
                border: "none",
                outline: "none",
                background: "transparent",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={clearSearch}
            >
              <img
                src="/images/greenCross1.svg"
                style={{
                  width: "10px",
                  height: "10px",
                }}
                alt="Clear"
              />
            </button>
          )}
          <button
            style={{
              border: "none",
              outline: "none",
              height: "35px",
              paddingTop: "9px",
            }}
            type="submit"
            onClick={() => btnSearch()}
          >
            <img
              style={{
                background: "inherit",
                width: "15px",
                height: "15px",
              }}
              src="/images/Search Icon.svg"
              alt="Search"
            />
          </button>
        </div>}

        <div
          style={{
            maxHeight: "100px",
            overflowY: "auto",
            scrollbarWidth: "thin",
          }}
        >
          {data?.map((option) => (
            <label
              key={option?.id}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "4px",
                fontSize: "12px",
                color: "#000",
                fontWeight: "normal",
                fontFamily: "'Rubik', sans-serif",
              }}
            >
              <input
                type="checkbox"
                checked={option?.isChecked}
                onChange={() => filterSelectionChange(option)}
                style={{
                  display: "inline-block",
                  opacity: 1,
                  position: "relative",
                  textTransform: "none",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  marginRight: "8px",
                }}
              />
              <span
                style={{
                  textTransform: "none",
                }}
              >
                {option?.name}
              </span>
              <span
                style={{
                  marginLeft: "auto",
                  color: "#888",
                  marginRight: "5px",
                }}
              >
                {option?.count}
              </span>
            </label>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "10px",
          }}
        >
          <button
            onClick={() => {
              clearSelection();
              clearSearch();
            }}
            style={{
              flex: 1,
              padding: "6px",
              border: "1px solid #ccc",
              background: "#ffffff",
              cursor: "pointer",
              borderRadius: "4px",
              marginRight: "4px",
            }}
          >
            CLEAR
          </button>
          <button
            onClick={onApplyFilter}
            style={{
              flex: 1,
              padding: "6px",
              border: "none",
              background: "#00bfa5",
              color: "white",
              cursor: "pointer",
              borderRadius: "4px",
            }}
          >
            APPLY
          </button>
        </div>
      </div>
    </div>
  );
}

export default CandidateFilter;
