import React, { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Loading from "../Loading/Loading";
import { useCandidateData } from "../../data/candidatedata";

const ExportPdf = ({ analysisid }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pdfGenerated, setPdfGenerated] = useState(false);
  const { GetCandidateResult } = useCandidateData();

  useEffect(() => {
    // Fetch candidate data when the component mounts
    GetCandidateResult(parseInt(analysisid))
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [analysisid, GetCandidateResult]);

  useEffect(() => {
    if (data && !pdfGenerated) {
      exportPDF();
      setPdfGenerated(true);
    }
  }, [data, pdfGenerated]);

  const preprocessJobDescription = (description) => {
    return description?.replace(/<\/?br\s*\/?>/gi, "\n"); // Replace <br> tags with newline characters
  };

  const today = new Date();
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const formattedDate = today.toLocaleDateString("en-GB", options);
  const dateFormat = new Date(data?.jobcreateddate);
  const day = String(dateFormat.getDate()).padStart(2, "0");
  const month = String(dateFormat.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = String(dateFormat.getFullYear());
  const formattedDateForJobCreate = `${day}-${month}-${year}`;

  const exportPDF = async () => {
    try {
      const doc = new jsPDF({
        orientation: "portrait",
        format: "a4",
        compress: true,
      });

      const htmlContent = `
            <div style="padding: 40px; width: 1466px; height: auto; margin-top: 10px;  box-sizing: border-box;"><br />
                <div style=" display: flex; justify-content: space-between;">
                    <text style="color: #5A93ED; font-size: 26px; font-weight: 700px;">${
                      data?.candidatename
                    }</text>
                    <text style=" font-size: 26px; font-weight: 700px;">${formattedDate}</text>
                </div>
                <br />
                <hr color='#708EA4' style='height: 2px' />
                <br />
                <h2 style='color: #1F2E39; font-size: 24px;'>Job Description</h2>
                <div style="margin-top: 15px; margin-bottom: 15px;">
                    <text style="font-size: 24px; font-weight: bold; color: #1F2E39;">
                        Job Title: ${data?.jobtitle} | ATS Job Code: ${
        data?.atsjobcode
      } | Job Opened: ${formattedDateForJobCreate}
                    </text>
                    <div style="font-size: 22px; font-weight: normal;  gap: 10px; color: #1F2E39; letter-spacing: 0px; height: auto; width: 1400px;">
                        <pre style="white-space: pre-wrap; word-wrap: break-word;">${preprocessJobDescription(
                          data.jobdescription
                        )}</pre>
                    </div>
                </div>
    
                <div style="margin-top: 30px; margin-bottom: 30px;">
                    <text style="font-size: 24px; font-weight: bold; color: #1F2E39;">Ranking Table</text>
                </div>
                <br />
    
                <div style="border-bottom: 8px solid #5A93ED;">
                    <table >
                        <thead style="background-color: #5A93ED; height: 70px; padding: 40px;"  >
                            <tr >
                                <th style="color: white; text-align: center; font-size: 24px; font-weight: 500px;">Rank Candidate Name</th>
                                <th style="color: white; text-align: center; font-size: 24px; font-weight: 500px;">D, E, & I</th>
                                <th style="color: white; text-align: center; font-size: 24px; font-weight: 500px;">Matching Skills</th>
                                <th style="color: white; text-align: center; font-size: 24px; font-weight: 500px;">Recent Title</th>
                                <th style="color: white; text-align: center; font-size: 24px; font-weight: 500px;">Education</th>
                                <th style="color: white;  text-align: center; font-size: 24px; font-weight: 500px;">Match %</th>
                            </tr>
                        </thead>
                        <tbody style="max-height: 600px; overflow: auto; -webkit-overflow-scrolling: touch;">
                            <tr>
                                <td style="background-color: #ffffff; border-bottom: none; padding: 30px;  width: 276px; text-align: center; font-size: 20px;">${
                                  data?.candidatename
                                }</td>
                                <td style="background-color: #CBDFFF; border-bottom: none; padding: 30px; width: 128px; text-align: center; font-size: 20px;">${
                                  data?.ethnicity
                                }</td>
                                <td style="background-color: #ffffff; border-bottom: none; padding: 30px; width: 350px; text-align: center; font-size: 20px;">${
                                  data?.matching_skills
                                }</td>
                                <td style="background-color: #CBDFFF; border-bottom: none; padding: 30px; width: 237px; text-align: center; font-size: 20px;">${
                                  data?.current_job_title
                                }</td>
                                <td style="background-color: #ffffff; border-bottom: none; padding: 30px; width: 237px; text-align: center; font-size: 20px;">${
                                  data?.education
                                }</td>
                                <td style="background-color: #CBDFFF; border-bottom: none; padding: 30px; width: 237px; text-align: center; font-size: 20px;">${
                                  data?.match_percentage
                                }%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
    
                <div style="width: 1400px;">
                    <div style="margin-top: 20px; margin-bottom: 20px; display: flex; flex-direction: column; gap: 14px;">
                        <h2 style="font-size: 24px; font-weight: bold; color: #1F2E39;">Profile Summary</h2>
                        <span style="font-size: 24px; font-weight: 500px; color: #1F2E39;">${
                          data?.analysis_summary
                        }</span>
                    </div>
                    <div style="margin-top: 20px; margin-bottom: 20px; display: flex; justify-content:center; text-align: center; flex-direction: row; gap: 2px;">
                        <text style="font-size: 24px; font-weight: bold; color: #1F2E39;">Pros:</text>
                        <text style="font-size: 24px; font-weight: 500px; color: #1F2E39;">${
                          data?.pros
                        }</text>
                    </div>
                    <div style="margin-top: 20px; margin-bottom: 20px; display: flex; justify-content:center; text-align: center; flex-direction: row; gap: 2px;">
                        <text style="font-size: 24px; font-weight: bold; color: #1F2E39;">Cons:</text>
                        <text style="font-size: 24px; font-weight: 500px; color: #1F2E39;">${
                          data?.cons
                        }</text>
                    </div>
                </div>
            </div>
        `;

      // Create a hidden iframe
      let iframe = document.createElement("iframe");
      iframe.style.visibility = "hidden";
      iframe.style.width = "100%";
      document.body.appendChild(iframe);
      let iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      iframeDoc.body.innerHTML = htmlContent;

      // Convert the content of the iframe to canvas
      let canvas = await html2canvas(iframeDoc.body, { scale: 2 }); // Higher scale for better quality
      let imgData = canvas.toDataURL("image/png");

      const imgWidth = 210; // A4 width in mm
      const pageHeight = doc.internal.pageSize.getHeight();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      // Add each page
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // Save the PDF
      doc.save(`${data?.candidatename}.pdf`);

      window.history.go(-1);

      // Remove the iframe
      document.body.removeChild(iframe);
    } catch (error) {
      console.error("Error occurred during download:", error);
    }
  };

  return (
    <div className="flex justify-center w-full">
      <div className="w-full">
        <Loading />
      </div>
    </div>
  );
};

export default ExportPdf;
