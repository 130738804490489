import React, { useEffect, useState } from "react";
import PageNumbers from "./PageNumbers";

function Pagination(props) {
  const {
    canNextPage,
    canPrevPage,
    totalPages,
    handlePageChange,
    currentPage,
  } = props;
  const MAX_PAGE_SIZE = 10;

  const onPreviousPage = () => {
    handlePageChange(currentPage - 1);
  };

  const onNextPage = () => {
    handlePageChange(currentPage + 1);
  };

  const onPageChange = (pageNumber) => {
    handlePageChange(pageNumber);
  };

  return (
    <div className="flex w-full justify-center sm:items-center py-3 md:px-3 px-3 gap-3 max-sm:flex-col">
      <span className="flex items-center gap-1">
        <PageNumbers
          table={{
            currentPage: currentPage,
            pageCount: totalPages,
            onNext: onNextPage,
            onPrev: onPreviousPage,
            onPageChange: onPageChange,
            canNext: canNextPage,
            canPrev: canPrevPage,
          }}
        />
      </span>
    </div>
  );
}

export default Pagination;
