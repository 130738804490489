import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "350px",
    height: "250px",
  },
}));

export default function AddNewUserSavedPopup(props) {
  const { open, setOpen,userid } = props;

  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
      navigate(-1);
    }
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 8,
            color: "green",
          }}
        >
          <img
            style={{ width: "15px", height: "15px" }}
            src="/images/Path 1670.svg"
          />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <img
              style={{ width: "50px", height: "50px" }}
              src="/images/Group 126.svg"
            />
          </Typography>

          <Typography
            gutterBottom
            style={{ font: "normal normal bold 20px/23px Rubik" }}
          >
            {userid ? "User updated Successfully" : "New User Added!"}
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
